import React, { useState, useEffect } from "react";
import TablePagination from "@mui/material/TablePagination";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TextField,
    TableContainer,
    TableSortLabel,
} from "@mui/material";
import dayjs from "dayjs";
import { AdminDashboardService } from "../../../../../core/services/admin-dashboard.service";
import { Loading } from '../../../../../redux/action';
import { connect } from 'react-redux';
import { LocalService } from "../../../../../core/services";
import HeadingStyle from "../../../../../components/heading-style/heading-style";
import CommonPopUp from '../../../../../components/popup/common-popup';
import { FormValidation } from '../../../../../core/utility';
import {Skeleton} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RolesService } from "../../../../../core/services/roles.service";


const adminDashboardService = new AdminDashboardService();
const localService: LocalService = new LocalService();
const formValidation = new FormValidation();
const rolesService = new RolesService();

interface ColumnDisplayNames {
    [key: string]: string;
}


const columnDisplayNames: ColumnDisplayNames = {
    email_primary: "Agency",
    brand_name: "Brand Name",
    premium_status: "Premium Status",
};

const tableHeadColumnPart = [
    {
        name: "email_primary",
        columnSize: "165px",
    },
    {
        name: "brand_name",
        columnSize: "165px",
    },
    {
        name: "premium_status",
        columnSize: "165px",
    },
];

interface Props {
    loading: (data: any) => any;
    userData: any;
}


const AgencyBrand: React.FC<Props> = (props: Props) => {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [orderBy, setOrderBy] = React.useState("");
    const [order, setOrder] = React.useState<"asc" | "desc">("asc");
    const [agencyBrands, setAgencyBrands] = useState<any>([]);
    const [selectedBrand, setSelectBrand] = useState<any>();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openPremiumDetails, setOpenPremiumDetails] = useState(false);
    const [remark, setRemark] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [formError, setFormErrors] = useState({
        remark: { status: true, message: '' },
      });

    useEffect(() => {
        // CHECK IF USER ALLOWED  ACCESS 
        checkAccessFunction();
      }, []);
    
      const checkAccessFunction = async () => {
        try {
          const res = await rolesService.checkAccess({ access_roles: ["view_agency_brands"], user_role_id: props?.userData?.user_role_id })
          if (res.data.rows.length > 0) {
            getAgencyBrands();
          }
          else {
            localService.toastify("You are not allowed to access", "error", 1000);
    
            navigate(`/brand/dashboard`)
          }
        }
        catch (error) {
          console.error('Error fetching getAdminDashData:', error);
    
        }
      }

    const handleError = (event: any) => {
        const validateRemark = formValidation.GetNameValidate(remark);
        setFormErrors({
          ...formError,
          remark: validateRemark,
        });
      };

    async function getAgencyBrands() {
        try {
            setLoading(true);
            const res = await adminDashboardService.getAgencyBrand();

            if(res.data.status){
                setAgencyBrands(res.data.rows);
            }

        } catch (error) {
            console.error('Error fetching getAgencyBrands:', error);
        } finally {
            setLoading(false);
        }
    }

    const handleSort = (property: any) => {
        const isAsc = orderBy === property && order === "asc";
        setOrderBy(property);
        setOrder(isAsc ? "desc" : "asc");
      };
    
      const sortedData = React.useMemo(() => {
        if (Array.isArray(agencyBrands)) {
          const sortedArray = [...agencyBrands].sort((a, b) => {
            if (a[orderBy] < b[orderBy]) return order === "asc" ? -1 : 1;
            if (a[orderBy] > b[orderBy]) return order === "asc" ? 1 : -1;
            return 0;
          });
          return sortedArray;
        }
      }, [agencyBrands, orderBy, order]);
    
      const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

      const showPremiumDetails = (data:any) => {
        setSelectBrand(data);
        setOpenPremiumDetails(!openPremiumDetails);
        setOpenConfirm(false);
      }

      const confirmPremium = (data:any) => {
        setSelectBrand(data);
        setOpenConfirm(!openConfirm);
        setOpenPremiumDetails(false);
      }

      const handleClose = () =>{
        setOpenConfirm(false);
        setOpenPremiumDetails(false);
        setFormErrors({
            remark:{
                status:true,
                message:''
            }}
        )
        setRemark('');
      }

    
    const handlePremium = async (e: any) => {
        try {
          e.preventDefault();
          const validateRemark = formValidation.GetNameValidate(remark);
          setFormErrors({
            ...formError,
            remark: validateRemark,
          });
          if (validateRemark.status) {
            try {
                const response = await adminDashboardService.handlePremium({
                                account_id : selectedBrand.account_id,
                                user_id : selectedBrand.user_id,
                                influencer_id : selectedBrand.influencer_id,
                                email_primary : selectedBrand.email_primary,
                                mobile_whatsapp : selectedBrand.mobile_whatsapp,
                                remark : remark,
                                subscribed_by : props.userData.user_id,
                            });            
              if (response.data.status.status) {
                localService.toastify(response.data.status.message, 'success', 1000);
                getAgencyBrands();
              } else {
                localService.toastify(response.data.status.message, 'error', 1000);
              }
            } catch (error) {
              console.log(
                'handlePremium Error :: ',
                JSON.stringify(error)
              );
            }
            handleClose();
          }
        } catch (error) {
          console.log('handlePremium Error :: ', JSON.stringify(error));
          handleClose();
        }
      };

    return (
        <div className="adminDashboardSignupMostOutterDiv ">    
            <div className="adminDashboardSignupContentOutterDiv">
                <div className="adminDashboardSignupContentDiv ">
                    <HeadingStyle title='Admin Dashboard' className=' px-3 d-block mb-3'/>
                    <div className="adminDashboardSignupContentDivTable ">

                        <TableContainer className="common-table">
                            <Table
                                sx={{ minWidth: 650 }}
                                aria-label="custom pagination table"
                            >
                                <TableHead className="common-table-header">
                                    <TableRow>
                                        {tableHeadColumnPart.map((column) => (
                                            <TableCell
                                                className="table_cell"
                                                style={{
                                                    minWidth: column.columnSize,
                                                    padding: "12px",
                                                }}
                                                align="left"
                                                key={column.name}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === column.name}
                                                    direction={order}
                                                    onClick={() => handleSort(column.name)}
                                                >
                                                    {columnDisplayNames[column.name] ||
                                                        column.name}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className='common-table-body'>
                                    {sortedData
                                        ?.slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        ?.map((row: any) => (
                                            <TableRow>
                                                <TableCell className="table_cell" align="left" >
                                                {loading ? <Skeleton /> : row.email_primary}
                                                </TableCell>
                                                <TableCell className="tableCell" align="left" >
                                                {loading ? <Skeleton /> : row.name}
                                                </TableCell>
                                                <TableCell className="tableCell" align="left" >
                                                    
                                                {loading ? <Skeleton /> : 
                                                    row.subscription_id &&
                                                        <Button
                                                            className="btn btn-primary"
                                                            variant="outlined"
                                                            onClick = {()=>showPremiumDetails(row)}
                                                        >
                                                           Premium Activated
                                                        </Button>
                                                }

                                                {loading ? <Skeleton /> : 
                                                    row.subscription_id == null &&
                                                        <Button
                                                            className="btn btn-primary"
                                                            variant="outlined"
                                                            onClick = {()=>confirmPremium(row)}
                                                        >
                                                           Get Premium
                                                        </Button>
                                                }
                                                   
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            className="common-pagination"
                            component="div"
                            rowsPerPageOptions={[10]}
                            count={sortedData && sortedData[0]?.total_user_count || 0}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{
                                color: "#00aae5",
                                marginTop: "10px",
                            }}
                        />
                    </div>
            
                    {openConfirm && (
                        <CommonPopUp
                        modalTitle={'Premium'}
                        openModal={openConfirm}
                        onClose={() => handleClose()}
                        className="modal-medium mx-auto"
                        >  
                            <div>
                                <p>Are you sure want to take premium for {selectedBrand?.name}?</p>
                                <form onSubmit={handlePremium}>
                                    <TextField
                                        fullWidth
                                        label='Remark'
                                        name='remark'
                                        variant='standard'
                                        value={remark}
                                        error={!formError?.remark?.status}
                                        helperText={formError?.remark?.message}
                                        onChange={(e) =>
                                        setRemark(e.target.value)
                                        }   
                                        onBlur={handleError}
                                    />
                                    <div className='col-lg-auto text-end mt-3 mb-3'>
                                        <Button
                                        size='small'
                                        type="submit"
                                        className="btn btn-primary"
                                        variant="outlined"
                                        >
                                        Confirm
                                        </Button>
                                    </div>
                                </form>
                            </div>   
                        </CommonPopUp>
                    )}

                    {openPremiumDetails && (
                        <CommonPopUp
                        modalTitle={'Premium Detail'}
                        openModal={openPremiumDetails}
                        onClose={() => handleClose()}
                        className="modal-medium mx-auto"
                        >  
                            <div>
                                <ul>
                                <li><strong>Agency: </strong>{selectedBrand.email_primary}</li>
                                <li><strong>Brand Name: </strong>{selectedBrand.name}</li>
                                <li><strong>Plan Name: </strong>{selectedBrand.plan_name}</li>
                                <li><strong>Subscribed By Email: </strong>{selectedBrand.subscribed_by_email}</li>
                                <li><strong>Plan End Date: </strong>{dayjs(selectedBrand.plan_end).format("DD MMM, YYYY")}</li>
                                </ul>
                            </div>   
                        </CommonPopUp>
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userData: state.UserReducer.UserData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        loading: (data: any) => dispatch(Loading(data)),
    };
};

const connectToRedux = connect(
    mapStateToProps,
    mapDispatchToProps
)(AgencyBrand);
export { connectToRedux as AgencyBrand };
