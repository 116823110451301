import React, { useState, useEffect, Fragment } from "react";
import { YouTube } from "@mui/icons-material";
import { IconButton, Slide } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { LocalService, ListService } from "../../core/services";
import MaterialSymbol from '../../components/icons/material-symbol';
import Loading from '../../components/loader/loading';
import InstagramIcon from "../../components/icons/instagram-icon";
import InstagramIcon1 from "../icons/instagram-icon1";

const listService: ListService = new ListService();
const localService: LocalService = new LocalService();

interface Props {
    selectedInfluencer: any[];
    platform: string;
    UserReducer: any;
    showPopAddToCampaign: boolean;
    handleCloseAddToCamp: () => void;
}

const AddToCampaign: React.FC<Props> = ({ selectedInfluencer, platform, UserReducer, showPopAddToCampaign, handleCloseAddToCamp }) => {

    const [loading, setLoading] = useState(false)
    const [campaignList, setCampaignList] = useState<any>([]);
    const [campaignInfluencerIds, setCampaignInfluencerIds] = useState<{ campaign_id: number, influencer_id: number, sm_id: number}[]>([]);

    useEffect(() => {
        getCampaignList();
    }, [])

    // get campaign lists
    function getCampaignList() {
        try {
            setLoading(true);
            listService.campaign_list({
                brand_id: UserReducer.brand_id,
                campaign_status: 'All',
                plan_id: 0
            })
                .then((res: any) => {
                    try {
                        if (res.data.status) {
                            setCampaignList(res.data.rows);
                            setLoading(false);
                        } else {
                            localService.toastify(res.data.status.message, "error", 1000);
                        }
                    } catch (err) {
                        throw new Error(`Data Align Error :: ${err}`);
                    }
                })
                .catch((e: any) => {
                    throw new Error(`Campaign List function error :: ${e}`);
                });

        } catch (error) {
            throw new Error(`campaign List error :: ${JSON.stringify(error)} `);
        } finally {
            setLoading(false);
        }
    }

    //handle checkbox
    const handleCheckboxCampaign = (campaignId: number) => {
        const isSelected = campaignInfluencerIds.map((obj: any) => obj.campaign_id).includes(campaignId);
        if (!isSelected) {
            selectedInfluencer.forEach((obj: any) => {
                setCampaignInfluencerIds((prevIds) => [
                    ...prevIds,
                    {
                        campaign_id: campaignId,
                        influencer_id: obj.influencer_id,
                        sm_id: platform === 'instagram' ? obj.ig_id : obj.yt_id
                    }
                ]);
            });
        } else {
            setCampaignInfluencerIds((prevIds) =>
                prevIds.filter((obj: any) => obj.campaign_id !== campaignId)
            );
        }
    };


    // handle add to campaign
    async function handleAddToCampaign() {
        try {
            if (campaignInfluencerIds.length !== 0) {
                const response = await listService.addToCampaign({
                    user_id: UserReducer.user_id,
                    account_id: UserReducer.account_id,
                    brand_id: UserReducer.brand_id,
                    campaign_influencer_id: campaignInfluencerIds,
                });

                if (response.data.status.status) {
                    localService.toastify("Added to campaign", 'success', 1000);
                    setCampaignInfluencerIds([]);
                } else if (!response.data.status.status && response.data.status.message.includes('upgrade')) {
                    localService.toastify(response.data.status.message, 'info', 3000);
                } else if (!response.data.status.status) {
                    localService.toastify("Please try again", 'error', 1000);
                }
            }
        } catch (error) {
            console.log(
                `saveInfluencerInList error in discover :: ${JSON.stringify(error)}`
            );
        } finally {
            handleCloseAddToCamp();
        }
    }


    return (
        <Fragment>
            <Slide direction="up" in={showPopAddToCampaign} mountOnEnter unmountOnExit>
                <div className="popper-popup">
                    <div className='d-flex popper-popup-header'>
                        <span className='me-auto pb-1 d-block'>Send Campaign</span>
                        <IconButton onClick={() => handleCloseAddToCamp()}><MaterialSymbol fontweight='200' iconName='close'
                        /></IconButton>
                    </div>
                    {loading && <Loading width='80' height='80' />}
                    {campaignList && (
                        <div className="list-section  add-campaign" >
                            <ul>
                                {
                                    campaignList.map((data: any, index: number) => {
                                        return (
                                            <>
                                                {data.Platform.toLowerCase() === platform && (
                                                    <li onClick={() => handleCheckboxCampaign(data.campaign_id)} key={index} className={`${campaignInfluencerIds.map((obj: any) => obj.campaign_id).includes(data.campaign_id) ? "selected-list" : ""}`}>
                                                        {data.Platform === "Instagram" && <InstagramIcon1 className="icon-instagram me-2" width={20} />}
                                                        <span>{data.Platform === "Youtube" && <YouTube />}</span>
                                                        <span>{data.Title}</span>
                                                        <MaterialSymbol iconName='check' fontweight="200" />
                                                    </li>
                                                )}
                                            </>
                                        );
                                    })}
                            </ul>
                        </div>
                    )}
                    <div className="popup-footer">
                        <div className="d-flex justify-content-end">

                            <LoadingButton
                                className="btn btn-primary btn-mui"
                                loadingPosition="start"
                                variant="contained"
                                disabled={campaignInfluencerIds.length === 0}
                                onClick={handleAddToCampaign}
                            >
                                Send
                            </LoadingButton>
                        </div>
                    </div>

                </div>
            </Slide>


        </Fragment>
    );
};


export default AddToCampaign;