import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { CampaignListType } from '../../pages/influencer/my-campaign/influencer-chat';
import { DefaultUserProfile } from '../../config/config';


interface Props {
    campaignDetail: { campaign_title: string, campaign_id: number };
    campaignList: CampaignListType[];
    onChange: (value: CampaignListType) => void;
}


export const CampaignList: React.FC<Props> = ({ campaignList, onChange, ...arg }: Props) => {
    return (
        <Container className={`pro-inf-list-container`} fluid>
            <Row className='pro-inf-chat-label'>
                <Col>
                    <span className='campaign-name'>{arg.campaignDetail.campaign_title}</span>
                    <span className='influencer-count'>{campaignList.length}&nbsp;Campaigns</span>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ul>
                        {campaignList.map((data, index) =>
                            <li key={index} onClick={() => onChange(data)}>
                                <div className="profile">
                                    <img className='profile-image-size' src={data.campaign_cover_url ? data.campaign_cover_url : DefaultUserProfile.influencer_unknown_default} />
                                    <div className='influencer-details '>
                                        <span className='influencer-name'>{data.campaign_title}</span>
                                        {/* <br /> */}
                                        {/* <span className='influencer-username'>{data.username}</span> */}
                                    </div>
                                </div>
                                <span className='proposal-status'>
                                    {data.status}
                                </span>
                                <span className={`message-count ${data.message_count === 0 ? 'inactive-count' : ''}`}>
                                    {data.message_count}
                                </span>

                            </li>
                        )}
                    </ul>
                </Col>
            </Row>
        </Container>
    )
}