import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserLogin, SwitchLayer1, SwitchLayer2 } from "../../../redux/action";
import useBodyClass from "../../../hooks/useBodyClass";
import { LocalService, LoginService, SignUpService, UserService } from "../../../core/services";
import { AddBrand } from "./addbrand";
import { AddInfluencer } from "./add-influencer";
import "./switchuser.scss";
import { Button, SwipeableDrawer } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem, useMediaQuery, Tooltip  } from "@mui/material";
import HeadingStyle from "../../../components/heading-style/heading-style";
import CommonPopUp from "../../../components/popup/common-popup";
import { CommonService } from '../../../core/services/common.service';
import ManageUserModal from "./manageUserModal";
import { createPortal } from 'react-dom';
import { ChevronLeft, Close } from '@mui/icons-material';
import ShowWelcomePopUp from "../../../components/popup/show-welcome";
import MaterialSymbol from "../../../components/icons/material-symbol";
import { DefaultAccountLogo } from "../../../config/config";
import { DefaultUserProfile } from "../../../config/config";
import ConfirmPopup from "../../../components/confirm-popup/confirm-popup";
const localService = new LocalService();
const loginService = new LoginService();
const signupService = new SignUpService();
const commonService:CommonService = new CommonService();
const userService: UserService = new UserService();

interface Props {
  UserReducer: (data: any) => void;
  switchLayer2: any;
  switchLayer1: (data: any) => void;
  SwitchUserData: (data: any) => void;
}

const SwitchUser: React.FC<Props> = (props: Props) => {
  const [usersList, setUsersList] = useState<any>(null);
  const [activeUser, setActiveUser] = useState<any>(null);
  const [showAddBrand, setShowAddBrand] = useState(false);
  const [showAddInfluencer, setShowAddInfluencer] = useState(false);
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState<number>(-1);
  const isScreenLessThan980 = useMediaQuery("(max-width: 980px)");
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [reviewDeactivate, setReviewDeactivate] = useState(false);
  const [navigateValue, setNavigateValue] = useState("");
  const [brandId, setBrandId] = useState<any>(null);
  const [updateData, setUpdateData] = useState<any>({});
  const [showWelcomePopUp, setShowWelcomePopUp] = useState(false)
  const [campaignCounts, setCampaignCounts] = useState([]);
  const [openDeleteBrandPopUp, setOpenDeleteBrandPopUp] = useState(false);
  const [brandToDelete, setBrandToDelete] = useState<any>(null);

  // Manage User
  const [openModalManageUser, setOpenModalManageUser] = useState<boolean>();
  const handleOpenModal = () => setOpenModal(true);
  const handleOpenModalManageUser = (brand_id: any) => {
    setOpenModalManageUser(true);
    setBrandId(brand_id);
  }

  function handleCloseModal() {
    setOpenModal(false);
    setOpenModalManageUser(false);
  }

  async function updateUserReducer() {
    // resent switchLayer1 and switchLayer2 (account/userdata)
    if (props.switchLayer2){
      const latestUserReducerData = await userService.getUserReducerAccountData(props.switchLayer2)
      if (![undefined, null, ''].includes(latestUserReducerData) && latestUserReducerData?.data?.rows[0]?.status === 'Blocked'){
        localService.logout();
      }else if (![undefined, null, ''].includes(latestUserReducerData)){
        props.switchLayer1(latestUserReducerData.data.rows);
        props.SwitchUserData(latestUserReducerData.data.rows[0]);
      }
    }
  }

  async function getAccountData() {
    const accountType = (props.switchLayer2.account_type + "")
    .toString()
    .toLocaleLowerCase();
    let response: any;
    try {
      if (accountType === "agency") {
        response = await loginService.getBrand({
          user_id: props.switchLayer2.user_id,
          account_id: props.switchLayer2.account_id,
        });

        if (response.data.status.status) {
          setUsersList(response.data.brands);
          setCampaignCounts(response.data.brand_campaigns_counts);
        } else {
          localService.toastify(response.data.status.message);
        }
      } 
      else if (accountType === "talent partner") 
      {
        response = await loginService.getInfluencer({
          user_id: props.switchLayer2.user_id,
          account_id: props.switchLayer2.account_id,
        });
        if (response.data.status.status) {
          setUsersList(response.data.rows);
        } else {
          localService.toastify(response.data.status.message);
        }
      }
    } catch (error) {
      throw new Error(`useEffect Promise Error ==> ${JSON.stringify(error)}`);
    }
  }

  const showWelcomePopUpCount = async()=>{
    try {
      const response = await commonService.checkPopup({user_id: props.switchLayer2.user_id, account_id: props.switchLayer2.account_id, popup_name: 'new_platform_welcome'});
      if (response.data.status) {
        setShowWelcomePopUp(true)
      }
    } catch (error: any) {
      throw new Error('getTopInfluencerData', error);
    }
  }
  useEffect(() => {
    getAccountData();
    showWelcomePopUpCount();

  }, [props.switchLayer2, showAddBrand, showAddInfluencer]);

  useEffect(() => {
    updateUserReducer();
    if (activeUser) {
      const accountType = (props.switchLayer2.account_type + "")
        .toString()
        .toLocaleLowerCase();
      if (accountType === "agency") {
        const currentUser = { ...props.switchLayer2, ...activeUser };
        props.UserReducer(currentUser);
        if (navigateValue === "dashboard") {
          navigate(`/brand/dashboard`);
        } else {
          if (navigateValue === "campaigns") {
            navigate("/brand/plans");
          } else {
            navigate("/brand/settings/subscription")
          }
        }
        localService.setStorageItem("userreducer", JSON.stringify(currentUser));
      } else if (accountType === "talent partner") {
        async function getInfluencerData() {
          try {
            let response = await loginService.getInfluencerAccounts({
              user_id: props.switchLayer2.user_id,
              account_id: props.switchLayer2.account_id,
            });

            if (response.data.status > 0) {
              const userData = {
                ...props.switchLayer2,
                influencer_ids: response.data.rows,
                ...activeUser
              };
              props.UserReducer(userData);
              if (navigateValue === "dashboard") {
                navigate(`/influencer/dashboard`);
              } else {
                if (navigateValue === "campaigns") {
                  navigate("/influencer/mycampaigns");
                } else {
                  navigate("/influencer/settings/subscription")
                }
              }
              //without remember me save user Data
              localService.setStorageItem(
                "userreducer",
                JSON.stringify(userData)
              );
            } else {
              localService.toastify(response.data.status.message);
            }
          } catch (error) { }
        }

        getInfluencerData();
      }
    }
  }, [activeUser]);
  const handleSideDrawerData = (data:any) => {
    setOpenAddBrandPopup(data)
    setOpenAddInfluencerPopup(data)
    getAccountData();
  };

  useBodyClass("no-spacing");
  useBodyClass("switch-account");

  const handleButtonClick = (event: any, buttonId: any) => {
    try {
      switch (buttonId) {
        case "Under Review":
          setReviewDeactivate(true);
          handleOpenModal();
          break;

        case "Deactivate":
          setReviewDeactivate(false);
          handleOpenModal();
          break;

        default:
          break;
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleConfirmDelete = () => {
    setOpenDeleteBrandPopUp(false); // Close the confirmation popup
    handleDeleteClick();
  };

  const handleOpenDeletePopup = (brand:any) => {
    setBrandToDelete(brand); // Store the brand to delete
    setOpenDeleteBrandPopUp(true); // Open the confirmation popup
  };

  async function setActiveUserMethod(data: any, value: any) {
    if (data.status != 'Under Review' && data.status != 'Deactivate') {
      setNavigateValue(value);
      setActiveUser(data);
    }
  }
  async function handleUpdateClick(data: any) {
    try {
      setUpdateData(data);
      if (props.switchLayer2.account_type === "Talent Partner") {
        setShowAddInfluencerMethod(data);
      }
      if (props.switchLayer2.account_type === "Agency") {
        setShowAddBrandMethod(data)
      }
    } catch (error) {
      console.log(`handleUpdateClick error :: ${JSON.stringify(error)}`);
    }
  }

  async function handleDeleteClick() {
    if (brandToDelete) {
      const response = await signupService.deleteBrand({
        brand_id: brandToDelete?.brand_id,
        account_id: props.switchLayer2.account_id,
      });

      if (response) {
        const res = await loginService.getBrand({
          user_id: props.switchLayer2.user_id,
          account_id: props.switchLayer2.account_id,
        });

        if (res.data.status.status) {
          setUsersList(res.data.brands);
        } else {
          localService.toastify(res.data.status.message);
        }
      }
    }
  }

  async function handleGetInfluencerData(influencer_id:any)
  {
    const response = await loginService.getTalentAccountDetail({
      influencer_id: influencer_id,
    });
    if(response.data.rows){
      setUpdateData({...response.data.rows[0]});

    }

  }

  async function setShowAddInfluencerMethod(data:any=null) {
    if(data){
      handleGetInfluencerData(data.influencer_id)
    }
    else{
      setUpdateData({});
    }
    setOpenAddInfluencerPopup(true);
  }

  async function setShowAddBrandMethod(data:any=null) {
    if(data){
      setUpdateData({...data});

    }
    else{
      setUpdateData({});

    }
    setOpenAddBrandPopup(true);
  }

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  const drawerAnchor = isSmallScreen ? 'bottom' : 'right';
  const [openAddInfluencerPopup, setOpenAddInfluencerPopup] = useState(false);
  const [openAddBrandPopup, setOpenAddBrandPopup] = useState(false);

  const handleAddInfluencerPopupClose = () => {
    setUpdateData({});
    setOpenAddInfluencerPopup(false);
  };
  const handleAddBrandPopupClose = () => {
    setUpdateData({});
    setOpenAddBrandPopup(false);
  };
  
  return (
    <>
      {openModalManageUser && (
        <ManageUserModal open={openModalManageUser}
          onClose={() => setOpenModalManageUser(false)} brandId={brandId} agencyAccountId={props.switchLayer2.account_id} />
      )}
      {/* For Manage Users............................... */}
      {openModal && (
        <CommonPopUp
          className="popup-talent-verify"
          modalContent={modalContent}
          openModal={openModal}
          onClose={() => handleCloseModal()}
        >
          {reviewDeactivate ? (
            <div className="common-popup">
              <p className="para1">
                This account is under review by our team. Once verified, you can
                start applying to campaigns for this account.
              </p>
              <p className="para2">
                To raise a concern, please write to us at
                <a> "influencer.support@clanconnect.ai"</a>
              </p>
            </div>
          ) : (
            <div className="common-popup">
              <p className="para1">
                Oops, our verification process shows that this influencer is not
                directly managed by you.
              </p>
              <p className="para2">
                To raise a concern, please write to us at
                <a>"influencer.support@clanconnect.ai"</a>
              </p>
            </div>
          )}
        </CommonPopUp>
      )}
      <div className="container-fluid _switch_container_fluid common-bg">
        <div className="container _switch_container">
          <div className="row _switch_main_row">
            <div className="_switch_main_col row">
              <div className="col">
                {props.switchLayer2.account_type === "Agency" &&
                  !showAddBrand && (
                    <div className="contentContaainerDivHeading">
                    <div className="d-flex"><HeadingStyle title="All Brands" /><span className="ms-1 fw-600" style={{paddingTop:'2px'}}>({usersList && usersList.length})</span></div>
                      <Button
                        className="btn btn-primary"
                        variant="contained"
                        onClick={setShowAddBrandMethod}
                      >
                        Add Brand
                      </Button>
                    </div>
                  )}
                {props.switchLayer2.account_type === "Talent Partner" &&
                  !showAddInfluencer && (
                    <div className="contentContaainerDivHeading">
                      <HeadingStyle title="All Influencers" />
                      <Button
                        className="btn btn-primary"
                        variant="contained"
                        onClick={setShowAddInfluencerMethod}
                      >
                        Add Influencer
                      </Button>
                    </div>
                  )}
              </div>
              {/* ADD BRAND */}
              {createPortal(
                <SwipeableDrawer
                  anchor={drawerAnchor}
                  open={openAddBrandPopup}
                  onClose={handleAddBrandPopupClose}
                  onOpen={setShowAddBrandMethod}
                  transitionDuration={300}
                  className='popup-drawer drawer-infl-dashboard drawer-add-infl'
                  disableSwipeToOpen={false}
                  hysteresis={0.8}
                  ModalProps={{
                    keepMounted: true,
                  }}
                >
                  {/* POPUP DRAWER HEADER */}
                  <div className='popup-drawer-header'>
                    <div className='popup-drawer-header-left'>
                      <IconButton className="text-black" onClick={handleAddBrandPopupClose}>
                        <ChevronLeft className='d-none d-md-inline-flex' />
                        <Close className='d-inline-flex d-md-none' />
                      </IconButton>
                      <strong className='ps-3'>Add Brand</strong>
                    </div>

                  </div>
                  {/* END POPUP DRAWER HEADER */}
                  <div className='popup-drawer-body pt-4'>
                    <AddBrand
                      updateShowBrand={(e) => setShowAddBrand(e)}
                      data={updateData} popUpOpen={handleSideDrawerData}
                      account_id={props?.switchLayer2?.account_id}
                    />
                  </div>
                </SwipeableDrawer>
                , document.body)}


              {/* ADD INFLUENCER */}
              {createPortal(
                <SwipeableDrawer
                  anchor={drawerAnchor}
                  open={openAddInfluencerPopup}
                  onClose={handleAddInfluencerPopupClose}
                  onOpen={setShowAddInfluencerMethod}
                  transitionDuration={300}
                  className='popup-drawer drawer-infl-dashboard drawer-add-infl'
                  // swipeAreaWidth={drawerBleeding}
                  disableSwipeToOpen={false}
                  hysteresis={0.8}
                  ModalProps={{
                    keepMounted: true,
                  }}
                >
                  {/* POPUP DRAWER HEADER */}
                  <div className='popup-drawer-header'>
                    <div className='popup-drawer-header-left'>
                      <IconButton className="text-black" onClick={handleAddInfluencerPopupClose}
                      >
                        <ChevronLeft className='d-none d-md-inline-flex' />
                        <Close className='d-inline-flex d-md-none' />
                      </IconButton>
                      <strong className='ps-3'>Add Influencer</strong>
                    </div>

                  </div>
                  {/* END POPUP DRAWER HEADER */}
                  <div className='popup-drawer-body pt-4'>
                    <AddInfluencer
                      account_id={props.switchLayer2.account_id}
                      user_id={props.switchLayer2.user_id}
                      updateShowInfluencer={(e) => setShowAddInfluencer(e)}
                      data={updateData} popUpOpen={handleSideDrawerData}
                    />
                  </div>
                </SwipeableDrawer>
                , document.body)}

              <hr className="mt-3" />
              {(usersList?.length === 0 && (
                props.switchLayer2.account_type + ""
              ).toLocaleLowerCase() === "agency") && <span className="text-center py-5">Add a brand to start exploring or posting campaigns!</span>}
              {(usersList?.length === 0 &&
                props.switchLayer2.account_type === "Talent Partner") &&
                <span className="text-center py-5">Add an influencer now to explore and apply to campaigns!</span>
              }
              {(usersList && usersList?.length > 0) && 
                usersList.map((res: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      {(
                        props.switchLayer2.account_type + ""
                      ).toLocaleLowerCase() === "agency" ? (
                        <div className="contentDivBrand">
                          <div className="contentDivLeft">
                            <div
                              className="contentDivLeftLeft"
                              onClick={(e) =>
                                setActiveUserMethod(res, "dashboard")
                              }
                              key={index}

                            >
                              <img
                                className="contentDivLeftLeftImage activate"
                                  src={res.logo_url ? res.logo_url : DefaultAccountLogo.brand}
                                alt="logo"
                              />
                            </div>
                            <div className="contentDivLeftRight">
                              <div className="contentDivLeftRightName">
                                <span
                                  className="contentDivLeftRightNameSpan activate"
                                  onClick={(e) =>
                                    setActiveUserMethod(res, "dashboard")
                                  }
                                  key={index}
                                >
                                  {res.brand_name}

                                </span>
                              </div>
                              <div className="inboxAndCampaignDiv">
                                <div className="contentDivLeftRightCampaigns activate">
                                  <FolderOpenIcon />
                                  <span
                                    className="contentDivLeftRightCampaignsSpan"
                                    onClick={(e) =>
                                      setActiveUserMethod(res, "campaigns")
                                    }
                                  >
                                    Campaigns {' '}
                                    {
                                    campaignCounts.map((data:any) => {
                                      if (data.brand_id == res.brand_id) {
                                        return (
                                          <Tooltip title="Live & Completed Campaign" arrow>
                                           <span key={data.brand_id}>
                                              ({data.live_count + data.completed_count})
                                            </span>
                                          </Tooltip>
                                         );
                                      }
                                      return null;
                                    })
                                  }
                                  </span>     
                                </div>
                              </div>
                              <div className="contentDivLeftRightPremium">
                                {
                                  res.remaining_days
                                    ?
                                    <span
                                      className="pro-tag contentDivLeftRightPremiumSpan"
                                        style={{ position: 'static' }}
                                    >
                                      {/* {res.remaining_days} */}
                                        PRO
                                    </span>
                                    :
                                    <span style={{position:'static'}}
                                      className="contentDivLeftRightPremiumSpan"
                                      onClick={(e) =>
                                        setActiveUserMethod(res, "subscription")
                                      }
                                    >
                                      Go Premium
                                    </span>
                                }
                              </div>
                            </div>
                          </div>
                          {
                            (() => {
                              // will be changed according to the mapping
                              if (res.role_name === null) {
                                return (
                                  <>
                                    <div className="contentDivRight">
                                      {isScreenLessThan980 ? (
                                        <IconButton className="text-black"
                                          edge="start"
                                          color="inherit"
                                          aria-label="menu"
                                          onClick={() => setMenuOpen(index)}
                                        >
                                          <MoreVertIcon id={`more-vert-icon-${index}`} />
                                        </IconButton>
                                      ) : (
                                        <div className="contentDivRightUpdateDelete">
                                          <span
                                            className="contentDivRightUpdateDeleteUpdate pe-2"
                                          >
                                            <IconButton className="text-black"
                                              onClick={(e) => handleUpdateClick(res)}>
                                              <MaterialSymbol fontweight="200"
                                                 iconName="edit" />
                                            </IconButton>
                                          </span>
                                          <span className="contentDivRightUpdateDeleteDelete">
                                          <IconButton
                                            className="text-black"
                                            onClick={() => handleOpenDeletePopup(res)}
                                          >
                                            <MaterialSymbol fontweight="200" iconName="delete" />
                                          </IconButton>
                                        </span>

                                          {(() => {
                                            if (props.switchLayer2.account_type == "Agency") {
                                              return (
                                                <>
                                                  <span className="contentDivRightUpdateDeleteDelete">
                                                    <IconButton className="text-black" 
                                                    onClick={(e) =>
                                                      handleOpenModalManageUser(res.brand_id)}>
                                                    <MaterialSymbol fontweight="200"
                                                     iconName="person_add" />
                                                  </IconButton>
                                                  </span>
                                                </>)
                                            }
                                          })()}

                                        </div>
                                      )}

                                      <Menu
                                        anchorEl={
                                          menuOpen
                                            ? document.getElementById(
                                              `more-vert-icon-${index}`
                                            )
                                            : null
                                        }
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "right",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                        open={menuOpen === index}
                                        onClose={() => setMenuOpen(-1)}
                                      >
                                        <MenuItem onClick={() => handleUpdateClick(res)}>
                                          <ModeEditIcon />
                                        </MenuItem>
                                        <MenuItem
                                        >
                                          <DeleteIcon onClick={(e) =>{setOpenDeleteBrandPopUp(true)}} />
                                        </MenuItem>
                                        {(() => {
                                          if (props.switchLayer2.account_type === "Agency") {
                                            return (
                                              <>
                                                <MenuItem>
                                                  <PersonAddAlt1Icon onClick={(e) =>
                                                    handleOpenModalManageUser(res.brand_id)} />
                                                </MenuItem>
                                              </>)
                                          }
                                        })()}
                                      </Menu>
                                    </div>
                                  </>
                                )
                              }

                            })()
                          }

                        </div>
                      ) : (
                        <div className="contentDivBrand">
                          <div className="contentDivLeft">
                            <div
                              className="contentDivLeftLeft"
                              onClick={(e) =>
                                setActiveUserMethod(res, "dashboard")
                              }
                              key={index}
                            >
                              <img
                                className={`contentDivLeftLeftImage ${res.status === "Activate" ? "activate" : ""}`}
                                src={res.photo === "" ? DefaultUserProfile.influencer_unknown_default : res.photo}
                                alt={res.photo === "" ? "Default user profile image" : "User profile image"}
                              />
                            </div>

                            <div className="contentDivLeftRight">
                              <div className="contentDivLeftRightName">
                                <span
                                  className={`contentDivLeftRightNameSpan ${res.status === "Activate" ? "activate" : ""}`}
                                  onClick={(e) =>
                                    setActiveUserMethod(res, "dashboard")
                                  }
                                  key={index}
                                >
                                  {res.name}
                                </span>
                              </div>
                              <div className="inboxAndCampaignDiv">
                                <div className={`contentDivLeftRightCampaigns ${res.status === "Activate" ? "activate" : ""}`}>
                                  <FolderOpenIcon />
                                  <span
                                    className="contentDivLeftRightCampaignsSpan"
                                    onClick={(e) =>
                                      setActiveUserMethod(res, "campaigns")
                                    }
                                  >
                                    Campaigns
                                  </span>
                                </div>
                              </div>
                              <div className="contentDivLeftRightPremium">
                                {res.status === "Under Review" && (
                                  <span
                                    className="contentDivLeftRightPremiumSpan"
                                    style={{ color: "#00aae5" }}
                                    onClick={(e) =>
                                      handleButtonClick(e, "Under Review")
                                    }
                                  >
                                    Under Review
                                  </span>
                                )}
                                {res.status === "Activate" && (
                                  res.remaining_days
                                    ?
                                    <span
                                      className="pro-tag contentDivLeftRightPremiumSpan"
                                      style={{ position:'static' }}
                                    >
                                      PRO
                                    </span>
                                    :
                                    <span
                                      className="contentDivLeftRightPremiumSpan"
                                      onClick={(e) =>
                                        setActiveUserMethod(res, "subscription")
                                      }
                                    >
                                      Go premium
                                    </span>
                                )}
                                {res.status === "Deactivate" && (
                                  <span
                                    className="contentDivLeftRightPremiumSpan"
                                    onClick={(e) =>
                                      handleButtonClick(e, "Deactivate")
                                    }
                                  >
                                    Unauthorized
                                  </span>
                                )}
                              </div>
                              
                              
                            </div>
                          </div>
                          {
                        (() => {
                            // will be changed according to the mapping
                          if (res.role_name == null) {
                            return (
                            <>
                          {res.status === "Under Review" &&( 
                          <div className="contentDivRight">
                            {isScreenLessThan980 ? (
                              <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={() => setMenuOpen(index)}
                              >
                                <MoreVertIcon id={`more-vert-icon-${index}`} />
                              </IconButton>
                            ) : (
                              <div className="contentDivRightUpdateDelete">
                                <span
                                  className="contentDivRightUpdateDeleteUpdate"
                                  onClick={(e) => handleUpdateClick(res)}
                                >
                                  <ModeEditIcon />
                                </span>
                                {/* <span className="contentDivRightUpdateDeleteDelete">
                                  <DeleteIcon />                                  
                                </span> */}
                                {(()=>{
                                  if(props.switchLayer2.account_type == "Agency"){
                                    return (
                                    <>
                                      <span className="contentDivRightUpdateDeleteDelete">
                                      <PersonAddAlt1Icon onClick ={(e)=>
                                        handleOpenModalManageUser(res.brand_id)}/>
                                      </span>
                                    </>)
                                  }
                                })()}

                              </div>
                            )}

                            <Menu
                              anchorEl={
                                menuOpen
                                  ? document.getElementById(
                                      `more-vert-icon-${index}`
                                    )
                                  : null
                              }
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              open={menuOpen === index}
                              onClose={() => setMenuOpen(-1)}
                            >
                              <MenuItem onClick={() => handleUpdateClick(res)}>
                                <ModeEditIcon />
                              </MenuItem>
                              <MenuItem
                              >
                                <DeleteIcon />
                              </MenuItem>
                              {(()=>{
                                  if(props.switchLayer2.account_type == "Agency"){
                                    return (
                                    <>
                                      <MenuItem>
                                      <PersonAddAlt1Icon onClick ={(e)=>
                                        handleOpenModalManageUser(res.brand_id)}/>
                                      </MenuItem>
                                    </>)
                                  }
                                })()}
                            </Menu>
                          </div>
                            )}
                            </>
                            )
                          } 
                          
                        })()
                          }


                          <Menu
                            anchorEl={
                              menuOpen
                                ? document.getElementById(
                                  `more-vert-icon-${index}`
                                )
                                : null
                            }
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            open={menuOpen === index}
                            onClose={() => setMenuOpen(-1)}
                          >
                            <MenuItem onClick={() => handleUpdateClick(res)}>
                              <ModeEditIcon />
                            </MenuItem>
                            <MenuItem
                            >
                              {/* <DeleteIcon onClick={(e) => handleDeleteClick()} /> */}
                            </MenuItem>
                            {(() => {
                              if (props.switchLayer2.account_type === "Agency") {
                                return (
                                  <>
                                    <MenuItem>
                                      <PersonAddAlt1Icon onClick={(e) =>
                                        handleOpenModalManageUser(res.brand_id)} />
                                    </MenuItem>
                                  </>)
                              }
                            })()}
                          </Menu>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      {showWelcomePopUp && (
            <ShowWelcomePopUp
            openUpgradeBrandModal={showWelcomePopUp}
            onClose={() => setShowWelcomePopUp(false)}
            popupMinWidth='630px'
          />

      )}

      {openDeleteBrandPopUp && createPortal(
        <ConfirmPopup
          confirmPopupInnerText={`Are you sure you want to delete this brand?`}
          openPopup={openDeleteBrandPopUp}
          confirmClosePopup={() => setOpenDeleteBrandPopUp(false)}
          confirmClickAction={handleConfirmDelete}
          drawerTitle=""
        />,
        document.body
      )}


    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    switchLayer2: state.SwitchLayer2Reducer.switchUser,
  };
};

const mapStateToDispatch = (dispatch: any) => {
  return {
    UserReducer: (data: any) => dispatch(UserLogin(data)),
    switchLayer1: (data: any) => dispatch(SwitchLayer1(data)),
    SwitchUserData: (data: any) => dispatch(SwitchLayer2(data)),
  };
};

const connectToRedux = connect(mapStateToProps, mapStateToDispatch)(SwitchUser);

export { connectToRedux as SwitchUser };
