import React from "react";
import { Outlet} from "react-router-dom";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Breadcrumbs } from "@mui/material";
import "./campaign-main.scss";
interface Props {
  Navigation: any;
  HiddenTab: any;
  completeStep: any;
  createPlan: any;
}

const MainCampaign: React.FC<Props> = (props: Props) => {
  return (
    <div className=" main_campaign_main_container">
      <div className="container">
        <div className="row">
          <div className="col breadcrumb">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                href={"createplan"}
                className={`link ${props.completeStep.createPlan ? "complete" : ""
                  }`}
              >
                Plan
              </Link>

              {props.Navigation.campaign_create ? (
                <Link
                  className={`link ${props.completeStep.campaign_create ? "complete" : ""
                    }`}
                  href={"createcampaign"}
                >
                  Campaign
                </Link>
              ) : (
                <Typography className="link">Campaign</Typography>
              )}

              {props.HiddenTab.createProduct &&
                props.Navigation.createProduct ? (
                <Link
                  className={`link ${props.completeStep.createProduct ? "complete" : ""
                    }`}
                  href={"createproduct"}
                >
                  Product
                </Link>
              ) : (
                <Typography className="link">Product</Typography>
              )}

              {props.Navigation.influencer ? (
                <Link
                  className={`link ${props.completeStep.createProduct ? "complete" : ""
                    }`}
                  href={"influencer"}
                >
                  Influencer
                </Link>
              ) : (
                <Typography className="link">Influencer </Typography>
              )}
              {props.Navigation.post ? (
                <Link
                  className={`link ${props.completeStep.post ? "complete" : ""
                    }`}
                  href={"post"}
                >
                  Post
                </Link>
              ) : (
                <Typography className="link">Post </Typography>
              )}
            </Breadcrumbs>
          </div>
        </div>
        <div className="row">
          <div className="col brand_campaigns_pages">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    HiddenTab: state.CreateCampaignReducer.hiddenTab,
    completeStep: state.CreateCampaignReducer.completeStep,
    Navigation: state.CreateCampaignReducer.navigation,
    createPlan: state.CreateCampaignReducer.createPlan,
  };
};

const connectToRedux = connect(mapStateToProps, null)(MainCampaign);
export { connectToRedux as MainCampaign };
