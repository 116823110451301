import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

interface Column {
  id:
  | "channellink"
  | "channelname"
  | "phone"
  | "email"
  | "gender"
  | "location"
  | "categoryofinfluencer"
  | "subscribers"
  | "campaign"
  | "brand"
  | "campaignhashtags"
  | "costdedicatedvideos"
  | "costintegratedvideos"
  | "avgviewofvideo"
  | "brandworked";

  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "channellink", label: "Channel Link", minWidth: 170 },
  { id: "channelname", label: "Channel Name", minWidth: 150 },
  { id: "phone", label: "Phone", minWidth: 100 },
  { id: "email", label: "Email", minWidth: 100 },
  { id: "gender", label: "Gender", minWidth: 100 },
  { id: "location", label: "Location", minWidth: 100 },
  {
    id: "categoryofinfluencer",
    label: "Influencer Category",
    minWidth: 170,
  },
  { id: "subscribers", label: "Subscribers ", minWidth: 100 },
  { id: "campaign", label: "Campaign ", minWidth: 100 },
  { id: "brand", label: "Brand", minWidth: 100 },
  { id: "campaignhashtags", label: "Campaign Hashtags", minWidth: 170 },
  {
    id: "costdedicatedvideos",
    label: "Cost of Dedicated Videos",
    minWidth: 200,
  },
  {
    id: "costintegratedvideos",
    label: "Cost of Integrated Videos",
    minWidth: 200,
  },
  { id: "avgviewofvideo", label: "Average View of a Video", minWidth: 200 },
  {
    id: "brandworked",
    label: "Brands worked with (Past 3 Months)",
    minWidth: 300,
  },
];

interface Data {
  channellink: string;
  channelname: string;
  phone: number;
  email: string;
  gender: number;
  location: string;
  categoryofinfluencer: string;
  subscribers: string;
  campaign: string;
  brand: string;
  campaignhashtags: string;
  costdedicatedvideos: string;
  costintegratedvideos: string;
  avgviewofvideo: string;
  brandworked: string;
}

export const InfluencerYoutube: React.FC = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [influencerData, setInfluencerData] = useState<Data[]>([]);

  useEffect(() => {
    loadDataFromLS();
  }, [influencerData]);

  const loadDataFromLS = () => {
    const storedData = localStorage.getItem("formValues");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setInfluencerData(parsedData);
    }
  };

  const addDataToLS = (data: Data) => {
    const updatedData = [...influencerData, data];
    localStorage.setItem("formValues", JSON.stringify(updatedData));
    setInfluencerData(updatedData);
  };

  return (
    <section>
      <div className="talent_verification_tab">
        <TableContainer
          className="common-table"
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead className='common-table-header'>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className='common-table-body'>
              {influencerData.map((row, index) => (
                <TableRow key={index}>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align}>
                      {row[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
      </div>
    </section>
  );
};
