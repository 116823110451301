import React from "react";
import { useState, useEffect } from "react";
import { DefaultUserProfile } from "../../config/config";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Tooltip } from "@mui/material";
import useBodyClass from "../../hooks/useBodyClass";

interface Props {
  data: any;
  unreadMsg?: any;
  selected_campaign?: any;
  influencer_type?: any;
  clickOnCampaign: (campaign: any) => void;
}

export const CampaignList: React.FC<Props> = (props: Props) => {
  const [searchValue, setSearchValue] = useState("");
  const [campaign_list, set_campaign_list] = useState(props.data);

  useEffect(() => {
    set_campaign_list(props.data);
  }, [props.data]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    setSearchValue(event.target.value);
    const filteredList = props.data.filter((campaign: any) =>
      campaign.campaign_title.toLowerCase().includes(value)
    );
    set_campaign_list(filteredList);
  };

  useBodyClass("page-btm-spc-exact")
  return (
    <div className="list-container-box">
      <div className="pro-inf-chat-label">
        <div className="search-inbox-chat">
          <TextField
            className="search-inbox-chat-text"
            fullWidth
            variant="outlined"
            placeholder="Search campaign..."
            value={searchValue}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <div className={`pro-inf-list-container-inbox`}>
        <div>
          <ul className="profile-ul">
            {campaign_list.map((data: any, index: number) => (
              <li
                className={`profile-li ${
                  props.selected_campaign &&
                  props.selected_campaign.campaign_id == data.campaign_id
                    ? "active-profile"
                    : ""
                }`}
                key={index}
                onClick={() => props.clickOnCampaign(data)}
              >
                <div className="profile">
                  <img
                    className="profile-image-size"
                    src={
                      data.campaign_cover_url
                        ? data.campaign_cover_url
                        : DefaultUserProfile.influencer_unknown_default
                    }
                  />
                  <div className="influencer-details ">
                    <span className="influencer-name">
                      <Tooltip arrow title={data.campaign_title}>
                        <>{data.campaign_title}</>
                      </Tooltip>
                    </span>
                  </div>
                  {props.unreadMsg
                    .filter((msg: any) => msg.campaign_id == data.campaign_id)
                    .map((msg: any, idx: number) => (
                      <span className="unread-msg">
                        {msg.unread_messages}
                      </span>
                    ))}
                </div>
                
                  
                  <span className="proposal-status">{data.status}</span>
                
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
