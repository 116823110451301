import React, { useState, useEffect } from "react";
// import "./detailed-analytics.scss";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Loading } from "../../../redux/action";
import { connect } from "react-redux";
import { AnalyticsService } from "../../../core/services/analytics.service"
import "./analytics.scss";
import {
  Tab} from "@mui/material";
import {
  TableComponent,
  SortingColumnsType,
} from "../../../../src/components/table-component/table"

import { Visibility, ThumbUp, Chat, YouTube } from '@mui/icons-material';
import AnalyticsDashboard from './components/analytics-dashboard';
import { MostEngagingPosts } from './components/most-engaging-posts';
import { MostEngagingInfluencer } from './components/most-engaging-influencer';
import DetailedInsightsPost from './components/detailed-insights-post';
import DetailedInsightsInfluencer from './components/detailed-insights-influencer';
import AddAnalyticsBrand from "./add_analytics_brand";
import UpgradeBrandPopUp from "../../../components/popup/upgrade-brand-popup";
import ROI from "./roi";
import dayjs from "dayjs";
import CommonPopUp from "../../../components/popup/common-popup";



interface Props {
  UserReducer: any;
  UserID: any;
  loading: (data: any) => void;
  selectCampaign: any;
  getSubscriptionDetail:any;
}

const analyticsService = new AnalyticsService();
const columnDisplayNamePostDetailedData = {
  er: "ER",
  video_views: "Views",
  likes: "Likes",
  comments: "Comments",
  created_at: "Date"

};
const columnDisplayNameInfluencerDetailedData = {
  total_post: "Post",
  total_er: "ER",
  total_views: "Views",
  like_count: "Likes",
  total_comments: "Comments"

};

const PostDetailedData: SortingColumnsType[] = [
  {
    name: "",
    columnSize: "45%",
  },
  {
    name: "ER",
    columnSize: "10%",
  },
  {
    name: "Views",
    columnSize: "10%",
  },
  {
    name: "Likes",
    columnSize: "10%",
  },
  {
    name: "Comments",
    columnSize: "10%",
  },
  {
    name: "Posted on",
    columnSize: "15%",
  },
];

const InfluencerDetailedData: SortingColumnsType[] = [

  {
    name: "",
    columnSize: "50%",
  },
  {
    name: "Post",
    columnSize: "10%",
  },
  {
    name: "ER",
    columnSize: "10%",
  },
  {
    name: "Views",
    columnSize: "10%",
  },
  {
    name: "Likes",
    columnSize: "10%",
  },
  {
    name: "Comments",
    columnSize: "10%",
  },

];


const DetailedAnalyticsYoutube: React.FC<Props> = (props: Props) => {
  const [value, setValue] = useState("1");
  const [value2, setValue2] = useState("1");
  const [value4, setValue4] = useState("1");
  const [analyticsCumulativeDataYtNumericData, setanalyticsCumulativeDataYtNumericData] = useState<any>([]);
  const [analyticsCumulativeDataYtMediaData, setAnalyticsCumulativeDataYtMediaData] = useState<any>([]);
  const [postFilterData, setPostFilterData] = useState<any>([]);
  const [influencerFilterData, setInfluencerFilterData] = useState<any>([]);
  const [graphData, setGraphData] = useState<any>([]);
  const [campaignId, setCampaignId] = useState(0)
  const [campaignPlatform, setCampaignPlatform] = useState("")
  const [totalEr, setTotalEr] = useState(0)
  const [smId, setSmId] = useState(0)
  const [postAnalyticsEr, setPostAnalyticsEr] = useState<any>([])
  const [influencerAnalyticsEr, setInfluencerAnalyticsEr] = useState<any>([])
  const [totalCost, setTotalCost] = useState(0);
  const [totalPost, setTotalPost] = useState(0);
  const [openUpgradeBrandModal, setOpenUpgradeBrandModal] = useState(false);
  const [openModalROI, setOpenModalROI] = useState<boolean>(false);
  const [addedLinksDetails, setAddedLinksDetails] = useState<any>([]);
  const [buttonClass, setButtonClass] = useState<string>('btn btn-primary disabled');
  const [openModal, setOpenModal] = useState(false);


  // -----------------Open Model-----------------------
  const [openModalAddPost, setOpenModalAddPost] = useState<boolean>();

  // -------------
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = useState('');

  // -----------
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleChange2 = (event: React.SyntheticEvent, newValue: string) => {
    setValue2(newValue);
  };

  const handleChange4 = (event: React.SyntheticEvent, newValue: string) => {
    setValue4(newValue);
  };



  // -----------------------------------

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };



  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value: any) => {
    setSelectedValue(value);
    handleCloseMenu();
  };




  // -----------------------------------------------------------------------------------------------

  const handleOpenModalAnalytics = (campaign_id: number, campaign_platform: string) => {
    setOpenModalAddPost(true);
    setCampaignId(campaign_id);
    setCampaignPlatform(campaign_platform);

  }


  useEffect(() => {
    analyticsData();
    getPostLinkData();
  }, []);

  const analyticsData = async () => {
    let campaign_id = props?.selectCampaign?.campaign_id;
    const res:any = await analyticsService.brandAnalyticsYoutube({ campaign_id: campaign_id, sm_id: null });
    if (res?.data?.status) {
      setAnalyticsCumulativeDataYtMediaData(res?.data?.analytics_data_yt_media[0])
      if(res?.data?.analytics_data_yt_numeric.length > 0){
        setanalyticsCumulativeDataYtNumericData(res?.data?.analytics_data_yt_numeric[0])
      }
      setPostFilterData(res?.data?.post_data_content)
      setInfluencerFilterData(res?.data?.influencer_data_content)
      setGraphData(res?.data?.graph_data_array)
      setTotalEr(res?.data?.total_er)
      setPostAnalyticsEr(res?.data?.yt_post_analytic_er)
      setInfluencerAnalyticsEr(res?.data.yt_influencer_analytic_er)
      setTotalPost(res.data.total_posts)
    }
  }

  const getPostLinkData = async () => {
    const campaignId = props?.selectCampaign?.campaign_id
    const res = await analyticsService.getAnalyticsLinks(campaignId)

    if (res.data.status) {
      setAddedLinksDetails(res.data.rows)

      //Today Date
      let currentDate = dayjs().format("YYYY-MM-DD HH:mm");
      // let currentDate = "2024-08-09 09:50"
      // Add 72 hours to the refreshed_at timestamp
      let refreshedAtTimestamp = dayjs(addedLinksDetails[0]?.refreshed_at).add(72, 'hour');
      // Format the new timestamp as "YYYY-MM-DD HH:mm"
      const newRefreshTimeStamp = refreshedAtTimestamp.format("YYYY-MM-DD HH:mm");

      if ((currentDate >= newRefreshTimeStamp) && addedLinksDetails[0]?.refreshed_count === 1) {
        setButtonClass('btn btn-primary')
      }
      else if ((currentDate >= newRefreshTimeStamp) && addedLinksDetails[0]?.refreshed_count === 2) {
        setButtonClass('btn btn-primary')
      }
      else if (addedLinksDetails[0]?.refreshed_count === 3 || addedLinksDetails[0]?.refreshed_count === 1) {
        setButtonClass('btn btn-primary disabled')
      }
    }
  }

  const refreshAnalytics = async () => {
    const campaign_id = props?.selectCampaign?.campaign_id
    const res = await analyticsService.analyticsRefreshInight({ campaign_id })

    if (res.data.status) {
      window.alert("Updated Successfully")
    }
    getPostLinkData();
  }

  const likesOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Likes Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData?.map((entry: any) => entry.post_date),
    },
    yAxis: {
      title: {
        text: 'Total Likes',
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: 'Total Likes',
        data: graphData?.map((entry: any) => entry.likes),
      },
    ],
    exporting: {
      enabled: false, // Disables exporting
    },
  };

  const playsOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Views Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData?.map((entry: any) => entry.post_date),
      
    },
    legend: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: 'Total Views',
      },
    },
    credits: {
      enabled: false
    },
    
    series: [
      {
        name: 'Total Views',
        data: graphData?.map((entry: any) => entry.views),
      },
    ],
    exporting: {
      enabled: false, // Disables exporting
    },
  };
  const commentOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Comments Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData?.map((entry: any) => entry.post_date),
    },
    yAxis: {
      title: {
        text: 'Total Comments',
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: 'Total Comments',
        data: graphData?.map((entry: any) => entry.comments),
      },
    ],
    exporting: {
      enabled: false, // Disables exporting
    },
  };

  const handleOpenUpgradeBrandModal = (value:boolean) => {
    setOpenUpgradeBrandModal(true)
  };

  function handleClosePopUps() {
    setOpenUpgradeBrandModal(false)
    setOpenModal(false)
  }

  return (
    <>
      {openModalAddPost && (
        <AddAnalyticsBrand open={openModalAddPost}
          onClose={() => setOpenModalAddPost(false)}
          post_platform={campaignPlatform}
          campaign_id={campaignId}
          sm_id={smId}
          totalPost={totalPost}
          getSubscriptionDetail={props.getSubscriptionDetail}
          handleOpenUpgradeBrandModal={handleOpenUpgradeBrandModal}
          campaignType={props.selectCampaign.campaign_type}
        />
      )}
      {openModal && (
        <CommonPopUp
          modalTitle={''}
          modalContent=""
          openModal={openModal}
          onClose={handleClosePopUps}
          className="modal-medium mx-auto"
        >
          <div className="">
            <div>
              <p>It takes a few minutes to get the data.Till we do the task, 
              sit back and relax or just have a cup of tea.</p>
            </div>

            <div className='popup-footer' style={{ marginTop: "32px" }}>
              <div className='row'>
                <div className='col text-end'>
                    <a className="btn btn-primary ms-2 btn-mui" onClick={handleClosePopUps}>
                      Ok
                    </a>
                </div>
              </div>
            </div>
          </div>
        </CommonPopUp>
      )}
      <div className="brand-analytics-youtube detailed-analytics">
        <div className="detailed-analytics-content">
          <div className="container custom-container">
            <div className="row project-detail-sec">
              <div className="col-md-12">
                <div className="button-div">
                  <span className="btn btn-outline-primary add"
                    onClick={(e) => {
                      handleOpenModalAnalytics(props.selectCampaign.campaign_id, props.selectCampaign.campaign_platform)
                    }} >
                    Add Post
                  </span>

                  {props.getSubscriptionDetail && props.getSubscriptionDetail.plan_id === 9 && (
                    <button className={buttonClass} onClick={refreshAnalytics}>
                      Refresh Insight
                    </button>
                  )}

                   <a href="#" className="" onClick={() => setOpenModalROI(true)}> ROI Generator</a>

                </div>


                <div className="upper-grid">

                  <AnalyticsDashboard postFilterData={postFilterData} analyticsCumulativeDataMediaData={analyticsCumulativeDataYtMediaData} campaign_platform={props?.selectCampaign?.campaign_platform} analyticsCumulativeDataNumericData={analyticsCumulativeDataYtNumericData} totalCost={totalCost} totalPost={totalPost}/>
                </div>
              </div>
            </div>
          </div>

        </div>
        {
          (() => {
            if (postFilterData.length > 0) {
              return (
                <>
                  <div className="toggle-button-div sec-stockchart-w-tab">

                    <TabContext value={value}>

                      <TabList
                        onChange={handleChange}
                        className='nav sub-tabs'
                      >
                        <Tab className='nav-link'
                          label={`Total Views`}
                          value="1"
                          icon={<span className="material-symbols-rounded">visibility</span>}
                          style={{
                            textTransform: "none",
                            fontSize: "16px",
                            flexGrow: 1,
                          }}
                        />
                        <Tab className='nav-link'
                          label={`Total Likes`}
                          value="2"
                          icon={<span className="material-symbols-rounded">favorite</span>}
                          style={{
                            textTransform: "none",
                            fontSize: "16px",
                            flexGrow: 1,
                          }}
                        />
                        <Tab className='nav-link'
                          label={`Total Comments`}
                          value="3"
                          icon={<span className="material-symbols-rounded">chat_bubble</span>}
                          style={{
                            textTransform: "none",
                            fontSize: "16px",
                            flexGrow: 1,
                          }}
                        />
                      </TabList>

                      <TabPanel className="px-0" value="1">
                        <HighchartsReact highcharts={Highcharts} options={playsOptions} />

                      </TabPanel>
                      <TabPanel className="px-0" value="2">
                        <HighchartsReact highcharts={Highcharts} options={likesOptions} />
                      </TabPanel>
                      <TabPanel className="px-0" value="3">
                        <HighchartsReact highcharts={Highcharts} options={commentOptions} />
                      </TabPanel>
                    </TabContext>

                  </div>
                  <div className='d-flex flex-column'>
                    <strong className='me-auto mb-3'>Most Engaging Posts</strong>
                    {/* <Button onClick={handleOpenMenu}>Most Engaging Posts</Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                    >
                      <MenuItem onClick={() => handleMenuItemClick('er')}>ER</MenuItem>
                      <MenuItem onClick={() => handleMenuItemClick('reach')}>Reach </MenuItem>
                      <MenuItem onClick={() => handleMenuItemClick('impressions')}>Impressions</MenuItem>
                    </Menu> */}
                    {/* {selectedValue && (
                     
                    )} */}
                    <div>
                      {/* Selected Value: {selectedValue} */}
                      {(() => {
                        const sortedData = [...postFilterData].sort((a: any, b: any) => (b.selectedValue > a.selectedValue) ? 1 : (b.selectedValue < a.selectedValue) ? -1 : 0).slice(0, 5);
                        return (
                          <MostEngagingPosts sortedData={sortedData} selectCampaign={props?.selectCampaign} />
                          // <div>
                          //   <div className="Most-Engaging-Posts">
                          //     <Box sx={{ flexGrow: 1 }}>
                          //       <Grid container spacing={2}>
                          //         {sortedData.map((item: any) => (
                          //           <Grid item xs={12} md={6} lg={3}>
                          //             <div className="grid-div">
                          //               <img src={item.thumbnails} className="grid-image"></img>
                          //               <strong>{item.username}</strong>
                          //             </div>
                          //           </Grid>
                          //         ))}
                          //       </Grid>
                          //     </Box>
                          //   </div>
                          // </div>
                        );
                      })()}
                    </div>
                    {(
                      <div className="analytics-most-engaging">
                        <div className='d-flex flex-column'>
                          <strong className="mb-3">Most Engaging Influencers</strong>
                          {(() => {
                            const sortedData = influencerFilterData.sort((a: any, b: any) => (b.total_er > a.total_er) ? 1 : (b.total_er < a.total_er) ? -1 : 0).slice(0, 5);
                            return (
                              <MostEngagingInfluencer sortedData={sortedData} selectCampaign={props?.selectCampaign} />
                              // <div>
                              //   <div className="Most-Engaging-Posts">
                              //     <Box sx={{ flexGrow: 1 }}>
                              //       <Grid container spacing={2}>
                              //         {sortedData.map((item: any) => (
                              //           <Grid item xs={12} md={6} lg={2.5}>
                              //             <div className="grid-div">
                              //               <img src={item.profile_image_url} className="grid-image" />
                              //               <strong>{item.username}</strong>
                              //               <strong>ER {item.total_er}%</strong>
                              //             </div>
                              //           </Grid>
                              //         ))}
                              //       </Grid>
                              //     </Box>
                              //   </div>
                              // </div>

                            );
                          })()}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="toggle-button-div2">

                    <TabContext value={value2}>


                      <div className="toggle-button-div3">
                        <TabContext value={value4}>
                          <TabList
                            onChange={handleChange4}
                            aria-label="lab API tabs example"
                            className="filter-tab-list"
                          >
                            <Tab
                              label={`Posts`}
                              value="1"
                              style={{ textTransform: "none", fontSize: "16px" }}
                            />
                            <Tab
                              label={`Influencers`}
                              value="2"
                              style={{ textTransform: "none", fontSize: "16px" }}
                            />
                          </TabList>

                          <TabPanel className='px-0' value="1">
                            <div className="post-table">
                              <DetailedInsightsPost columnDisplayName={columnDisplayNamePostDetailedData}
                                orderByColumnName=""
                                inputDataSource={postFilterData}
                                sortingColumnType={PostDetailedData}
                                renderData={postFilterData}
                                setRenderData={(e: any) => setPostFilterData(e)}
                                selectCampaign={props?.selectCampaign}
                                postAnalyticsEr={postAnalyticsEr} />
                              {/* {
                                (() => {
                                  if (postFilterData.length > 0) {
                                    return (
                                      <>
                                        <TableComponent
                                          columnDisplayName={columnDisplayNamePostDetailedData}
                                          orderByColumnName=""
                                          inputDataSource={postFilterData}
                                          sortingColumnType={PostDetailedData}
                                          renderData={postFilterData}
                                          setRenderData={(e) => setPostFilterData(e)}

                                        >
                                          {postFilterData.map((data: any, index: number) => {
                                            return (
                                              <TableRow key={index} hover role="checkbox">
                                                <TableCell>
                                                  <div className="grid-div-post d-flex">

                                                    <img src={data?.thumbnails} className="grid-image me-2" />
                                                    <div className='d-flex flex-column'>
                                                      <span>{data.channel_title} </span>
                                                      <span>{data.title} </span>
                                                    </div>
                                                  </div>
                                                </TableCell>
                                                {
                                                  (() => {
                                                    const matchingItem = postAnalyticsEr.find((item: any) => item.url_id === data.url_id);

                                                    return (
                                                      <TableCell>
                                                        {matchingItem ? matchingItem.result.toFixed(2) : "NA"}
                                                      </TableCell>
                                                    );
                                                  })()
                                                }



                                                <TableCell>{data?.view_count}</TableCell>
                                                <TableCell>{data?.like_count}</TableCell>
                                                <TableCell>{data?.comment_count}</TableCell>
                                                <TableCell>{data?.created_at}</TableCell>
                                              </TableRow>
                                            );
                                          })}
                                        </TableComponent>
                                      </>
                                    )
                                  }

                                })()

                              } */}
                            </div>
                          </TabPanel>
                          <TabPanel className='px-0' value="2">
                            <div className="influencer-table">
                              <DetailedInsightsInfluencer columnDisplayName={columnDisplayNameInfluencerDetailedData}
                                orderByColumnName=""
                                inputDataSource={influencerFilterData}
                                sortingColumnType={InfluencerDetailedData}
                                renderData={influencerFilterData}
                                setRenderData={(e: any) => setInfluencerFilterData(e)}
                                selectCampaign={props?.selectCampaign} />
                              {/* {(() => {
                                if (influencerFilterData.length > 0) {
                                  return (
                                    <>
                                      <TableComponent
                                        columnDisplayName={columnDisplayNameInfluencerDetailedData}
                                        orderByColumnName=""
                                        inputDataSource={influencerFilterData}
                                        sortingColumnType={InfluencerDetailedData}
                                        renderData={influencerFilterData}
                                        setRenderData={(e) => setInfluencerFilterData(e)}
                                      >
                                        {influencerFilterData.map((data: any, index: number) => {
                                          return (
                                            <TableRow key={index} hover role="checkbox">
                                              <TableCell>
                                                <div className="grid-div-post">
                                                  <img src={data.profile_image_url} className="grid-image"></img>
                                                  <p>{data.custom_url} </p>
                                                </div>
                                              </TableCell>
                                              <TableCell>{data?.total_post}</TableCell>
                                              {
                                                (() => {
                                                  const matchingItem = influencerAnalyticsEr.find((item: any) => item.custom_url === data.custom_url)

                                                  return (
                                                    <TableCell>
                                                      {matchingItem ? matchingItem.result.toFixed(2) : "N A"}
                                                    </TableCell>
                                                  );
                                                })()
                                              }
                                              <TableCell>{data?.total_views}</TableCell>
                                              <TableCell>{data?.total_likes}</TableCell>
                                              <TableCell>{data?.total_comments}</TableCell>
                                            </TableRow>
                                          );
                                        })}
                                      </TableComponent>
                                    </>
                                  )
                                }

                              })()

                              } */}

                            </div>
                          </TabPanel>
                        </TabContext>

                      </div>
                    </TabContext>

                  </div>
                </>
              )
            }

          })()

        }
{openUpgradeBrandModal && (
        <UpgradeBrandPopUp
          openUpgradeBrandModal={openUpgradeBrandModal}
          onClose={() => handleClosePopUps()}
          popupMinWidth='630px'
        >
        </UpgradeBrandPopUp>
      )}

      </div>
      {openModalROI && <ROI open={openModalROI} likes={analyticsCumulativeDataYtNumericData.total_likes}  views = {analyticsCumulativeDataYtNumericData.total_views} er ={0} comments={analyticsCumulativeDataYtNumericData.total_comments} plays ={0} user_id ={props.UserReducer.user_id} account_id ={props.UserReducer.account_id} brand_id ={props.selectCampaign.brand_id} influencer_id ={props.UserReducer.influencer_id} campaign_id ={props.selectCampaign.campaign_id} platform = {"YouTube"} onClose={()=>setOpenModalROI(false)}/>}

    </>
  );
};


const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    UserID: state.UserID.userID,
    selectCampaign: state.InfSelectCamp.campaign,
    getSubscriptionDetail: state.Subscription.subscription_detail,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailedAnalyticsYoutube);

export { connectToRedux as DetailedAnalyticsYoutube };
