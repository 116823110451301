import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

interface Column {
  id:
  | "profilelink"
  | "influencername"
  | "phone"
  | "emailid"
  | "gender"
  | "location"
  | "categoryofinfluencer"
  | "followers"
  | "campaign"
  | "brand"
  | "campaignhashtags"
  | "costofreel"
  | "avgviewofreel"
  | "costofstory"
  | "swipeuplink"
  | "costofimagepost"
  | "brandworked";

  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "profilelink", label: "Profile Link", minWidth: 170 },
  { id: "influencername", label: "Influencer Name", minWidth: 150 },
  { id: "phone", label: "Phone", minWidth: 100 },
  { id: "emailid", label: "Email", minWidth: 100 },
  { id: "gender", label: "Gender", minWidth: 100 },
  { id: "location", label: "Location", minWidth: 100 },
  {
    id: "categoryofinfluencer",
    label: "Influencer Category",
    minWidth: 170,
  },
  { id: "followers", label: "Followers ", minWidth: 100 },
  { id: "campaign", label: "Campaign ", minWidth: 100 },
  { id: "brand", label: "Brand", minWidth: 100 },
  { id: "campaignhashtags", label: "Campaign Hashtags", minWidth: 170 },
  {
    id: "costofreel",
    label: "Cost of Reels",
    minWidth: 150,
  },
  {
    id: "avgviewofreel",
    label: "Avg. View of Reel",
    minWidth: 150,
  },
  { id: "costofstory", label: "Cost of story", minWidth: 150 },

  { id: "swipeuplink", label: "Swipe up Link ", minWidth: 150 },
  { id: "costofimagepost", label: "Cost of Image Post ", minWidth: 170 },
  {
    id: "brandworked",
    label: "Brands worked with (Past 3 Months)",
    minWidth: 300,
  },
];

interface Data {
  profilelink: string;
  influencername: string;
  phone: number;
  emailid: string;
  gender: number;
  location: string;
  categoryofinfluencer: string;
  followers: string;
  campaign: string;
  brand: string;
  campaignhashtags: string;
  costofreel: string;
  avgviewofreel: string;
  costofstory: string;
  swipeuplink: string;
  costofimagepost: string;
  brandworked: string;
}

export const InfluencerInstagram: React.FC = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [influencerData, setInfluencerData] = useState<Data[]>([]);

  useEffect(() => {
    loadDataFromLS();
  }, [influencerData]);

  const loadDataFromLS = () => {
    const storedData = localStorage.getItem("formValues");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setInfluencerData(parsedData);
    }
  };

  const addDataToLS = (data: Data) => {
    const updatedData = [...influencerData, data];
    localStorage.setItem("formValues", JSON.stringify(updatedData));
    setInfluencerData(updatedData);
  };

  return (
    <section>
      <div className="talent_verification_tab">

        <TableContainer
          className="common-table"
        // sx={{ maxHeight: 440 }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead className='common-table-header'>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className='common-table-header'>
              {influencerData.map((row, index) => (
                <TableRow key={index}>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align}>
                      {row[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
      </div>
    </section>
  );
};
