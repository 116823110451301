import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Loading } from "../../../redux/action";
import { LoadingButton } from "@mui/lab";
import {Autocomplete,Button,FormControl,FormHelperText,InputAdornment,InputLabel,MenuItem,OutlinedInput,Select,TextField,Theme,useTheme} from "@mui/material";
import Grid from "@mui/material/Grid";
import {Email,Instagram,Phone,YouTube} from "@mui/icons-material";
import {PublicService,LocalService,SignUpService,UserService} from "../../../core/services";
import { FormValidation } from "../../../core/utility";
import InfluencerProfileCard from "../../signup/influencer/influencer-profile-card";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import "./add-account.scss";
import {MenuPropsAutoCompleteNoCheckBox, MenuPropsSelect } from "../../../core/utility/style";
import FileUploadPopup from "../../../components/popup/file-upload-popup";
import {Accordion,AccordionSummary, AccordionDetails} from '@mui/material';
import { DefaultUserProfile } from "../../../config/config";

const formValidation: FormValidation = new FormValidation();
const localService: LocalService = new LocalService();
const signupService: SignUpService = new SignUpService();

type AddInfluencerType = {
  logo_url: string;
  account_id: number;
  user_id: number;
  influencer_name: string;
  email: string;
  mobile: string;
  gender: string;
  genre: string[];
  language: string[];
  location_id: any;
  website: string;
  dob:string;
  instagram: string;
  youtube: string;
  ig_influencer_profile_data: any;
  yt_influencer_profile_data: any;
  tp_contact_name:any;
  tp_contact_email:any;
  tp_contact_phone:any;

};

interface Props {
  userDetails: any;
  loading: (loading: boolean) => void;
  updateShowInfluencer: (value: boolean) => void;
  account_id: number;
  user_id: number;
  data: any;
  popUpOpen?:any;
}

const AddInfluencer: React.FC<Props> = ({ userDetails,popUpOpen,data, ...props }: Props) => {
  const theme = useTheme();
  const [igloading, setIgLoading] = useState(false);
  const [ytloading, setYtLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [genricList, setGenricList] = useState<string[]>([]);
  const [location, setLocation] = useState([]);
  const [languageList, setLanguageList] = useState<string[]>([]);
  const [showInstaProfileCard, setShowInstaProfileCard] = useState(false);
  const [showYtProfileCard, setShowYtProfileCard] = useState(false);
  const [showUploadPopup, setShowUploadPopup] = useState<any>(false);
  const [expandedAccordian1, setExpandedAccordian1] = useState(true);
  const [expandedAccordian2, setExpandedAccordian2] = useState(false);

  const [errorDate, setErrorDate] = useState({
    dd: { status: true, message: "" },
    mm: { status: true, message: "" },
    yyyy: { status: true, message: "" },
  });
  const [dd, setDD] = useState("");
  const [mm, setMM] = useState("");
  const [yyyy, setYYYY] = useState("");

  const ddRef = useRef<HTMLInputElement>(null);
  const mmRef = useRef<HTMLInputElement>(null);
  const yyyyRef = useRef<HTMLInputElement>(null);
  const path = `accounts/${props.account_id}/talents`;

  const publicService = new PublicService();
  const [formData, setFormData] = useState<AddInfluencerType>({
    account_id: props.account_id,
    user_id: props.user_id,
    influencer_name: data?.name|| "" ,
    email: data?.primary_email || "",
    mobile: data?.primary_phone || "",
    gender: data?.gender || "",
    genre: data?.catg_1 ? findArray([data?.catg_1, data?.catg_2, data?.catg_3]) : [],
    language: data?.lang_1 ? findArray([data?.lang_1, data?.lang_2, data?.lang_3,data?.lang_4]) : [],
    location_id: findArray([data.location_id]),
    website:  data?.website ||"",
    dob: data?.dob||"",
    instagram: data.username||"",
    youtube: data.channel_id ||"",
    ig_influencer_profile_data: {},
    yt_influencer_profile_data: {},
    logo_url:data.photo||"",
    tp_contact_name: data?.contact_person_name|| "",
    tp_contact_email: data?.contact_person_email||"",
    tp_contact_phone:data.contact_person_phone || "",
    
  });

  const [formError, setFormError] = useState({
    influencer_name: { status: true, message: "" },
    gender: { status: true, message: "" },
    genre: { status: true, message: "" },
    language: { status: true, message: "" },
    dob: { status: true, message: "" },
    location_id: { status: true, message: "" },
    verification: { status: true, message: "" },
    mobile: { status: true, message: "" },
    email: { status: true, message: "" },
    tp_contact_name :{ status: true, message: "" },
    tp_contact_email :{ status: true, message: "" },
    tp_contact_phone :{ status: true, message: "" },
  });

  function findArray(obj: any) {
    const result = [];
    for (const key in obj) {
      if (obj[key] !== 0) {
        result.push(obj[key]);
      }
    }
    return result;
  }


  useEffect(() => {
    async function getLocations() {
      try {
        props.loading(true);
        const res = await publicService.getLocation();
        if (res.data.status) {
          if (res?.data?.rows[0].state === 'India' && res?.data?.rows[0].city === 'India' ){
            res?.data?.rows.shift();
          }
          setLocation(res?.data?.rows);
        } else {
          setLocation([]);
        }
      } catch (error) {
        setLocation([]);
        console.log(`get Geo (Location) error :: ${JSON.stringify(error)}`);
      } finally {
      }
    }
    getLocations();

    PublicService.getGenre()
      .then((res) => {
        setGenricList(res?.data?.rows);
        props.loading(false);
      })
      .catch((error) => {
        props.loading(false);
        throw new Error(`getGenre error :: ${JSON.stringify(error)}`);
      });
    async function getLanguage() {
      try {
        props.loading(true);
        const res = await publicService.getLanguage();
        if (res.data.status) {
          setLanguageList(res?.data?.rows);
          props.loading(false);
        } else {
          setLanguageList([]);
        }
      } catch (error) {
        props.loading(false);
        throw new Error(`getLanguage error :: ${JSON.stringify(error)}`);
      }
    }
    getLanguage();

  }, []);


 useEffect(()=>{
  setFormData((prevData) => ({
    ...prevData,
    account_id: props.account_id,
    user_id: props.user_id,
    influencer_name: data?.name || "",
    email: data?.primary_email || "",
    mobile: data?.primary_phone || "",
    gender: data?.gender || "",
    genre: data?.catg_1 ? findArray([data?.catg_1, data?.catg_2, data?.catg_3]) : [],
    language: data?.lang_1 ? findArray([data?.lang_1, data?.lang_2, data?.lang_3, data?.lang_4]) : [],
    location_id: findArray([data.location_id]),
    website:  data?.website ||"",
    dob: data?.dob||"",
    instagram: data.username||"",
    youtube: data.channel_id ||"",
    ig_influencer_profile_data: {},
    yt_influencer_profile_data: {},
    logo_url:data.photo||"",
    tp_contact_name: data?.contact_person_name|| "",
    tp_contact_email: data?.contact_person_email||"",
    tp_contact_phone:data.contact_person_phone || "",
  }));
 },[data, props.account_id, props.user_id])

  useEffect(() => {
    // if(!data.dob){
      const padToTwoDigits = (num:any) => num.toString().padStart(2, '0');
      const formattedDd = padToTwoDigits(dd);
      const formattedMm = padToTwoDigits(mm);
      const formattedYyyy = yyyy; // Assuming yyyy is already in 4-digit format
      setFormData((prevData) => {
        const updatedData = {
            ...prevData,
            dob: `${formattedYyyy}-${formattedMm}-${formattedDd}`,
        };
        return updatedData;
      });
  
  
    // }

  }, [dd,mm,yyyy]);

  useEffect(() => {
    if (data.dob) {
      const [year, month, day] = data.dob.split("-");
      setDD(day);
      setMM(month);
      setYYYY(year);
    } else {
      setDD("");
      setMM("");
      setYYYY("");
    }
  }, [data.dob]);
  const handleGenre = (event: any) => {
    const { target: { value }, } = event;
    if (formData.genre.length != 3) {
      setFormData((previData) => ({ ...previData, genre: value }));
    } else {
      if (value.length === 2) {
        setFormData((previData) => ({ ...previData, genre: value }));
      }
    }
  };

  const handleLanguage = (event: any) => {
    const {
      target: { value },
    } = event;
    if (formData.language.length != 4) {
      setFormData((previData) => ({ ...previData, language: value }));
    } else {
      if (value.length === 3) {
        setFormData((previData) => ({ ...previData, language: value }));
      }
    }
  };

  // Extra component
  function getStyles(name: string, personName: string[], theme: Theme) {
    try {
      return {
        fontWeight:
          personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
      };
    } catch (error) { }
  }

  //verify instagram handle
  const handleInstagramProfile = async () => {
    try {
      setIgLoading(true);
      UserService.getInstaProfileDetails(formData.instagram, "sm_api")
        .then(async (res: any) => {
          if (res.status === 1) {
            // logging_page_id
            const checkInDb = await publicService.checkInfluencerHandleInDB({
              platform: "Instagram",
              handle_name: formData.instagram,
            });
            if (checkInDb.data.status.status) {
              setShowInstaProfileCard(true);
              // setInstaCardData(res.data);
              //Update form Data
              setFormData((prevData) => {
                prevData.ig_influencer_profile_data = res.data;
                return { ...prevData };
              });
            } else {
              setShowInstaProfileCard(false);
              // setInstaCardData({});
              localService.toastify("handle name is already registered");
            }
          } else {
            setShowInstaProfileCard(false);
            localService.toastify(`Incorrect handlename`, "info", 1000);
          }
          setIgLoading(false);
        })
        .catch((e) => {
          setIgLoading(false);
          // setInstaCardData([]);
        });
    } catch (err) {
      console.log(err);
    }
  };

  //Verify Youtube handle
  const handleYoutubeProfile = async () => {
    try {
      setYtLoading(true);
      const checkInDb = await publicService.checkInfluencerHandleInDB({
        platform: "Youtube",
        handle_name: formData.youtube,
      });
      if (checkInDb.data.status.status) {
        UserService.getYtProfileDetails(formData.youtube, "sm_api")
          .then((res) => {
            if (res.status === 1) {
              setShowYtProfileCard(true);
              //Update form Data
              setFormData((prevData) => {
                prevData.yt_influencer_profile_data = res.data;
                return { ...prevData };
              });
            } else {
              setShowYtProfileCard(false);
            }
            setYtLoading(false);
          })
          .catch((e) => {
            setYtLoading(false);
          });
      } else {
        setYtLoading(false);
        localService.toastify("handle name is already registered");
      }
    } catch (err) {
      console.log(err);
    }
  };

  // check if email already exist

  const checkIfEmailExist = async (email: string): Promise<boolean> => {
    try {
      const response = await publicService.checkEmailIsRegister({
        email: email,
        user_type: "influencer",
      });
  
      // Assuming the response indicates whether the email exists or not
      return response; // Return true if response exists (email is registered), false otherwise
  
    } catch (error) {
      console.error("Error checking email existence:", error);
      return false; // Return false in case of an error
    }
  }
  
  const checkIfPhoneExist = async (phone: string): Promise<boolean> => {
    try {
      const response = await publicService.checkPhoneIsRegister({
        phone: phone
      });
  
      // Assuming the response indicates whether the email exists or not
      return response; // Return true if response exists (email is registered), false otherwise
  
    } catch (error) {
      console.error("Error checking email existence:", error);
      return false; // Return false in case of an error
    }
  }

  //Save Influencer
  async function addInfluencer() {
    try {
      //validation
      setExpandedAccordian1(true);
      setExpandedAccordian2(true);
      const isValidInfluencerName = formValidation.GetNameValidate(formData?.influencer_name);
      const isValidGender = formValidation.GetNameValidate(formData.gender);
      const isValidGenre = formData.genre.length > 0 ? { status: true, message: "" }: { status: false, message: "Genre is required" };
      const isValidLanguage = formData.language.length > 0 ? { status: true, message: "" }: { status: false, message: "Language is required" };
      const isValidDd = formValidation.IsValidDay(dd);
      const isValidMm = formValidation.IsValidMonth(mm);
      const isValidYyyy = formValidation.IsvalidYear(yyyy);
      const isValidEmail = async() => {

       if(formData.email.length > 0){
        let vaildEmail =formValidation.GetEmailControlIsValid(formData.email)
        if(vaildEmail.status == true){
          const response:any  = await checkIfEmailExist(formData.email);
          if (response?.data?.status.message.length === 0) 
            {       
            return { status: true, message: "" }
           }
           else
           {
            return { status: false, message: "Email acccount already exist" }
           }
          }
        else
          {
            return vaildEmail;
          }
       }
       else{
        return { status: true, message: "" }
       }
      };
      const isValidPhone = async() => {
        if(formData.mobile.length > 0){
         let vaildEmail =formValidation.GetMobileControlIsValid(formData.mobile)
         if(vaildEmail.status == true){
           const response:any  = await checkIfPhoneExist(formData.mobile);
           if (response?.data?.status.message.length === 0) 
            {       
             return { status: true, message: "" }
            }
            else
            {
             return { status: false, message: response?.data?.status.message }
            }
           }
         else
           {
             return vaildEmail;
           }
        }
        else{
         return { status: true, message: "" }
        }
       };
    const emailValidationResult = await isValidEmail();
    const phoneValidationResult = await isValidPhone();
  
      const isValidLocation = formData.location_id.length == 0? { status: false, message: "Location is required" } :{ status: true, message: "" };
      const isValidInstagram = Object.keys(formData.ig_influencer_profile_data).length > 0 ? { status: true, message: "" }: { status: false, message: "Instagram is required" };
      const isValidYoutube = Object.keys(formData.yt_influencer_profile_data).length > 0 ? { status: true, message: "" }: { status: false, message: "Youtube is required" };
      const isValidTalentPartnerContactName = formValidation.GetNameValidate(formData.tp_contact_name);
      const isValidTalentPartnerContactEmail = formValidation.GetEmailControlIsValid(formData.tp_contact_email);
      const isValidTalentPartnerContactPhone = formValidation.GetMobileControlIsValid(formData.tp_contact_phone);
      let verificationStatus = { status: false, message: "Social Media Handle (atleast one)"};
      if (isValidInstagram.status || isValidYoutube.status) 
      {
        verificationStatus = { status: true, message: "" };
      }
      setFormError((prevData) => {
        prevData.influencer_name = isValidInfluencerName;
        prevData.gender = isValidGender;
        prevData.genre = isValidGenre;
        prevData.location_id = isValidLocation;
        prevData.language = isValidLanguage;
        prevData.dob = isValidDd && isValidMm && isValidYyyy;
        prevData.email = emailValidationResult; 
        prevData.mobile = phoneValidationResult;
        prevData.verification = verificationStatus;
        prevData.tp_contact_email = isValidTalentPartnerContactEmail;
        prevData.tp_contact_name = isValidTalentPartnerContactName;
        prevData.tp_contact_phone = isValidTalentPartnerContactPhone;
        return { ...prevData };
      });
      //Validate form Fields
      if (
        isValidInfluencerName.status &&
        verificationStatus.status &&
        isValidGender.status &&
        isValidLocation.status &&
        isValidLanguage.status &&
        emailValidationResult.status &&
        phoneValidationResult.status &&
        isValidGenre.status && 
        isValidTalentPartnerContactName.status &&
        isValidTalentPartnerContactEmail.status &&
        isValidTalentPartnerContactPhone.status &&
        isValidDd.status &&
        isValidMm.status &&
        isValidYyyy
      ) {
        //Call api to save the Data
        setLoading(true);
        const response = await signupService.addInfluencer(formData);
        if (response.data.status.status) {
          props.updateShowInfluencer(false);
          setFormData({
            ...formData,
            influencer_name: "",
            email:  "",
            mobile: "",
            gender: "",
            genre: [],
            language: [],
            location_id: [],
            website:  "",
            instagram: "",
            youtube: "",
            dob: "",
            ig_influencer_profile_data: {},
            yt_influencer_profile_data: {},
            tp_contact_email: "",
            tp_contact_name: "",
            tp_contact_phone: "",
            logo_url: DefaultUserProfile.influencer_unknown_default
          });
        }
        setDD("");
        setMM("");
        setYYYY("")
        setShowInstaProfileCard(false)
        setShowYtProfileCard(false)
        popUpOpen(false)

      }
    } catch (error) {
      console.log(
        `addInfluencer function failed: ${JSON.stringify(error)}`
      );
    } finally {
      setLoading(false);
    }
  }

  async function updateInfluencer(){
    try {
      //validation
      setExpandedAccordian1(true);
      setExpandedAccordian2(true);
      const isValidInfluencerName = formValidation.GetNameValidate(formData.influencer_name);
      const isValidGender = formValidation.GetNameValidate(formData.gender);
      const isValidGenre = formData.genre.length > 0 ? { status: true, message: "" }: { status: false, message: "Genre is required" };
      const isValidLanguage = formData.language.length > 0 ? { status: true, message: "" }: { status: false, message: "Language is required" };
      const isValidDd = formValidation.IsValidDay(dd);
      const isValidMm = formValidation.IsValidMonth(mm);
      const isValidYyyy = formValidation.IsvalidYear(yyyy);
      const isValidEmail = async() => {

       if(formData.email.length > 0){
        let vaildEmail =formValidation.GetEmailControlIsValid(formData.email)
        if(vaildEmail.status == true){
          const response:any  = await checkIfEmailExist(formData.email);
          if (response?.data?.status.message.length === 0) 
            {       
            return { status: true, message: "" }
           }
           else
           {
            return { status: false, message: "Email acccount already exist" }
           }
          }
        else
          {
            return vaildEmail;
          }
       }
       else{
        return { status: true, message: "" }
       }
      };
      const isValidPhone = async() => {
        if(formData.mobile.length > 0){
         let vaildEmail =formValidation.GetMobileControlIsValid(formData.mobile)
         if(vaildEmail.status == true){
           const response:any  = await checkIfPhoneExist(formData.mobile);
           if (response?.data?.status.message.length === 0) 
            {       
             return { status: true, message: "" }
            }
            else
            {
             return { status: false, message: response?.data?.status.message }
            }
           }
         else
           {
             return vaildEmail;
           }
        }
        else{
         return { status: true, message: "" }
        }
       };
    const emailValidationResult = await isValidEmail();
    const phoneValidationResult = await isValidPhone();
  
      const isValidLocation = formData.location_id.length == 0 ? { status: false, message: "Location is required" } :{ status: true, message: "" };
      const isValidTalentPartnerContactName = formValidation.GetNameValidate(formData.tp_contact_name);
      const isValidTalentPartnerContactEmail = formValidation.GetEmailControlIsValid(formData.tp_contact_email);
      const isValidTalentPartnerContactPhone = formValidation.GetMobileControlIsValid(formData.tp_contact_phone);
     
      setFormError((prevData) => {
        prevData.influencer_name = isValidInfluencerName;
        prevData.gender = isValidGender;
        prevData.genre = isValidGenre;
        prevData.location_id = isValidLocation;
        prevData.language = isValidLanguage;
        prevData.dob = isValidDd && isValidMm && isValidYyyy;
        prevData.email = emailValidationResult; 
        prevData.mobile = phoneValidationResult;
        prevData.tp_contact_email = isValidTalentPartnerContactEmail;
        prevData.tp_contact_name = isValidTalentPartnerContactName;
        prevData.tp_contact_phone = isValidTalentPartnerContactPhone;
        return { ...prevData };
      });
      //Validate form Fields
      if (
        isValidInfluencerName.status &&
        isValidGender.status &&
        isValidLocation.status &&
        isValidLanguage.status &&
        emailValidationResult.status &&
        phoneValidationResult.status &&
        isValidGenre.status && 
        isValidTalentPartnerContactName.status &&
        isValidTalentPartnerContactEmail.status &&
        isValidTalentPartnerContactPhone.status &&
        isValidDd.status &&
        isValidMm.status &&
        isValidYyyy
      ) {
        //Call api to save the Data
        setLoading(true);
        const response = await signupService.updateInfluencer({formData,influencer_id:data.influencer_id});
        if (response.data.status.status) {
          props.updateShowInfluencer(false);
          setFormData({
            ...formData,
            influencer_name: "",
            email:  "",
            mobile: "",
            gender: "",
            genre: [],
            language: [],
            location_id: [],
            website:  "",
            instagram: "",
            youtube: "",
            dob: "",
            ig_influencer_profile_data: {},
            yt_influencer_profile_data: {},
            tp_contact_email: "",
            tp_contact_name: "",
            tp_contact_phone: "",
            logo_url: DefaultUserProfile.influencer_unknown_default
          });
        }
        setDD("");
        setMM("");
        setYYYY("")
        setShowInstaProfileCard(false)
        setShowYtProfileCard(false)
        popUpOpen(false)

      }
    } catch (error) {
      console.log(
        `addInfluencer function failed: ${JSON.stringify(error)}`
      );
    } finally {
      setLoading(false);
    }

  }

  // Toggle the expanded state on change
  const handleChangeAccordian1 = (isExpanded:any) => {
    setExpandedAccordian1(!expandedAccordian1);
  };
  const handleChangeAccordian2 = (isExpanded:any) => {
    setExpandedAccordian2(!expandedAccordian2);
  };
  return (
    <div className="addbrand-container add-influencer">
      <Accordion className='accordion-container w-100 p-1 ' expanded={expandedAccordian1} onChange={handleChangeAccordian1}>
        <AccordionSummary>
        <div className="proposal-header justify-content-center">
          <p>Influencer Details</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} >
            <Grid item xs={12} >
              <TextField
                className="w-100 input-style "
                size="small"
                error={!formError.influencer_name.status}
                // helperText={formError.influencer_name.message}
                id="id_influencer_name"
                name="influencer_name"
                placeholder= {formData?.influencer_name ? " ": "Influencer Name"}
                // label={formData?.influencer_name ? " ": "Influencer Name"}
                variant="outlined"
                value={formData?.influencer_name}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    influencer_name: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} >
              <TextField
                className="w-100 input-style"
                size="small"
                error={!formError.email.status}
                helperText={formError.email.message}
                id="id_email"
                name="email_id"
                placeholder="xyz@gmail.com"
                label="Email (Optional)"
                variant="outlined"
                value={formData.email}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    email: e.target.value,
                  }))
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  ),
                }}
              />
              
            </Grid>
            <Grid item xs={12} >
              <TextField
                className="w-100 input-style"
                size="small"
                id="phone"
                name="phone_number"
                error={!formError.mobile.status}
                helperText={formError.mobile.message}
                placeholder="9999999999"
                label="Mobile (Optional)"
                variant="outlined"
                value={formData.mobile}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    mobile: e.target.value,
                  }))
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <div className="d-flex" style={{paddingLeft: "16px",paddingTop: "16px"}}>
              <Grid item xs={4}style={{paddingRight: "4px"}} >
                <FormControl fullWidth size="small" className="input-style">
                  <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                  <Select
                    error={!formError.gender.status}
                    labelId="demo-simple-select-label"
                    id="gender_id"
                    label="Gender"
                    name="gender"
                    value={formData.gender}
                    onChange={(e) =>
                      { 
                        const value = e.target.value;
                        setFormData((prevData) => ({ ...prevData, gender: value }));
                      }
                    }
                    MenuProps={MenuPropsSelect}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                  <FormHelperText error={!formError.gender.status}>
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={8} >
                <div className="d-flex">
                  <TextField
                    className="w-100 _dobinput input-style input-common"
                    name="dd"
                    error={!errorDate.dd.status}
                    // disabled = {data.dob ? true : false}
                    inputRef={ddRef}
                    value={dd}
                    type="number"
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;

                      if (/^\d{0,2}$/.test(value)) {
                        setDD(value);

                        setErrorDate((previous) => ({
                          ...previous,
                          dd: formValidation.IsValidDay(value),
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      let formattedValue = e.target.value;
                      if (formattedValue.length === 1 && formattedValue !== '0') {
                        formattedValue = `0${formattedValue}`;
                        setDD(formattedValue);

                        setErrorDate((previous) => ({
                          ...previous,
                          dd: formValidation.IsValidDay(formattedValue),
                        }));
                      }
                    }}
                    inputMode="numeric"
                    size="small"
                    id="dd_id"
                    label="DD"
                    variant="outlined"
                  />
                  <span className="_influencer_dob_slash ">/</span>
                  <TextField
                    className="w-100 _dobinput input-style input-common"
                    name="mm"
                    error={!errorDate.mm.status}
                    inputRef={mmRef}
                    // disabled = {data.dob ? true : false}
                    // helperText={errorDate.mm.message}
                    value={mm}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      if (/^\d{0,2}$/.test(value)) {
                        setMM(value);

                        setErrorDate((previous) => ({
                          ...previous,
                          mm: formValidation.IsValidMonth(value),
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      let formattedValue = e.target.value;
                      if (formattedValue.length === 1 && formattedValue !== '0') {
                        formattedValue = `0${formattedValue}`;
                        setMM(formattedValue);

                        setErrorDate((previous) => ({
                          ...previous,
                          mm: formValidation.IsValidMonth(formattedValue),
                        }));
                      }
                    }}
                    type="number"
                    size="small"
                    id="mm_id"
                    label="MM"
                    variant="outlined"
                  />
                  <span className="_influencer_dob_slash">/</span>
                  <TextField
                    className="w-100 _dobinput input-style input-common"
                    name="yyyy"
                    error={!errorDate.yyyy.status}
                    // disabled = {data.dob ? true : false}
                    inputRef={yyyyRef}
                    onBlur={() => {
                      setErrorDate((e) => ({
                        ...e,
                        yyyy: formValidation.IsvalidYear(yyyy),
                      }));
                    }}
                    value={yyyy}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      if (value.length <= 4) {
                        setYYYY(value);
                      } else {
                        setYYYY(yyyy);
                      }
                    }}
                    type="number"
                    size="small"
                    id="yyyy_id"
                    label="YYYY"
                    variant="outlined"
                  />
                  </div>
                  <div className="d-flex flex-column">
                    {errorDate.dd.message && <span className="error fs-12">{errorDate.dd.message}</span>}
                    {errorDate.mm.message && <span className="error fs-12">{errorDate.mm.message}</span>}
                    {errorDate.yyyy.message && <span className="error fs-12">{errorDate.yyyy.message}</span>}
                  </div>
              </Grid>
            </div>
            <Grid item xs={12} >
              <FormControl className="w-100 input-style" size="small">
                <InputLabel id="demo-multiple-name-label">
                  Genre (Max. 3)
                </InputLabel>
                <Select
                  error={!formError.genre.status}
                  className="_genre_option"
                  labelId="demo-multiple-name-label"
                  id="Genre_id"
                  multiple
                  input={<OutlinedInput label="Genre (Max. 3)" />}
                  onChange={handleGenre}
                  value={formData.genre?.map((res: any) => res)}
                  MenuProps={MenuPropsSelect}
                  name="genre"
                >
                  {genricList ? (
                    genricList?.map((res: any) => (
                      <MenuItem
                        key={res.category_id}
                        value={res.category_id}
                        style={getStyles(res.category, formData.genre, theme)}
                      >
                        {res.category}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem
                      key={1}
                      value={1}
                      style={getStyles("No Data", formData.genre, theme)}
                    >
                      {"No Data"}
                    </MenuItem>
                  )}
                </Select>
                <FormHelperText error={!formError.genre.status}>
                  {/* {formError.genre.message} */}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} >
              <FormControl className="w-100 input-style" size="small">
                <InputLabel id="demo-multiple-name-label">
                  Language (Max. 4)
                </InputLabel>
                <Select
                  error={!formError.language.status}
                  className="_genre_option"
                  labelId="demo-multiple-name-label"
                  id="Language_id"
                  multiple
                  input={<OutlinedInput label="Language(Max. 4)" />}
                  onChange={handleLanguage}
                  value={formData.language?.map((res: any) => res)}
                  MenuProps={MenuPropsSelect}
                  name="language"
                >
                  {languageList ? (
                    languageList?.map((res: any) => (
                      <MenuItem
                        key={res.id}
                        value={res.id}
                        style={getStyles(res.id, formData.language, theme)}
                      >
                        {`${res.language_name} (${res.language_original_name})`}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem
                      key={1}
                      value={1}
                      style={getStyles("No Data", formData.language, theme)}
                    >
                      {"No Data"}
                    </MenuItem>
                  )}
                </Select>
                <FormHelperText error={!formError.language.status}>
                  {/* {formError.language.message} */}
                </FormHelperText>
              </FormControl>
            </Grid>
            {/* hhh */}
            <Grid item xs={12}>
              <Autocomplete
                className="inputFieldSp"
                size="small"
                disablePortal
                id="combo-box-demo"
                ListboxProps={MenuPropsAutoCompleteNoCheckBox?.PaperProps}
                value={

                  formData.location_id
                    ? (() => {
                        const selectedLocation:any = location.find(
                          (loc: any) => loc.location_id == formData.location_id
                        );
                        return selectedLocation
                          ? `${selectedLocation.city}, ${selectedLocation.state}, ${selectedLocation.country}`
                          : null;
                      })()
                      
                    : null
                }
                onChange={(e, v) => {
                  setFormData((prevData) => {
                    const selectedLocation:any = location.find(
                      (data: any) => `${data.city}, ${data.state}, ${data.country}` === v
                    );

                    return {
                      ...prevData,
                      location_id: selectedLocation ? selectedLocation.location_id : null,
                    };
                  });
                }}
                options={
                  location.length
                    ? location.map(
                        (res: any) => `${res.city}, ${res.state}, ${res.country}`
                      )
                    : ["No Data"]
                }
                renderInput={(params) => (
                  <TextField
                    error={!formError.location_id.status}
                    name="location_id"
                    {...params}
                    label="Location"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} >
              <div className="__wa_column verify-btns">
                <TextField
                  className="w-100 input-style"
                  name="instagram"
                  error={!formError.verification.status}
                  helperText={formError.verification.message}
                  value={formData.instagram}
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      instagram: e.target.value,
                    }))
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Instagram />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  id="instagram_id"
                  placeholder="eg. virat.kohli"
                  variant="outlined"
                />
                <LoadingButton
                  className="__button __button-influender"
                  loading={igloading}
                  loadingPosition="start"
                  variant="contained"
                  color="success"
                  disabled={showInstaProfileCard}
                  onClick={handleInstagramProfile}
                >
                  Verify
                </LoadingButton>

                {showInstaProfileCard && (
                  <div className="profile-card mt-3">
                    <InfluencerProfileCard
                      platform="instagram"
                      name={formData.ig_influencer_profile_data.name}
                      username={
                        formData.ig_influencer_profile_data.insta_username
                      }
                      profile_image={
                        formData.ig_influencer_profile_data.profile_picture_url
                      }
                      description={formData.ig_influencer_profile_data.biography}
                      subscriber={
                        formData.ig_influencer_profile_data.followers_count
                      }
                      follows={formData.ig_influencer_profile_data.follows}
                      total_media={
                        formData.ig_influencer_profile_data.total_media
                      }
                    />
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} >
              <div className="__wa_column verify-btns">
                <TextField
                  className="w-100 input-style"
                  name="youtube"
                  size="small"
                  id="youtube_id"
                  error={!formError.verification.status}
                  helperText={formError.verification.message}
                  value={formData.youtube}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <YouTube style={{ color: "red" }} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="eg. UCjOooOPRBat1IqsP-BBPTzQ"
                  variant="outlined"
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      youtube: e.target.value,
                    }))
                  }
                />

                <LoadingButton
                  className="__button __button-influender"
                  color="success"
                  loading={ytloading}
                  loadingPosition="start"
                  variant="contained"
                  disabled={showYtProfileCard}
                  onClick={handleYoutubeProfile}
                >
                  Verify
                </LoadingButton>
                {showYtProfileCard && (
                  <div className="profile-card mt-3">
                    <InfluencerProfileCard
                      platform="youtube"
                      name={formData.yt_influencer_profile_data.channel_name}
                      username={formData.yt_influencer_profile_data.custom_url}
                      profile_image={
                        formData.yt_influencer_profile_data.thumbnail
                      }
                      description={
                        formData.yt_influencer_profile_data.description
                      }
                      subscriber={
                        formData.yt_influencer_profile_data.subscriber_count
                      }
                      follows={0}
                      total_media={
                        formData.yt_influencer_profile_data.video_count
                      }
                    />
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
                {' '}
              <div className='cover_image_section '>
                {showUploadPopup && (
                  <FileUploadPopup
                    openModal={showUploadPopup}
                    onClose={(e) => setShowUploadPopup(false)}
                    onSend={(e: any) => {
                    setFormData((prevData) => ({
                        ...prevData,
                        logo_url: e.url,
                        }));
                        }}
                    path={path}
                    acceptedFileTypes=".jpg, .jpeg, .png, .pdf"
                  />
                )}
                <img
                  aria-label='cropped image'
                  className='campaign_cover_image'
                  alt='cover'
                  src={
                    formData.logo_url === ''
                    ? DefaultUserProfile.influencer_unknown_default
                    : formData.logo_url
                    }
                />
                <Button
                className='btn btn-outline-primary upload_button'
                onClick={() => setShowUploadPopup(true)}
                variant='contained'
                startIcon={<FileUploadIcon />}
                >
                  Upload Logo
                </Button>
              </div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion className='accordion-container w-100 p-1'  expanded={expandedAccordian2} onChange={handleChangeAccordian2}>
        <AccordionSummary>
        <div className="proposal-header flex-column justify-content-center">
          <p>Contact Details</p>
          <span className="fs-75 fst-italic">We will be reaching out to the given contact for verification</span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} >
          <Grid item xs={12} >
            <TextField
              className="w-100 input-style "
              size="small"
              id="id_tp_contact_name"
              name="tp_contact_name"
              error={!formError.tp_contact_name.status}
              label="Name"
              variant="outlined"
              value={formData.tp_contact_name}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  tp_contact_name: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} >
            <TextField
              className="w-100 input-style"
              size="small"
              error={!formError.tp_contact_email.status}
              id="id_tp_contact_email"
              name="tp_contact_email"
              placeholder="xyz@gmail.com"
              label="Email"
              variant="outlined"
              value={formData.tp_contact_email}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  tp_contact_email: e.target.value,
                }))
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                ),
              }}
            />
            
          </Grid>
          <Grid item xs={12} >
            <TextField
              className="w-100 input-style"
              size="small"
              id="tp_contact_phone"
              name="tp_contact_phone_number"
              error={!formError.tp_contact_phone.status}

              placeholder="9999999999"
              label="Mobile"
              variant="outlined"
              value={formData.tp_contact_phone}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  tp_contact_phone: e.target.value,
                }))
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Phone />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <div className='custom-drawer-footer-sticky'>
        <Grid
          className="add-cancel-btns"
          md={12}
          sm={12}
        >

          <div className="">
            {
              data.name ?
                (
                  <LoadingButton
                    className="btn btn-primary "
                    loading={loading}
                    loadingPosition="start"
                    variant="contained"
                    type="button"
                    onClick={updateInfluencer}

                  >Update Influencer</LoadingButton>
                )
                : (
                  <LoadingButton
                    className="btn btn-primary "
                    loading={loading}
                    loadingPosition="start"
                    variant="contained"
                    type="button"
                    onClick={addInfluencer}
                  >Add Influencers </LoadingButton>
                )
            }
          </div>
        </Grid>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userDetails: state.SwitchLayer2Reducer.switchUser,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddInfluencer);

export { connectToRedux as AddInfluencer };
