import React, { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { NavLink, useNavigate } from "react-router-dom";
import { CAPTCHA_SITE_KEY } from "../../../config/config";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Tooltip,
} from "@mui/material";
import Box from '@mui/material/Box';

import { FormValidation } from "../../../core/utility";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  PublicService,
  SignUpService,
  LocalService,
} from "../../../core/services";
import { connect } from "react-redux";
import { Loading } from "../../../redux/action";
import OtpField from "../../../components/otp/otp";
import { CryptoJS } from "../../../core/utility";
import useBodyClass from "../../../hooks/useBodyClass";
import HeadingStyle from "../../../components/heading-style/heading-style";
import { CaptchaService } from "../../../core/services/captcha.service";
import BrandAgencyContent from "../../../components/signup-right-sec/brand-agency-content";
import "./Agency.scss";
import LoadingButton from "@mui/lab/LoadingButton";
import { DefaultAccountLogo, DefaultUserProfile } from "../../../config/config";
import Footer from "../../../components/footer/footer";
import { MuiOtpInput } from "mui-one-time-password-input";
import MaterialSymbol from "../../../components/icons/material-symbol";
import useConditionalFocus from "../../../hooks/useConditionalFocus";

const localservice: LocalService = new LocalService();
const publicService: PublicService = new PublicService();
const signupService = new SignUpService();
const captchaService = new CaptchaService();


interface ErrorTypes {
  status: boolean;
  message: string;
}

interface Props {
  loading: (data: any) => void;
  UserData: any;
}

// const cryptoJS = new CryptoJS();
const Agency: React.FC<Props> = (props: Props) => {
  const formValidation = new FormValidation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showotpBox, setOtpBox] = useState(false);
  const [showEmailotpBox, setEmailOtpBox] = useState(false);
  const [whatsappnumber, setWhatsAppNumber] = useState("");
  const [disableWhatsappfield, setDisableWhatsAppField] = useState(true);
  const [captchaToken, setCaptchaToken] = useState<any>();
  const [disableEmailField, setDisableEmailField] = useState(true);
  const [email, setEmail] = useState("");
  const [pin, setPin] = useState("");
  const [remarks, setRemarks] = useState("");
  const [isEditButtonDisabled, setIsEditButtonDisabled] = useState(false);
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(true);
  const [timer, setTimer] = useState(60);
  const [isTimerDisabled, setIsTimerDisabled] = useState(true);
  const [showResendEdit, setShowResendEdit] = useState(false);
  const phoneRef = useRef<any>(null);
  const [isEditButtonEmailDisabled, setIsEditButtonEmailDisabled] = useState(false);
  const [isResendButtonEmailDisabled, setIsResendButtonEmailDisabled] = useState(true);
  const [Emailtimer, setEmailTimer] = useState(60);
  const [isEmailTimerDisabled, setIsEmailTimerDisabled] = useState(true);
  const [showEmailResendEdit, setShowEmailResendEdit] = useState(false);
  const emailRef = useRef<any>(null);
  const [phoneOtpVerifyloading, setPhoneOtpVerifyLoading] = useState(false);
  const [emailOtpVerifyloading, setEmailOtpVerifyLoading] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [passwordChecking, setPasswordChecking] = useState('');
  const [emailOtpError, setEmailOtpError] = useState<boolean>(false);
  const [phoneOtpError, setPhoneOtpError] = useState<boolean>(false);


  // For Verification----------------------------------------------
  const [verifiedfield, setVerifiedfield] = useState(false)
  const [verifiedEmailfield, setVerifiedEmailfield] = useState(false);
  const [verifiedWAfield, setVerifiedWAfield] = useState(false);
  const [verifiedEmail, setVerifiedEmail] = useState(false)

  // Disable Input Field
  const [disableWhatsappfieldInput, setDisableWhatsAppFieldInput] = useState(false);
  const [disableEmailfieldInput, setDisableEmailfieldInput] = useState(false);
  const [otpWhatsAppValue, setOtpWhatsAppValue] = React.useState<string>('')
  const [otpEmailValue, setOtpEmailValue] = React.useState<string>('')
  const [otpPINValue, setOtpPINValue] = React.useState<string>('')
 


  //validations
  const [errorcompanyname, setErrorCompanyName] = useState<ErrorTypes>();
  const [errorwhatsapp, setErrorWhatsapp] = useState<ErrorTypes>();
  const [erroremail, setErrorEmail] = useState<ErrorTypes>();
  const [errorPassword, setErrorPassword] = useState<ErrorTypes>();
  const [errorFull_name, setErrorFull_name] = useState<ErrorTypes>();
  const [errorPin, setErrorPin] = useState<ErrorTypes>();
  const [errorRemarks, setErrorRemarks] = useState<ErrorTypes>();
  const [errorConfirmPassword, setErrorConfirmPassword] = useState<ErrorTypes>();

   // field activation
   const [isActiveField, setIsActiveField] = useState({
    name: false,
    fullname: false,
    email: false,
    mobile: false,
    password: false,
    confirmPassword: false,
    pin: false
  });

  const fullNameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);

  useConditionalFocus(fullNameRef, isActiveField.name);
  useConditionalFocus(emailRef, isActiveField.fullname);
  useConditionalFocus(phoneRef, isActiveField.email);
  useConditionalFocus(passwordRef, isActiveField.mobile);
  useConditionalFocus(confirmPasswordRef, isActiveField.password);

  // Button Loading States

  const [loading, setLoading] = useState(false);
  const [phoneloading, setPhoneLoading] = useState(false);
  const [emailloading, setEmailLoading] = useState(false);


  const navigate = useNavigate();

  //handles

  useEffect(() => {
    let countdown: any;
    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setTimer(0);
      setIsResendButtonDisabled(false);
    }

    return () => clearInterval(countdown);
  }, [timer]);

  useEffect(() => {
    let countdown: any;
    if (Emailtimer > 0) {
      countdown = setInterval(() => {
        setEmailTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setEmailTimer(0);
      setIsResendButtonEmailDisabled(false);
    }

    return () => clearInterval(countdown);
  }, [Emailtimer]);

  const handleEmailOtp = (otp: any) => {
    try{
    if (otp.length === 4) {
      setEmailOtpVerifyLoading(true);
      PublicService.emailVerifyOtp({ email: email, otp: otp })
        .then((res) => {
          if (res?.data?.rows?.status) {
            setEmailOtpBox(false);
            setDisableEmailField(true);
            setVerifiedEmailfield(true);
            setVerifiedEmail(true);
            setShowEmailResendEdit(false);
            localservice.toastify(res?.data?.rows?.message);
            setEmailOtpError(false);
            setIsActiveField({
              ...isActiveField,
              email: true
            })
          } else {
            setEmailOtpError(true);
            // localservice.toastify(res?.data?.rows?.message);
          }
        })
        .catch((error) => {
          setEmailOtpVerifyLoading(false);
          throw new Error('VerifyOtp Error ::: ' + error);  
        });
    }}catch(error){
      console.log('handleEmailOtp Error',error)
    }finally{
      setEmailOtpVerifyLoading(false);
    }
  };
  const handleEmailSendOTP = async () => {
    try {
      if (!email) {
        setErrorEmail({ status: true, message: "Email is required." });
        return;
      }
      // Regular expression for email validation
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(email)) {
        setErrorEmail({
          status: true,
          message: "Invalid email format.",
        });
        return;
      }
      if (!erroremail?.status) {
        setErrorEmail(formValidation.GetEmailControlIsValid(email));
        return;
      }

      setEmailLoading(true)
      // Send email OTP
      const response = await PublicService.emailSendOtp({ email: email });
      setDisableEmailField(true);
      setEmailLoading(false)
      setDisableEmailfieldInput(true)
      if (response?.data?.rows?.status) {
        setShowEmailResendEdit(true);
        setIsResendButtonEmailDisabled(true);
        setEmailTimer(30);
        setIsTimerDisabled(true);
        setIsEditButtonEmailDisabled(true);
        setEmailOtpBox(true);
        setDisableEmailField(true)
        setVerifiedEmailfield(true);
        localservice.toastify(response?.data?.rows?.message);
      } else {
        setErrorEmail(response?.data?.rows?.message);
        localservice.toastify(response?.data?.rows?.message);
        setDisableEmailField(false);
        setVerifiedEmailfield(false);
        setDisableEmailfieldInput(false)
      }
    } catch (error) {
      setDisableEmailField(false);
      setDisableEmailfieldInput(false)
      console.warn(`Email OTP Response Error :: ${error}`);
    }
  };


  const handleOnSubmit = async (e: any) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);
      const formData = Object.fromEntries(form);
      let storeData: any = { ...formData };
      let isValidConfirmPassword;
      let { agency_name, email_id, mobile, terms, full_name, password, confirm_password } =
        storeData;
      // recaptcha = storeData["g-recaptcha-response"];
      storeData["pin"] = pin
      storeData["remarks"] = remarks

      setErrorEmail(formValidation.GetEmailControlIsValid(email_id));
      setErrorCompanyName(formValidation.GetNameValidate(agency_name));
      setErrorWhatsapp(formValidation.GetMobileControlIsValid(mobile));
      setErrorFull_name(formValidation.GetNameValidate(full_name));
      setErrorPassword(formValidation.GetPasswordValidate(password));
      setErrorPin(formValidation.GetPinIsValid(`${pin}`));
      setErrorRemarks(formValidation.GetNameValidate(remarks))

      const isValidEmail = formValidation.GetEmailControlIsValid(email_id).status
      const isValidCompany = formValidation.GetNameValidate(agency_name).status;
      const isValidFullName = formValidation.GetNameValidate(full_name).status
      const isValidPin = formValidation.GetPinIsValid(`${pin}`).status
      const isValidRemarks = props?.UserData == null ? true : formValidation.GetNameValidate(remarks).status;
      const isValidPassword = formValidation.GetPasswordValidate(password).status
    
      if(confirm_password == ''){
        setErrorConfirmPassword({
          status:false,
          message:'This field is required'
        })
        isValidConfirmPassword = false;
      }else{
        if(password == confirm_password){
          isValidConfirmPassword = true;
          setErrorConfirmPassword({
            status:true,
            message:''
          })
        }else{
          setErrorConfirmPassword({
            status:false,
            message:'Password does not match'
          })
          isValidConfirmPassword = false;
        }
      }

      if (!verifiedWAfield && whatsappnumber) {
        localservice.toastify("WhatsApp No. Not Verified.", "error", 1000);
      }
      if (!verifiedEmailfield && email) {
        localservice.toastify("Email Id. Not Verified.", "error", 1000);
      }
      if (
        isValidEmail &&
        errorwhatsapp?.status &&
        isValidCompany &&
        isValidFullName &&
        (props?.UserData == null ? terms : true) &&
        isValidRemarks &&
        isValidPin &&
        isValidPassword &&
        isValidConfirmPassword
      ) {
        storeData["mobile_verified"] = "Yes";
        storeData["email_verified"] = "Yes";
        storeData["photo"] = DefaultUserProfile.influencer_unknown_default;
        storeData["logo"] = DefaultAccountLogo.agency;
        storeData["createdby"] = props?.UserData == null ? "0" : props?.UserData?.user_id

        props.loading(true);
        if (props?.UserData == null) {
          const verifyCaptcha = async () => {
            try {
              const res = await captchaService.verifyCaptcha({ token: captchaToken });
              return res?.data?.rows?.message === "Captcha verified successfully!";
            } catch (error) {
              throw new Error(`verifyCaptcha Error :: ${JSON.stringify(error)}`);
            }
          };

          if (await verifyCaptcha()) {
            try {
              const res = await signupService.createAgencyAccount(storeData);
              if (res.data.status.status) {
                // let date = new Date();
                // let id = encodeURI(
                //   JSON.stringify(cryptoJS.encrypt(date + "", "encryptKey"))
                // );
                localservice.setStorageItem("login", `${email_id}`)
                localservice.setStorageItem("password", `${password}`)
                navigate(`/welcome/agency?id=${res.data.status?.user_id}`);
              }
              props.loading(false);

            }
            catch (error) {
              props.loading(false);
              console.warn("Captcha Verification (Brand) Error in ", error)
            }
            finally {
              props.loading(false);

            }

          }
          else {
            props.loading(false);

          }
        }
        else {
          try {
            const res = await signupService.createAgencyAccount(storeData);
            if (res.data.status.status) {
              // let date = new Date();
              // let id = encodeURI(
              //   JSON.stringify(cryptoJS.encrypt(date + "", "encryptKey"))
              // );
              const checkStatus = res.data.status.status ? "success" : "info";
              localservice.toastify(res.data.status.message, checkStatus, 1000);
              navigate(`/brand/admin-dashboard`);
            }
            props.loading(false);

          }
          catch (error) {
            props.loading(false);
            console.warn("Error in ", error)

          }
          finally {
            props.loading(false);

          }


        }

      }
      else {
        props.loading(false);
      }
    } catch (error) {
      props.loading(false);
      throw new Error(`handle On Submit in Brand Error :: ${error}`);
    }
  };

  const handleFullName = (e: any) => {
    const {
      target: { value },
    } = e;
    setErrorFull_name(formValidation.GetNameValidate(value));
    if(!isActiveField.fullname){
      setIsActiveField({
        ...isActiveField,
        fullname: formValidation.GetNameValidate(value).status
      })
    }
  };

  const handlePassword = (e: any) => {
    const {
      target: { value },
    } = e;
    setPasswordChecking(value);
    setErrorPassword(formValidation.GetPasswordValidate(value));
    if(!isActiveField.password){
      setIsActiveField({
        ...isActiveField,
        password: formValidation.GetPasswordValidate(value).status
      })
    }
  };

  const handleConfirmPassword = (e: any) => {
    const {
      target: { value },
    } = e;
    if(value !== passwordChecking) {
      setErrorConfirmPassword({status: false, message: 'Password does not match'});
    }else{
      setErrorConfirmPassword({status: true, message: ''});
      setIsActiveField({
        ...isActiveField,
        confirmPassword: true
      })
    }
  };


  const handlePin = (pin: any) => {
    setPin(pin);
    setIsActiveField({
      ...isActiveField,
      pin: true
    })
  };

  const handleWhatsAppOtp = (otp: any) => {
    try{
    if (otp.length === 4) {
      setPhoneOtpVerifyLoading(true);
      PublicService.verifyOtp({ mobile: +whatsappnumber, otp: otp })
        .then((res) => {
          if (res?.data?.rows?.status) {
            setOtpBox(false);
            setDisableWhatsAppField(true);
            setVerifiedWAfield(true);
            setShowResendEdit(false);
            localservice.toastify(res?.data?.rows?.message, "success", 1000);
            setVerifiedfield(true);
            setPhoneOtpError(false);
            setIsActiveField({
              ...isActiveField,
              mobile: true,
            })
          } else {
            setDisableWhatsAppField(false);
            setPhoneOtpError(true);
            // localservice.toastify(res?.data?.rows?.message, "error", 1000);
          }
        })
        .catch((error) => {
          setPhoneOtpVerifyLoading(false);
          throw new Error("VerifyOtp Error ::: " + error);
        });
    }}catch(error){
      console.log('handleWhatsAppOtp error',error);
    }finally{
      setPhoneOtpVerifyLoading(false);
    }
  };
  const handleSendOtpButton = () => {
    setDisableWhatsAppFieldInput(true)
    const through_admin_dashboard = props?.UserData == null ? "false" : "true";
    const phone_pattern = new RegExp(/^(?:\+\d{1,3}\s?)?(?:\(\d{1,4}\)|\d{1,4})[-.\s]?\d{2,12}$/);
    if (`${whatsappnumber}`.length === 10 && phone_pattern.test(whatsappnumber)) {
      setPhoneLoading(true);
      setDisableWhatsAppField(false)
      // setVerifiedWAfield(true)
      PublicService.sendOtp({ mobile: +whatsappnumber, through_admin_dashboard: through_admin_dashboard })
        .then((res: any) => {
          if (res?.data?.rows?.status) {
            if (props?.UserData == null) {
              setShowResendEdit(true);
              setIsResendButtonDisabled(true);
              setTimer(30);
              setIsTimerDisabled(true);
              setIsEditButtonDisabled(true);
              setOtpBox(res?.data?.rows?.status);
              // setVerifiedWAfield(res?.data?.rows?.status);
              setDisableWhatsAppField(res?.data?.rows?.status);
            }
            else {
              setVerifiedWAfield(false)
            }
            localservice.toastify(res.data.rows.message, "success", 1000);
          } else {
            setErrorWhatsapp(res?.data?.rows?.message);
            setDisableWhatsAppField(false)
            setDisableWhatsAppFieldInput(false)
            localservice.toastify(res.data.rows.message, "error", 1000);
          }
          setPhoneLoading(false);

        })
        .catch((error: any) => {
          setLoading(false);
          setDisableWhatsAppField(true)
          setDisableWhatsAppFieldInput(false)
          setVerifiedWAfield(false);
          throw new Error(`Otp Response Error :: ${error}`);
        });
    }
  };
  const handleWhatsApp = (e: any) => {
    try {
      if (e.target.value.length < 10) {
        setWhatsAppNumber((e.target.value));
        setErrorWhatsapp(formValidation.GetMobileControlIsValid(e.target.value));
        setDisableWhatsAppField(true)
      }
      else if (e.target.value.length == 10 && formValidation.GetMobileControlIsValid(e.target.value).status == true) {
        setWhatsAppNumber((e.target.value));
        setErrorWhatsapp(formValidation.GetMobileControlIsValid(e.target.value));
        setDisableWhatsAppField(false)
      }
      else if(e.target.value.length > 10) {
        setWhatsAppNumber(e.target.value.slice(0, 10));
        setErrorWhatsapp(formValidation.GetMobileControlIsValid(e.target.value.slice(0, 10)));

      }
    } catch (error) {
      throw new Error(`handleWhatsApp error :: ${error}`);
    }
  };

  async function handleEmail(e: any) {
    const value = e?.target?.value ?? e;
    setEmail(e.target.value)
    let isValid: { status: boolean; message: string } =
      formValidation.GetEmailControlIsValid(value);
    setErrorEmail(isValid);
    
    if (isValid.status) {
      const response = await publicService.checkEmailIsRegister({
        email: value,
        user_type: "agency",

      });
      if (response.data.status.message.length === 0) {
        if (props?.UserData != null) {
          // setVerifiedEmailfield(true);
        }
        setDisableEmailField(false);

      }
      else {
        setVerifiedEmailfield(false);
        setDisableEmailField(true)

      }

      if (response) {
        isValid = response.data.status;
        setErrorEmail(isValid);
      }
    }else{
      setDisableEmailField(true);
    }

    return isValid;
  }


  const handleAgency = (e: any) => {
    try {
      const {
        target: { value },
      } = e;
      setErrorCompanyName(formValidation.GetNameValidate(value));
      if(!isActiveField.name){
        setIsActiveField({
          ...isActiveField,
          name: formValidation.GetNameValidate(value).status
        })
      }
    } catch (error) {
      throw new Error("handleCompanyName :: " + error);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleChangePhone = () => {
    setVerifiedWAfield(false);
    setOtpBox(false);
    setShowResendEdit(false);
    setDisableWhatsAppFieldInput(false)
    if (phoneRef.current) {
      phoneRef.current.focus();
    }
  }

  const handleChangeEmail = () => {
    setVerifiedEmailfield(false);
    setEmailOtpBox(false);
    setShowEmailResendEdit(false);
    setDisableEmailfieldInput(false)
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }
  useBodyClass("common-signup-page");
 
const handlePINChange = (newValue: string) => {
  const filteredValue = newValue.replace(/\D/g, '');
  setOtpPINValue(filteredValue);
  setPin(filteredValue);
}
const handleWhatsAppChange = (newValue: string) => {
  const filteredValue = newValue.replace(/\D/g, '');
  setOtpWhatsAppValue(filteredValue);
}
const handleEmailChange = (newValue: string) => {
  const filteredValue = newValue.replace(/\D/g, '');
  setOtpEmailValue(filteredValue)
}
// Function to check if a value is numeric
const matchIsNumeric = (text: any) => {
    return !isNaN(Number(text));
};

// Validation function for MuiOtpInput
const validateChar = (value: string) => {
    // return matchIsNumeric(value);
    return /^\d*$/.test(value);
};

  return (
    <>
    <div className="_influencer-container">
      <div className="container">
        <div className="row">
          <div className={props?.UserData == null ? "col-12 col-lg-6 mt-4 mt-lg-0" : "col-12 col-lg-12 mt-4 mt-lg-0"}>
            {(() => {
              if (props?.UserData == null) {
                return (
                  <p className="_influencer-login-btn">
                    Already have an account?{" "}
                    <span>
                      <NavLink
                        to="/login"
                        className="_influencer-signup-navlink"
                      >
                        Login
                      </NavLink>
                    </span>
                  </p>)
              }
            })()}
            <div className="_influencer-signup">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-1 mb-2 __backArrow">
                  <span className="__backArrow_icon">
                    <NavLink to="/" className="__navLink">
                      <ArrowBackIcon className="_icon" />
                    </NavLink>
                  </span>{" "}
                  <HeadingStyle title="Agency Signup" />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-1 mb-2 text-end">
                  <span className="_mandatory">All fields are mandatory</span>
                </div>
              </div>
              <form onSubmit={handleOnSubmit} className="__signup_form">
                <div className="col-12 mb-3">
                  <TextField
                    className="w-100  input-common input-style"
                    size="small"
                    autoFocus
                    id="agency_name_id"
                    onBlur={handleAgency}
                    name="agency_name"
                    placeholder="Agency Name"
                    label="Agency Name"
                    variant="outlined"
                  />
                  <span className="error">{errorcompanyname?.message}</span>
                </div>
                <div className="col-12 mb-3">
                  <TextField
                    className="w-100  input-common input-style"
                    size="small"
                    inputRef={fullNameRef}
                    id="full_name_id"
                    disabled={!isActiveField.name}
                    onBlur={handleFullName}
                    name="full_name"
                    placeholder="Your Full Name"
                    label="Full Name"
                    variant="outlined"
                  />
                  <span className="error">{errorFull_name?.message}</span>
                </div>
                <div className="col-12 mb-3 __wa_column">
                  <TextField
                    className= {`w-100 input-style input-common ${verifiedEmail ? "verified-email": ""}`}
                    size="small"
                    id="email_id"
                    disabled={!isActiveField.fullname}
                    inputRef={emailRef}
                    onChange={handleEmail}
                    name="email_id"
                    label="Your Email Id"
                    variant="outlined"
                    InputProps={{ readOnly: disableEmailfieldInput }}
                  />
                  {/* {(() => {
                    if (props?.UserData == null) {
                      return (
                        <>
                          <LoadingButton
                            className="__button"
                            color="success"
                            loading={emailloading}
                            loadingPosition="start"
                            variant="contained"
                            startIcon={<SendIcon />}
                            onClick={handleEmailSendOTP}
                            disabled={disableEmailField}
                          >
                            {props?.UserData == null ? "Send OTP" : "Verify OTP"}
                          </LoadingButton>
                        </>
                      )
                    }
                  })()} */}
                     {!verifiedEmail &&
                      <LoadingButton
                        className={`__button ${disableEmailField ? "btn-disabled" : ""}`}
                        color="success"
                        loading={emailloading}
                        loadingPosition="start"
                        variant="contained"
                        startIcon={<SendIcon />}
                        onClick={handleEmailSendOTP}
                        disabled={disableEmailField}
                      >
                       Send OTP
                      </LoadingButton>
                    }
                      {verifiedEmail &&
                        <Tooltip className="icon-verified" title="verified" arrow>
                          <IconButton size="small" className="p-0">
                            <MaterialSymbol iconName="verified" fontweight="200" />
                          </IconButton>
                        </Tooltip>
                      }
                  <span className="error">{erroremail?.message}</span>
                  {showEmailResendEdit &&
                    <div className="resend-otp d-flex align-items-center w-100 mb-2">
                      {isEditButtonEmailDisabled && <p className="change-phone fs-12 btn btn-text-link me-auto my-0" onClick={handleChangeEmail}>Change Email</p>}
                      <div className="resend-otp-container d-flex">
                        <button
                          className="otp-btn fs-12 btn btn-text-link"
                          onClick={handleEmailSendOTP}
                          type="button"
                          disabled={isResendButtonEmailDisabled}
                        >
                          Resend OTP
                        </button>
                        {isEmailTimerDisabled && <p className="otp-time fs-12 my-0">&nbsp; 00:{Emailtimer < 10 ? `0${Emailtimer}` : Emailtimer}</p>}
                      </div>

                    </div>
                  }
                  {/* {showEmailotpBox && <OtpField handleOtp={handleEmailOtp} />} */}
                  {/* {showEmailotpBox && <MuiOtpInput
                    value={otpEmailValue}
                    onChange={handleEmailChange}
                    onComplete={handleEmailOtp}
                    length={4}
                    autoFocus
                    TextFieldsProps={{ size: 'small', placeholder: '0', type:"tel"  }}
                    validateChar={validateChar} 
                    />} */}
                     {showEmailotpBox && 
                        <div className="mobile-verification">
                        <MuiOtpInput
                          className="otp-fields"
                          value={otpEmailValue}
                          onChange={handleEmailChange}
                          length={4}
                          autoFocus
                          TextFieldsProps={{ size: 'small', placeholder: '0', type:"tel"  }}
                          validateChar={validateChar} 
                          />
                          
                          <LoadingButton
                          className="verify-button"
                          loading={emailOtpVerifyloading}
                          variant="contained"
                          color="success"
                          disabled={otpEmailValue.length == 4 ? false : true}
                          onClick={()=>handleEmailOtp(otpEmailValue)}
                        >
                          Verify
                        </LoadingButton>
                        </div>
                          }
                          {emailOtpError && <span className="error">Wrong One Time Password (OTP)</span>}
                </div>
                <div className="col-12 mb-3 __wa_column">
                  <TextField
                    className="w-100  input-common input-style"
                    type="number"
                    size="small"
                    color="primary"
                    disabled={!isActiveField.email}
                    inputRef={phoneRef}
                    onChange={handleWhatsApp}
                    name="mobile"
                    id="whatsapp_number_id"
                    label="WhatsApp No."
                    value={whatsappnumber}
                    InputProps={{
                      readOnly: disableWhatsappfieldInput,
                    }}
                    variant="outlined"
                  />
                  {!verifiedfield &&
                  <LoadingButton
                    className={`__button ${disableWhatsappfield ? "btn-disabled" : ""}`}
                    color="success"
                    loading={loading}
                    loadingPosition="start"
                    disabled={disableWhatsappfield}
                    onClick={handleSendOtpButton}
                    variant="contained"
                    startIcon={<WhatsAppIcon />}
                  >
                    Send OTP
                  </LoadingButton>
                  }
                   {verifiedfield && 
                        <Tooltip className="icon-verified" title="verified" arrow>
                          <IconButton size="small" className="p-0">
                            <MaterialSymbol iconName="verified" fontweight="200" />
                          </IconButton>
                        </Tooltip>
                      }
                  <span className="error">{errorwhatsapp?.message}</span>
                  {showResendEdit &&
                    <div className="resend-otp d-flex align-items-center w-100 mb-2">
                      {isEditButtonDisabled && <p className="change-phone fs-12 btn btn-text-link me-auto my-0" onClick={handleChangePhone}>Change Phone</p>}
                      <div className="resend-otp-container d-flex">
                        <button
                          className="otp-btn fs-12 btn btn-text-link"
                          onClick={handleSendOtpButton}
                          type="button"
                          disabled={isResendButtonDisabled}
                        >
                          Resend OTP
                        </button>
                        {isTimerDisabled && <p className="otp-time fs-12 my-0">&nbsp; 00:{timer < 10 ? `0${timer}` : timer}</p>}
                      </div>

                    </div>
                  }
                  {/* {showotpBox && <OtpField handleOtp={handleWhatsAppOtp} />} */}
                  {showotpBox &&  
                  <div className="mobile-verification"> 
                        <MuiOtpInput
                        className="otp-fields"
                        value={otpWhatsAppValue}
                        onChange={handleWhatsAppChange}
                        length={4}
                        autoFocus
                        TextFieldsProps={{ size: 'small', placeholder: '0', type:"tel"  }}
                        validateChar={validateChar} 
                        />
                          <LoadingButton
                          className="verify-button"
                          loading={phoneOtpVerifyloading}
                          variant="contained"
                          color="success"
                          disabled={otpWhatsAppValue.length == 4 ? false : true}
                          onClick={()=>handleWhatsAppOtp(otpWhatsAppValue)}
                        >
                          Verify
                        </LoadingButton>
                        </div>
                        }  
                       {phoneOtpError && <span className="error">Wrong One Time Password (OTP)</span>}               
                </div>
                <div className="col-12 mb-3">
                  <FormControl
                    variant="outlined"
                    className="w-100  input-common input-style"
                    size="small"
                    disabled={!isActiveField.mobile}
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="password_id"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      inputRef={passwordRef}
                      onBlur={handlePassword}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                  <span className="error">{errorPassword?.message}</span>
                </div>

                <div className="row mb-3">
                  <div className="col">
                    <FormControl
                      variant="outlined"
                      className="w-100 input-common input-style"
                      size="small"
                      disabled={!isActiveField.password}
                    >
                      <InputLabel htmlFor="outlined-adornment-password">
                        Confirm Password
                      </InputLabel>
                      <OutlinedInput
                        id="confirm_password_id"
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirm_password"
                        inputRef={confirmPasswordRef}
                        onBlur={handleConfirmPassword}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownConfirmPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Confirm Password"
                      />
                    </FormControl>
                    {errorConfirmPassword && !errorConfirmPassword.status && <span className="error">{errorConfirmPassword?.message}</span>}
                  </div>
                </div>

                <div className="row __manually mb-3">
                  <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                    <span style={{ fontSize: "14px" }}> Create Login Pin  </span>
                  </div>
                  <div className="col">
                    <div className="__wa_column">
                      {/* {<OtpField handleOtp={handlePin} />} */}
                      <MuiOtpInput
                        value={otpPINValue}
                        onChange={handlePINChange}
                        onComplete={handlePin}
                        style={{ pointerEvents: isActiveField.confirmPassword ? 'auto' : 'none', opacity: isActiveField.confirmPassword ? 1 : 0.5 }}
                        length={4}
                        TextFieldsProps={{ size: 'small', placeholder: '0', type:"tel"  }}
                        validateChar={validateChar} 
                        />
                    </div>
                    <span className="error">
                      {errorPin?.message}
                    </span>
                  </div>
                </div>

                {(() => {
                  if (props?.UserData == null) {
                    return (
                      <>
                        <div className="row mb-3">
                          <div className="col">
                            <ReCAPTCHA
                              sitekey={CAPTCHA_SITE_KEY}
                              onChange={(token: any) => setCaptchaToken(token)}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col">
                            <Checkbox
                              inputProps={{
                                "aria-label": "Checkbox",
                              }}
                              name="terms"
                              defaultChecked
                              size="small"
                              style={{
                                margin: "0",
                                padding: "0",
                              }}
                            />
                            <span>
                              I agree to the{" "}
                              <NavLink
                                to="/terms_condition"
                                style={{
                                  textDecoration: `none`,
                                  color: `#0d6efd`,
                                }}
                              >
                                {" "}
                                Terms and Conditions
                              </NavLink>{" "}
                              and Privacy Policy
                            </span>
                          </div>
                        </div>
                      </>
                    )
                  }
                  else {
                    return (
                      <>
                        <div className="row">

                          <Box
                            component="form"
                            sx={{
                              '& .MuiTextField-root': { m: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <div>
                              <TextField
                                id="outlined-multiline-static"
                                label="Remarks"
                                multiline
                                rows={2}
                                value={remarks}
                                onChange={(e) => {
                                  setRemarks(e.target.value)
                                }}
                              />
                              <span className="error">{errorRemarks?.message}</span>
                            </div>
                          </Box>
                        </div>
                      </>)

                  }
                })()}
                <div className="row">
                  <div className="col text-end">
                    <NavLink
                      to="/"
                      style={{
                        textDecoration: `none`,
                      }}
                      className="btn btn-outline-primary btn-mui "
                    >
                      Cancel
                    </NavLink>
                    <Button
                      type="submit"
                      variant="contained"
                      className="btn btn-primary ms-2"
                      disabled={!isActiveField.pin}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {(() => {
            if (props?.UserData == null) {
              return (
                <div className="col-12 col-lg-6 mt-4 mt-lg-0 _influencer-content-sec">
                  <BrandAgencyContent />
                </div>
              )
            }
          })()}
        </div>
      </div>
    </div>
    <Footer />
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};
const mapStateToProps = (state: any) => {
  return {
    UserData: state.UserReducer.UserData,
  };
}
const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(Agency);
export { connectToRedux as Agency };
