import { IconButton, SwipeableDrawer } from '@mui/material'
import React from 'react'

type Props = {
  open: any;
  onClose: any;
  onOpen: any;
  closeDrawer: any;
  planInfo: any;
  selectedPlanName: any;

}

export default function PlanDetailDrawer({ closeDrawer, open, onOpen, onClose, planInfo, selectedPlanName }: Props) {
  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      className='drawer-plan-detail popup-drawer '
    >
      <div className='popup-drawer-header'>
        <div className='popup-drawer-header-left'>
          <IconButton
            onClick={closeDrawer}
          >
            <span className='d-none d-md-inline-flex material-symbols-rounded'>chevron_left</span>
            <span className='d-inline-flex d-md-none material-symbols-rounded'>cancel</span>
          </IconButton>
          <div className='scrolling-text-sec'>
            <div className='inner-scrolling-text-container'>
              <strong className='inner-scrolling-text'>{`${selectedPlanName}`}</strong>
            </div>
          </div>
        </div>


      </div>
      <div className='popup-drawer-body'>
        <div className="camp-detail-toggle">
          <ul>
            {planInfo?.industry &&

              <li><span>Industry</span> <span>{planInfo?.industry}</span></li>}
            {planInfo?.organization &&
              <li><span>Organization</span> <span>{planInfo?.organization}</span></li>}
            {planInfo?.brand &&
              <li><span>Product Brand</span> <span>{planInfo?.brand}</span></li>
            }
            {planInfo?.category &&
              <li><span>Product Category</span> <span>{planInfo?.category}</span></li>
            }
            {planInfo?.description &&
              <li><span>Description</span>
                <span>{planInfo?.description}</span></li>
            }
          </ul>
        </div>
      </div>
    </SwipeableDrawer>
  )
}