export const SubscriptionPlanFeaturesData = {
  influencer: {
    basic_plan_features: [
      { text: 'Live Briefs', liClassName: '', iconClassName: 'bi bi-check',value:'' },
      { text: 'Apply to campaigns', liClassName: '', iconClassName: 'bi bi-x' },
      { text: <strong>Creative Suite</strong>, liClassName: 'border-bottom-0', iconClassName: '',value:'' },
      { text: 'Creative approval', liClassName: '', iconClassName: 'bi bi-x',value:'' },
      { text: 'Scheduling', liClassName: '', iconClassName: 'bi bi-x' ,value:''},
      { text: <strong>Analytics</strong>, liClassName: 'border-bottom-0', iconClassName: '',value:'' },
      { text: 'Quality Audience', liClassName: '', iconClassName: 'bi bi-x',value:'' },
      { text: 'Audience Interests', liClassName: '', iconClassName: 'bi bi-x',value:'' },
      { text: 'Engagement Stats', liClassName: '', iconClassName: 'bi bi-x',value:'' },
      { text: <strong>Invoicing</strong>, liClassName: 'border-bottom-0', iconClassName: '',value:'' },
      { text: 'Billing and Invoicing', liClassName: '', iconClassName: 'bi bi-x',value:'' },
      { text: 'GST enabled Invoicing', liClassName: '', iconClassName: 'bi bi-x',value:'' }
    ],
    premium_plan_features: [
      { text: 'Live Briefs', liClassName: '', iconClassName: 'bi bi-check',value:'' },
      { text: 'Apply to campaigns', liClassName: '', iconClassName: 'bi bi-check',value:'' },
      { text: <strong>Creative Suite</strong>, liClassName: 'border-bottom-0', iconClassName: '' ,value:''},
      { text: 'Creative approval', liClassName: '', iconClassName: 'bi bi-check',value:'' },
      { text: 'Scheduling', liClassName: '', iconClassName: 'bi bi-check' ,value:''},
      { text: <strong>Analytics</strong>, liClassName: 'border-bottom-0', iconClassName: '',value:'' },
      { text: 'Quality Audience', liClassName: '', iconClassName: 'bi bi-check',value:'' },
      { text: 'Audience Interests', liClassName: '', iconClassName: 'bi bi-check' ,value:''},
      { text: 'Engagement Stats', liClassName: '', iconClassName: 'bi bi-check' ,value:''},
      { text: <strong>Invoicing</strong>, liClassName: 'border-bottom-0', iconClassName: '',value:'' },
      { text: 'Billing and Invoicing', liClassName: '', iconClassName: 'bi bi-check' ,value:''},
      { text: 'GST enabled Invoicing', liClassName: '', iconClassName: 'bi bi-check',value:'' }
    ]
  },
  brand: {
    basic_plan_features: [
      { text: <strong>Influencers</strong>, liClassName: '', iconClassName: '',value:'' },
      { text: 'Search influencers', liClassName: 'brand-li', iconClassName: '', value:'25'},
      { text: 'Profile reports', liClassName: 'brand-li', iconClassName: '',value:'100' },
      { text: 'Download profile report', liClassName: 'brand-li', iconClassName: 'bi bi-x' ,value:''},
      { text: 'Create list of influencers', liClassName: 'brand-li', iconClassName: '' ,value:'3 lists, 10 influencers each'},
      { text: 'Compare (upto 15 influencers)', liClassName: 'brand-li', iconClassName: 'bi bi-check', value:''},
      { text: <strong>Campaigns</strong>, liClassName: 'border-bottom-0', iconClassName: '', value:''},
      { text: 'Create a barter campaign brief', liClassName: 'brand-li', iconClassName: 'bi bi-x', value:'' },
      { text: 'Create a paid campaign brief', liClassName: 'brand-li', iconClassName: '', value:'1' },
      { text: 'Get unlimited proposals', liClassName: 'brand-li', iconClassName: '', value:'Till 7th day' },
      { text: 'Negotiate influencers\' quotation', liClassName: 'brand-li', iconClassName: '', value:'Till 14th day' },
      { text: 'Create approval suite', liClassName: 'brand-li', iconClassName: '', value:'Till 30th day' },
      { text: <strong>Insights & Reporting</strong>, liClassName: 'border-bottom-0', iconClassName: '', value:'' },
      { text: 'View campaign performance', liClassName: 'brand-li', iconClassName: '', value:'Till 40th day' },
      { text: 'View individual influencer performance', liClassName: 'brand-li', iconClassName: 'bi bi-check', value:'' },
      { text: 'View individual post performance', liClassName: 'brand-li', iconClassName: 'bi bi-check', value:'' },
      { text: 'Download campaign report', liClassName: 'brand-li', iconClassName: 'bi bi-x', value:'' },
      { text: <strong>Clan Manager</strong>, liClassName: 'border-bottom-0', iconClassName: '', value:'' },
      { text: 'Dedicated Clan manager', liClassName: 'brand-li', iconClassName: 'bi bi-x', value:'' },
    ],
    premium_plan_features: [
      { text: <strong>Influencers</strong>, liClassName: 'border-bottom-0', iconClassName: '',value:'' },
      { text: 'Search influencers', liClassName: 'brand-li', iconClassName: '', value:'Unlimited'},
      { text: 'Profile reports', liClassName: 'brand-li', iconClassName: '',value:'Unlimited' },
      { text: 'Download profile report', liClassName: 'brand-li', iconClassName: '' ,value:'Unlimited'},
      { text: 'Create list of influencers', liClassName: 'brand-li', iconClassName: '' ,value:'Unlimited'},
      { text: 'Compare (upto 15 influencers)', liClassName: 'brand-li', iconClassName: '', value:'Unlimited'},
      { text: <strong>Campaigns</strong>, liClassName: 'border-bottom-0', iconClassName: '', value:''},
      { text: 'Create a barter campaign brief', liClassName: 'brand-li', iconClassName: '', value:'Unlimited' },
      { text: 'Create a paid campaign brief', liClassName: 'brand-li', iconClassName: '', value:'Unlimited' },
      { text: 'Get unlimited proposals', liClassName: 'brand-li', iconClassName: 'bi bi-check', value:'' },
      { text: 'Negotiate influencers\' quotation', liClassName: 'brand-li', iconClassName: 'bi bi-check', value:'' },
      { text: 'Create approval suite', liClassName: 'brand-li', iconClassName: 'bi bi-check', value:'' },
      { text: <strong>Insights & Reporting</strong>, liClassName: 'border-bottom-0', iconClassName: '', value:'' },
      { text: 'View campaign performance', liClassName: 'brand-li', iconClassName: 'bi bi-check', value:'' },
      { text: 'View individual influencer performance', liClassName: 'brand-li', iconClassName: 'bi bi-check', value:'' },
      { text: 'View individual post performance', liClassName: 'brand-li', iconClassName: 'bi bi-check', value:'' },
      { text: 'Download campaign report', liClassName: 'brand-li', iconClassName: 'bi bi-check', value:'' },
      { text: <strong>Clan Manager</strong>, liClassName: 'border-bottom-0', iconClassName: '', value:'' },
      { text: 'Dedicated Clan manager', liClassName: 'brand-li', iconClassName: 'bi bi-check', value:'' },
    ]
  }
};


export const SubscriptionBasicPlanData = {
  influencer: {
    id: 1,
    user_type: 'Influencer',
    plan_name: 'BASIC',
    plan_type: '',
    gst_included: 'false',
    subscription_amount: 0,
    net_amount: 0,
    amount: 0,
    discount: 0
  },
  brand: {
    id: 2,
    user_type: 'Brand',
    plan_name: 'BASIC',
    plan_type: '',
    gst_included: 'false',
    subscription_amount: 0,
    net_amount: 0,
    amount: 0,
    discount: 0
  }
}