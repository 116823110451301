import React, { useEffect, useState } from "react";
import { MenuItem, TextField } from "@mui/material";

const genderOptions = [
  {
    value: "M",
    label: "Male",
  },
  {
    value: "F",
    label: "Female",
  },
  {
    value: "O",
    label: "Other",
  },
];

export const AddInfluencerIG: React.FC = () => {
  const [gender, setGender] = React.useState("");
  const handleChange = (event: any) => {
    setGender(event.target.value);
  };
  const [initialValues, setInitialValues] = useState({
    profilelink: "",
    influencername: "",
    phone: "",
    emailid: "",
    gender: "",
    location: "",
    categoryofinfluencer: "",
    followers: "",
    campaign: "",
    brand: "",
    campaignhashtags: "",
    costofreel: "",
    avgviewofreel: "",
    costofstory: "",
    swipeuplink: "",
    costofimagepost: "",
    brandworked: "",
  });
  const [formValues, setFormValues] = useState<{ [key: string]: string }[]>([]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setFormValues([...formValues, { ...initialValues, gender }]);
  };

  useEffect(() => {
    localStorage.setItem("formValues", JSON.stringify(formValues));
    setInitialValues({
      profilelink: "",
      influencername: "",
      phone: "",
      emailid: "",
      gender: "",
      location: "",
      categoryofinfluencer: "",
      followers: "",
      campaign: "",
      brand: "",
      campaignhashtags: "",
      costofreel: "",
      avgviewofreel: "",
      costofstory: "",
      swipeuplink: "",
      costofimagepost: "",
      brandworked: "",
    });
  }, [formValues]);

  return (
    <section>
      <form
        className="ig_influencer"
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
            <TextField
              className="w-100 input-style"
              value={initialValues.profilelink}
              size="small"
              label="Profile Link"
              variant="outlined"
              onChange={(e) =>
                setInitialValues({
                  ...initialValues,
                  profilelink: e.target.value,
                })
              }
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
            <TextField
              className="w-100 input-style"
              value={initialValues.influencername}
              size="small"
              label="Influencer Name"
              variant="outlined"
              onChange={(e) =>
                setInitialValues({
                  ...initialValues,
                  influencername: e.target.value,
                })
              }
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
            <TextField
              className="w-100 input-style"
              value={initialValues.phone}
              size="small"
              label="Phone"
              variant="outlined"
              onChange={(e) =>
                setInitialValues({
                  ...initialValues,
                  phone: e.target.value,
                })
              }
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
            <TextField
              className="w-100 input-style"
              value={initialValues.emailid}
              size="small"
              label="Email ID"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              onChange={(e) =>
                setInitialValues({
                  ...initialValues,
                  emailid: e.target.value,
                })
              }
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
            {/* <TextField
              className="w-100 input-style"
              value={initialValues.gender}
              size="small"
              label="Gender"
              variant="outlined"
              onChange={(e) =>
                setInitialValues({
                  ...initialValues,
                  gender: e.target.value,
                })
              }
            /> */}

            <TextField
              className="w-100 input-style"
              size="small"
              select
              label="Gender"
              value={gender}
              onChange={handleChange}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            >
              {genderOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
            <TextField
              className="w-100 input-style"
              value={initialValues.location}
              size="small"
              label="Location"
              variant="outlined"
              onChange={(e) =>
                setInitialValues({
                  ...initialValues,
                  location: e.target.value,
                })
              }
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
            <TextField
              className="w-100 input-style"
              value={initialValues.categoryofinfluencer}
              size="small"
              label="Category of influencer         "
              variant="outlined"
              onChange={(e) =>
                setInitialValues({
                  ...initialValues,
                  categoryofinfluencer: e.target.value,
                })
              }
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
            <TextField
              className="w-100 input-style"
              value={initialValues.followers}
              size="small"
              label="Followers"
              variant="outlined"
              onChange={(e) =>
                setInitialValues({
                  ...initialValues,
                  followers: e.target.value,
                })
              }
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
            <TextField
              value={initialValues.campaign}
              className="w-100 input-style"
              size="small"
              label="Campaign"
              variant="outlined"
              onChange={(e) =>
                setInitialValues({
                  ...initialValues,
                  campaign: e.target.value,
                })
              }
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
            <TextField
              className="w-100 input-style"
              value={initialValues.brand}
              size="small"
              label="Brand"
              variant="outlined"
              onChange={(e) =>
                setInitialValues({
                  ...initialValues,
                  brand: e.target.value,
                })
              }
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
            <TextField
              className="w-100 input-style"
              value={initialValues.campaignhashtags}
              size="small"
              label="Campaign Hashtags"
              variant="outlined"
              onChange={(e) =>
                setInitialValues({
                  ...initialValues,
                  campaignhashtags: e.target.value,
                })
              }
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
            <TextField
              className="w-100 input-style"
              value={initialValues.costofreel}
              size="small"
              label="Cost of reel"
              variant="outlined"
              onChange={(e) =>
                setInitialValues({
                  ...initialValues,
                  costofreel: e.target.value,
                })
              }
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
            <TextField
              className="w-100 input-style"
              value={initialValues.avgviewofreel}
              size="small"
              label="Avg. views of reel"
              variant="outlined"
              onChange={(e) =>
                setInitialValues({
                  ...initialValues,
                  avgviewofreel: e.target.value,
                })
              }
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
            <TextField
              className="w-100 input-style"
              value={initialValues.costofstory}
              size="small"
              label="Cost of story "
              variant="outlined"
              onChange={(e) =>
                setInitialValues({
                  ...initialValues,
                  costofstory: e.target.value,
                })
              }
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
            <TextField
              className="w-100 input-style"
              value={initialValues.swipeuplink}
              size="small"
              label="Cost of story w/a swipe up link "
              variant="outlined"
              onChange={(e) =>
                setInitialValues({
                  ...initialValues,
                  swipeuplink: e.target.value,
                })
              }
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
            <TextField
              className="w-100 input-style"
              value={initialValues.costofimagepost}
              size="small"
              label="Cost of image post"
              variant="outlined"
              onChange={(e) =>
                setInitialValues({
                  ...initialValues,
                  costofimagepost: e.target.value,
                })
              }
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt-1 mb-3">
            <TextField
              className="w-100 input-style"
              value={initialValues.brandworked}
              size="small"
              label="Brands worked with in past 3 months"
              variant="outlined"
              onChange={(e) =>
                setInitialValues({
                  ...initialValues,
                  brandworked: e.target.value,
                })
              }
            />
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-primary btn-sm ml-0"
          style={{ margin: "0px 0px 10px 0px" }}
        >
          Add
        </button>
      </form>
    </section>
  );
};
