import React, { useEffect, useState } from "react";
import { DefaultUserProfile } from "../../config/config";
import dayjs from "dayjs";
import MaterialSymbol from "../icons/material-symbol";
import * as Util from "../../core/utility";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import useBodyClass from "../../hooks/useBodyClass";

const method: Util.Methods = new Util.Methods();

interface Props {
  chats?: any;
  userData?: {
    user_id: number | string;
    account_id: number | string;
    influencer_id: number | string;
    account_name: string;
    brand_name: string;
    brand_organization: string;
    photo: string;
    logo: string;
    account_type: string;
    gcp_profile_image_url: string;
    logo_url: string; 
  };
  campaignDetail?: any;
  influencer?: any;
  campaign?: any;
  onClick?: () => void;
  onSubmit?: (event: any) => void;
  direct_tab?: boolean;
  influencer_type?: any;
  onClickChatBackBtn?: any;
  isMobile?: boolean;
  chatLoading?: boolean;
  chatSendLoading?: boolean;
}


export const ChatBox: React.FC<Props> = ({ influencer, ...arg }: Props) => {
  const [random] = useState(method.getRandNumber());
  // Ensure chats object exists, provide default empty object
  const chats = arg.chats || {};
  useBodyClass("page-btm-spc-exact")
  return (
    <div className={`pro-inf-chat-container-chat`}>
      {arg.influencer_type == "brand" && !arg.direct_tab && (
        <>
          <section className="influencer-chat-header-chat">
            <section className="pro-detail">
              {/* Influencer Section */}

              {influencer && (
                <>
                  {arg.isMobile && (
                    <span
                      onClick={arg.onClickChatBackBtn}
                      className="chat-back-btn"
                    >
                      <ArrowBackIosRoundedIcon />
                    </span>
                  )}
                  <img
                    className="profile-image-size"
                    src={
                      influencer.gcp_profile_image_url ||
                      influencer.profile_image_url ||
                      DefaultUserProfile.influencer_unknown_default
                    }
                    alt="Influencer Profile"
                  />
                </>
              )}
              {influencer && (
                <section>
                  <span className="influencer-name">
                    {influencer.name || influencer.account_name}
                  </span>
                  <br />
                  <span className="influencer-username">
                    {influencer.username}
                  </span>
                </section>
              )}
              {influencer && (
                <span className="proposal-status">{influencer.status}</span>
              )}
            </section>
          </section>
          <section id={`chat-fire_${random}`} className="chat-view-chat">
            {!arg.chatLoading &&
            <ul className="parent-ul-chat">
              {/* Check if chats is an object and render */}
              {Object.keys(chats).map((date, index) => (
                <li key={`parent_${index}`} className="parent-li-chat">
                  <span className="proposal-status-chat">
                    {dayjs(date).format("DD MMM, YYYY")}
                  </span>
                  <ul className="child-ul-chat">
                    {chats[date]?.map((message: any, childIndex: number) => (
                      <li
                        key={`child_${childIndex}`}
                        className={`mt-3 child-li-chat ${
                          message.influencer_id === arg.userData?.influencer_id
                            ? "to"
                            : "from"
                        }`}
                      >
                        <span>{message.comments}</span>
                        {/* Influencer */}
                        {influencer && (
                          <img
                            className="profile-image-size"
                            src={
                              message.account_id === arg.userData?.account_id
                                ? arg.campaignDetail?.campaign_cover_url
                                : influencer.gcp_profile_image_url ||
                                  influencer.profile_image_url ||
                                  DefaultUserProfile.influencer_unknown_default
                            }
                            alt="Influencer"
                          />
                        )}
                        {/* Campaign */}
                        {arg.campaign && (
                          <img
                            className="profile-image-size"
                            src={
                              message.account_id === arg.userData?.account_id
                                ? arg?.userData?.photo
                                : arg.campaign.campaign_cover_url
                            }
                            alt="Campaign"
                          />
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>}
          </section>
        </>
      )}

      {arg.influencer_type == "brand" && arg.direct_tab && (
        <>
          <section className="influencer-chat-header-chat">
            <section className="pro-detail">
              {/* Influencer Section */}
              {influencer && (
                <>
                  {arg.isMobile && (
                    <span
                      onClick={arg.onClickChatBackBtn}
                      className="chat-back-btn"
                    >
                      <ArrowBackIosRoundedIcon />
                    </span>
                  )}
                  <img
                    className="profile-image-size"
                    src={
                      influencer.gcp_profile_image_url ||
                      influencer.profile_image_url ||
                      influencer.profile_image ||
                      DefaultUserProfile.influencer_unknown_default
                    }
                    alt="Influencer Profile"
                  />
                </>
              )}
              {influencer && (
                <section>
                  <span className="influencer-name">{influencer.name}</span>
                  <br />
                  <span className="influencer-username">
                    {influencer.username}
                  </span>
                </section>
              )}
            </section>
          </section>

          <section id={`chat-fire_${random}`} className="chat-view-chat">
            {!arg.chatLoading && 
            <ul className="parent-ul-chat">
              {/* Check if chats is an object and render */}
              {Object.keys(chats).map((date, index) => (
                <li key={`parent_${index}`} className="parent-li-chat">
                  <span className="proposal-status">
                    {dayjs(date).format("DD MMM, YYYY")}
                  </span>
                  <ul className="child-ul-chat">
                    {chats[date]?.map((message: any, childIndex: number) => (
                      <li
                        key={`child_${childIndex}`}
                        className={`mt-3 child-li-chat ${
                          message.influencer_id === arg.userData?.influencer_id
                            ? "to"
                            : "from"
                        }`}
                      >
                        <span>{message.comments}</span>
                        {/* Influencer */}
                        {influencer && (
                          <img
                            className="profile-image-size"
                            src={
                              message.account_id === arg.userData?.account_id
                                ? arg.userData?.photo || arg.userData?.logo_url ||
                                  DefaultUserProfile.influencer_unknown_default
                                : influencer.gcp_profile_image_url ||
                                  influencer.profile_image_url ||
                                  DefaultUserProfile.influencer_unknown_default
                            }
                            alt="Influencer"
                          />
                        )}
                        {/* Campaign */}
                        {arg.campaign && (
                          <img
                            className="profile-image-size"
                            src={
                              message.account_id === arg.userData?.account_id
                                ? arg?.userData?.photo
                                : arg.campaign.campaign_cover_url
                            }
                            alt="Campaign"
                          />
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>}
          </section>
        </>
      )}

      {arg.influencer_type == "influencer" && !arg.direct_tab && (
        <>
          <section className="influencer-chat-header-chat">
            <section className="pro-detail">
              {arg.campaign && (
                <>
                  {arg.isMobile && (
                    <span
                      onClick={arg.onClickChatBackBtn}
                      className="chat-back-btn"
                    >
                      <ArrowBackIosRoundedIcon />
                    </span>
                  )}
                  <img
                    className="profile-image-size"
                    src={arg.campaign.campaign_cover_url}
                    alt="Campaign Cover"
                  />
                  <section>
                    <span className="influencer-name">
                      {arg.campaign.campaign_title}
                    </span>
                  </section>
                  <span className="proposal-status">{arg.campaign.status}</span>
                </>
              )}
            </section>
          </section>

          <section id={`chat-fire_${random}`} className="chat-view-chat">
          {!arg.chatLoading && 
            <ul className="parent-ul-chat">
              {/* Check if chats is an object and render */}
              {Object.keys(chats).map((date, index) => (
                <li key={`parent_${index}`} className="parent-li-chat">
                  <span className="proposal-status">
                    {dayjs(date).format("DD MMM, YYYY")}
                  </span>
                  <ul className="child-ul-chat">
                    {chats[date]?.map((message: any, childIndex: number) => (
                      <li
                        key={`child_${childIndex}`}
                        className={`mt-3 child-li-chat ${
                          message.influencer_id === arg.userData?.influencer_id
                            ? "to"
                            : "from"
                        }`}
                      >
                        <span>{message.comments}</span>
                        {/* Campaign */}
                        {arg.campaign && (
                          <img
                            className="profile-image-size"
                            src={
                              message.account_id === arg.userData?.account_id
                                ? arg?.userData?.photo
                                : arg.campaign.campaign_cover_url
                            }
                            alt="Campaign"
                          />
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>}
          </section>
        </>
      )}

      {arg.influencer_type == "influencer" && arg.direct_tab && (
        <>
          <section className="influencer-chat-header-chat">
            <section className="pro-detail">
              {influencer && (
                <>
                  <img
                    className="profile-image-size"
                    src={
                      influencer.photo ||
                      DefaultUserProfile.influencer_unknown_default
                    }
                    alt="influencer"
                  />
                  <section>
                    <span className="influencer-name">{influencer.name}</span>
                  </section>
                </>
              )}
            </section>
          </section>

          <section id={`chat-fire_${random}`} className="chat-view-chat">
          {!arg.chatLoading && 
            <ul className="parent-ul-chat">
              {Object.keys(chats).map((date, index) => (
                <li key={`parent_${index}`} className="parent-li-chat">
                  <span className="proposal-status">
                    {dayjs(date).format("DD MMM, YYYY")}
                  </span>
                  <ul className="child-ul-chat">
                    {chats[date]?.map((message: any, childIndex: number) => (
                      <li
                        key={`child_${childIndex}`}
                        className={`mt-3 child-li-chat ${
                          message.influencer_id === arg.userData?.influencer_id
                            ? "to"
                            : "from"
                        }`}
                      >
                        <span>{message.comments}</span>
                        {/* Campaign */}
                        {influencer && (
                          <img
                            className="profile-image-size"
                            src={
                              message.account_id === arg.userData?.account_id
                                ? arg?.userData?.gcp_profile_image_url || DefaultUserProfile.influencer_unknown_default
                                : influencer.photo ||
                                  DefaultUserProfile.influencer_unknown_default
                            }
                            alt="Influencer"
                          />
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>}
          </section>
        </>
      )}

      <section className="conversion-section">
        <form onSubmit={arg.onSubmit}>
          <input type="text" name="message" placeholder="Write message..." />
          <button disabled = {arg.chatSendLoading} type="submit" className="send-btn">
            <MaterialSymbol
              className={`send-icon`}
              iconName="send"
              fontweight="200"
            />
          </button>
        </form>
      </section>
    </div>
  );
};
