import React, { useEffect, useState, useRef } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InstagramIcon from "@mui/icons-material/Instagram";
import SendIcon from "@mui/icons-material/Send";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_SITE_KEY } from "../../../config/config";
import { BASE_URL_UI } from "../../../config/config";

import {
  Autocomplete,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Theme,
  useTheme,
  Tooltip,
} from "@mui/material";
import {
  PublicService,
  SignUpService,
  LocalService,
  UserService,
} from "../../../core/services";
import { FormValidation } from "../../../core/utility";
import { connect } from "react-redux";
import { Loading } from "../../../redux/action";
import HeadingStyle from "../../../components/heading-style/heading-style";
import useBodyClass from "../../../hooks/useBodyClass";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InfluencerProfileCard from "./influencer-profile-card";
import { CaptchaService } from "../../../core/services/captcha.service";
import { DefaultAccountLogo, DefaultUserProfile } from "../../../config/config";
import { LoadingButton } from "@mui/lab";
import "./_influencerSignUp.scss";
import InfluencerContent from '../../../components/signup-right-sec/Influencer-content';
import MaterialSymbol from "../../../components/icons/material-symbol";
import Footer from "../../../components/footer/footer";
import { MuiOtpInput } from "mui-one-time-password-input";
import { MenuPropsAutoCompleteNoCheckBox, MenuPropsSelect } from "../../../core/utility/style";
import { BASE_URL_WEB } from "../../../config/config";

import useConditionalFocus from "../../../hooks/useConditionalFocus";
import YTInfluencerProfileLeft from "../../influencer/profile-view/yt-influencer-profile-left";
import { Helmet } from "react-helmet";
const signupService = new SignUpService();
const captchaService = new CaptchaService();


const localService: LocalService = new LocalService();
const publicService: PublicService = new PublicService();

interface ErrorTypes {
  status: boolean;
  message: string;
}

interface Props {
  loading: (data: any) => void;
}

const Influencer: React.FC<Props> = (props: Props) => {
  const formValidation = new FormValidation();
  const [phoneloading, setPhoneLoading] = useState(false);
  const [emailOtpVerifyloading, setEmailOtpVerifyLoading] = useState(false);
  const [phoneOtpVerifyloading, setPhoneOtpVerifyLoading] = useState(false);
  const [emailloading, setEmailLoading] = useState(false);
  const [igVerifyLoading, setIgVerifyLoading] = useState(false);
  const [ytVerifyLoading, setYtVerifyLoading] = useState(false);
  const theme = useTheme();
  const [selectedLocation, setSelectedLocation] = useState(null);
  //set validate
  const [gender, setGender] = useState("");
  const [genre, setGenre] = useState<string[]>([]);
  const [language, setLanguage] = useState<string[]>([]);
  const [location, setLocation] = useState([]);
  const [genricList, setGenricList] = useState<string[]>([]);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [languageList, setLanguageList] = useState<string[]>([]);
  const [showotpBox, setOtpBox] = useState(false);
  const [disableWhatsappfield, setDisableWhatsAppField] = useState(true);
  const [showEmailotpBox, setEmailOtpBox] = useState(false)
  const [instagramProfile, setInstagramProfile] = useState("");
  const [youtubeProfile, setYoutubeProfile] = useState("");
  const [whatsappnumber, setWhatsAppNumber] = useState("");
  const [showInstaProfileCard, setShowInstaProfileCard] = useState(false);
  const [verifyIgId, setverifyIgId] = useState(false);
  const [verifyYtId, setverifyYtId] = useState(false);
  const [showYtProfileCard, setShowYtProfileCard] = useState(false);
  const [instaCardData, setInstaCardData] = useState<any>({});
  const [ytCardData, setYtCardData] = useState<any>({});
  const [captchaToken, setCaptchaToken] = useState<any>();
  const [disableEmailField, setDisableEmailField] = useState(true);
  const [emailOtpSent, setEmailOtpSent] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [pin, setPin] = useState("");
  const [dd, setDD] = useState("");
  const [mm, setMM] = useState("");
  const [yyyy, setYYYY] = useState("");
  const [isEditButtonDisabled, setIsEditButtonDisabled] = useState(false);
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(true);
  const [timer, setTimer] = useState(60);
  const [isTimerDisabled, setIsTimerDisabled] = useState(true);
  const [showResendEdit, setShowResendEdit] = useState(false);
  const phoneRef = useRef<any>(null);
  const [isEditButtonEmailDisabled, setIsEditButtonEmailDisabled] = useState(false);
  const [isResendButtonEmailDisabled, setIsResendButtonEmailDisabled] = useState(true);
  const [Emailtimer, setEmailTimer] = useState(60);
  const [isEmailTimerDisabled, setIsEmailTimerDisabled] = useState(true);
  const [showEmailResendEdit, setShowEmailResendEdit] = useState(false);
  const emailRef = useRef<any>(null);
  const [passwordChecking, setPasswordChecking] = useState('');
  const [emailOtpError, setEmailOtpError] = useState<boolean>(false);
  const [phoneOtpError, setPhoneOtpError] = useState<boolean>(false);
  //validation
  const [errorWhatsApp, setErrorWhatsApp] = useState<ErrorTypes>();
  const [errorFullName, setErrorFullName] = useState<ErrorTypes>();
  const [errorEmail, setErrorEmail] = useState<ErrorTypes>();
  const [errorGender, setErrorGender] = useState<ErrorTypes>();
  const [errorLocation, setErrorLocation] = useState<ErrorTypes>();
  const [errorGenre, setErrorGenre] = useState<ErrorTypes>();
  const [errorTerms, setErrorTerms] = useState<ErrorTypes>();
  const [errorPassword, setErrorPassword] = useState<ErrorTypes>();
  const [errorIgRegistered, setErrorIgRegistered] = useState<ErrorTypes>();
  const [errorYtRegistered, setErrorYtRegistered] = useState<ErrorTypes>();
  const [errorConfirmPassword, setErrorConfirmPassword] = useState<ErrorTypes>();

  // For Verification----------------------------------------------
  const [verifiedEmailfield, setVerifiedEmailfield] = useState(false);
  const [verifiedWAfield, setVerifiedWAfield] = useState(false);
  //Login with Pin
  const [otpWhatsAppValue, setOtpWhatsAppValue] = React.useState<string>('')
  const [otpEmailValue, setOtpEmailValue] = React.useState<string>('')
  const [otpPINValue, setOtpPINValue] = React.useState<string>('')
  const [influencerId,setInfluencerId] =useState(null)

    // Disable Input Field
  const [disableWhatsappfieldInput, setDisableWhatsAppFieldInput] = useState(false);
  const [disableEmailfieldInput, setDisableEmailfieldInput] = useState(false);

  // field activation
  const [isActiveField, setIsActiveField] = useState({
    name: false,
    email: false,
    mobile: false,
    password: false,
    confirmPassword: false,
    gender: false,
    location: false,
    genre: false,
    language: false,
    terms: false,
    social_media: false,
    pin: false,
    dd: false,
    mm: false,
    yyyy: false,
  });

  const genderRef = useRef<HTMLInputElement>(null);
  // const dobRef = useRef<HTMLInputElement>(null);
  const ddRef = useRef<HTMLInputElement>(null);
  const mmRef = useRef<HTMLInputElement>(null);
  const yyyyRef = useRef<HTMLInputElement>(null);
  const genreRef = useRef<HTMLInputElement>(null);
  const languageRef = useRef<HTMLInputElement>(null);
  const locationRef = useRef<HTMLInputElement>(null);
  const socialRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const pinRef = useRef<HTMLInputElement>(null);

  useConditionalFocus(phoneRef, isActiveField.name);
  useConditionalFocus(emailRef, isActiveField.mobile);
  useConditionalFocus(genderRef, isActiveField.email);
  useConditionalFocus(ddRef, isActiveField.gender);
  useConditionalFocus(mmRef, isActiveField.dd);
  useConditionalFocus(yyyyRef, isActiveField.mm);
  useConditionalFocus(genreRef, isActiveField.yyyy);
  useConditionalFocus(languageRef, isActiveField.genre);
  useConditionalFocus(locationRef, isActiveField.language);
  useConditionalFocus(socialRef, isActiveField.location);
  useConditionalFocus(passwordRef, isActiveField.social_media);
  useConditionalFocus(confirmPasswordRef, isActiveField.password);
  useConditionalFocus(pinRef, isActiveField.confirmPassword);

  const location_val = useLocation();
  let influencer_id: any = null; // Default value if not found
  if (location_val && location_val.state && location_val.state.influencer_id) {
    influencer_id = location_val.state.influencer_id;
  }

  const [errorDate, setErrorDate] = useState({
    dd: { status: true, message: "" },
    mm: { status: true, message: "" },
    yyyy: { status: true, message: "" },
  });
  const [errorSocialMediaHandle, setErrorSocialMediaHandle] = useState<ErrorTypes>();
  const [errorPin, setErrorPin] = useState<ErrorTypes>();
  const [errorLanguage, setErrorLanguage] = useState<ErrorTypes>();

  //redux
  const navigate = useNavigate();

  useEffect(() => {
    props.loading(true);

    //get Geo (Location)
    async function getLocations() {
      try {
        props.loading(true);
        const res = await publicService.getLocation();
        if (res.data.status) {
          if (res?.data?.rows[0].state === 'India' && res?.data?.rows[0].city === 'India' ){
            res?.data?.rows.shift();
          }
          setLocation(res?.data?.rows);
        } else {
          setLocation([]);
        }
      } catch (error) {
        setLocation([]);
        console.log(`get Geo (Location) error :: ${JSON.stringify(error)}`);
      } finally {
        props.loading(false);
      }
    }
    getLocations();
    PublicService.getGenre()
      .then((res) => {
        setGenricList(res?.data?.rows);
        props.loading(false);
      })
      .catch((error) => {
        props.loading(false);
        throw new Error(`getGenre error :: ${JSON.stringify(error)}`);
      });
    async function getLanguage() {
      try {
        props.loading(true);
        const res = await publicService.getLanguage();
        if (res.data.status) {
          let languageList = res?.data?.rows;

          // Rearrange the language list to show English and Hindi first
          const english = languageList.find((lang:any) => lang.language_name === "English");
          const hindi = languageList.find((lang:any) => lang.language_name === "Hindi");

          // Remove English and Hindi from the original list
          languageList = languageList.filter((lang:any) => lang.language_name !== "English" && lang.language_name !== "Hindi");
          
          languageList.sort((a:any, b:any) => a.language_name.localeCompare(b.language_name));
          
          // Add English and Hindi to the beginning of the list
          if (hindi) languageList.unshift(hindi);
          if (english) languageList.unshift(english);

          setLanguageList(languageList);
          props.loading(false);
        } else {
          setLanguageList([]);
        }
      } catch (error) {
        props.loading(false);
        throw new Error(`getLanguage error :: ${JSON.stringify(error)}`);
      }
    }

    getLanguage();
   
    
  }, []);

  useEffect(() => {
    let countdown: any;
    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setTimer(0);
      setIsResendButtonDisabled(false);
    }

    return () => clearInterval(countdown);
  }, [timer]);

  useEffect(() => {
    let countdown: any;
    if (Emailtimer > 0) {
      countdown = setInterval(() => {
        setEmailTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setEmailTimer(0);
      setIsResendButtonEmailDisabled(false);
    }

    return () => clearInterval(countdown);
  }, [Emailtimer]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleLocationChange = (event:any, value:any) => {
    setSelectedLocation(value);
    // You can perform any action here based on the selected location
    if(influencer_id){
      setIsActiveField((prevIsActiveField) => ({
        ...prevIsActiveField,
        social_media:false,
      }));   
    }
  };

  const handleEmail = async (e: any) => {
    const value = e?.target?.value ?? e;
    setEmail(e.target.value)
    let isValid: { status: boolean; message: string } =
      formValidation.GetEmailControlIsValid(value);
    setErrorEmail(isValid);
    if (isValid.status) {
      const response = await publicService.checkEmailIsRegister({
        email: value,
        user_type: "influencer",
      });
      if (response.data.status.message.length === 0) {
        setDisableEmailField(false);
      }
      else {
        setVerifiedEmailfield(false);
        setDisableEmailField(true)
      }

      if (response) {
        isValid = response.data.status;
        setErrorEmail(isValid);
      }
    }else{
      setDisableEmailField(true);
    }

    return isValid;
  };
  const handleEmailOtp = (otp: any) => {
    try{
    if (otp.length === 4) {
      setEmailOtpVerifyLoading(true);
      PublicService.emailVerifyOtp({ email: email, otp: otp })
        .then((res) => {
          if (res?.data?.rows?.status) {
            setEmailOtpBox(false);
            setDisableEmailField(true);
            setVerifiedEmailfield(true);
            setVerifiedEmail(true);
            setShowEmailResendEdit(false);
            setEmailOtpError(false);
            setIsActiveField({
              ...isActiveField,
              email: true,
            })
            localService.toastify(res?.data?.rows?.message);
          } else {
            setEmailOtpError(true);
            // localService.toastify(res?.data?.rows?.message);
          }
        })
        .catch((error) => {
          setEmailOtpVerifyLoading(false);
          throw new Error('VerifyOtp Error ::: ' + error);
        });
    }}catch(error){
      console.log('handleEmailOtp Error',error);
    }finally{
      setEmailOtpVerifyLoading(false);
    }
  };

  const handleEmailSendOTP = async () => {
    try {
      if (!email) {
        setErrorEmail({ status: true, message: "Email is required." });
        return;
      }
      // Regular expression for email validation
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(email)) {
        setErrorEmail({
          status: true,
          message: "Invalid email format.",
        });
        return;
      }
      if (!errorEmail?.status) {
        setErrorEmail(formValidation.GetEmailControlIsValid(email));
        return;
      }

      setEmailLoading(true)
      // Send email OTP
      const response = await PublicService.emailSendOtp({ email: email });
      setDisableEmailfieldInput(true)
      setEmailLoading(false);
      if (response?.data?.rows?.status) {
        setShowEmailResendEdit(true);
        setIsResendButtonEmailDisabled(true);
        setEmailTimer(30);
        setIsTimerDisabled(true);
        setIsEditButtonEmailDisabled(true);
        setEmailOtpBox(true);
        setDisableEmailField(true)
        localService.toastify(response?.data?.rows?.message);
        
      } else {
        setErrorEmail(response?.data?.rows?.message);
        localService.toastify(response?.data?.rows?.message);
        setDisableEmailfieldInput(false)
      }

    } catch (error) {
      console.warn(`Email OTP Response Error :: ${error}`);
      setDisableEmailfieldInput(false)

    }
  };

  const handlePin = (pin: any) => {
    setPin(pin);
    setIsActiveField({
      ...isActiveField,
      pin: true,
    })
  };
  useEffect(() => {
    returnInfluencerId()
  }, []);

  const returnInfluencerId = async () => {
    if (influencer_id) {
      const res = await signupService.createInfluencerAccountWAFlow({
        data: {
          influencer_id: influencer_id,
        },
      });
      if (res) {
        if (res?.data?.influencer_data.primary_email.length > 0) {
          setEmail(res.data.influencer_data.primary_email);
        }
        setInfluencerId(influencer_id)
        if (res?.data?.influencer_data.primary_phone) {
          setWhatsAppNumber(res?.data?.influencer_data.primary_phone);
          setDisableWhatsAppField(false);
          setVerifiedWAfield(true);
          setDisableWhatsAppFieldInput(true)
        }

        if (res?.data?.ig_username) {
          setInstagramProfile(res?.data?.ig_username)
          setShowInstaProfileCard(true)
          setverifyIgId(true)
        }

        if (res?.data.yt_channel_id) {
          setYoutubeProfile(res?.data.yt_channel_id)
          setShowYtProfileCard(true)
          setverifyYtId(true)
        }
        setName(res?.data?.influencer_data.name)


      }
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const data = Object.fromEntries(form);
    const storeData: any = { ...data };
    let isValidConfirmPassword;
    let {
      full_name,
      whatsapp_number,
      email_id,
      gender,
      genre,
      location_id,
      instagram,
      youtube,
      terms,
      language,
      password,
      confirm_password
    } = storeData;
    storeData["ig_influencer_profile_data"] = instaCardData;
    storeData["yt_influencer_profile_data"] = ytCardData;
    storeData["influencer_id"] = influencer_id;
    try {
      setErrorWhatsApp(
        formValidation.GetMobileControlIsValid(`${whatsapp_number}`)
      );
      setErrorEmail(formValidation.GetEmailControlIsValid(`${email_id}`));
      setErrorFullName(formValidation.GetNameValidate(`${full_name}`));
      setErrorGender(formValidation.GetNameValidate(`${gender}`));
      setErrorLocation(formValidation.getSplitValidation(`${location_id}`));
      setErrorPassword(formValidation.GetPasswordValidate(password));
      setErrorGenre(formValidation.getSplitValidation(`${genre}`));
      setErrorPin(formValidation.GetPinIsValid(`${pin}`));
      setErrorLanguage(formValidation.getSplitValidation(`${language}`));
      const isValidPin = formValidation.GetPinIsValid(`${pin}`).status
      const isValidEmail = formValidation.GetEmailControlIsValid(email_id).status
      const isValidName = formValidation.GetNameValidate(`${full_name}`).status;
      const isValidGender = formValidation.GetNameValidate(`${gender}`).status;
      const isValidPassword = formValidation.GetPasswordValidate(password).status
      if(confirm_password == ''){
        setErrorConfirmPassword({
          status:false,
          message:'This field is required'
        })
        isValidConfirmPassword = false;
      }else{
        if(password == confirm_password){
          isValidConfirmPassword = true;
          setErrorConfirmPassword({
            status:true,
            message:''
          })
        }else{
          setErrorConfirmPassword({
            status:false,
            message:'Password does not match'
          })
          isValidConfirmPassword = false;
        }
      }

      const isValidLocation = formValidation.getSplitValidation(`${location_id}`).status;
      const isValidGenre = formValidation.getSplitValidation(`${genre}`).status;
      const isValidLanguage = formValidation.getSplitValidation(`${language}`).status;

      // setErrorTerms()
      if (location_id) {
        const getLocation_id: any = location.find(
          (res: any) =>
            `${res.city}, ${res.state}, ${res.country}` === location_id
        );
        storeData.location_id = getLocation_id.location_id;
      }

      if (instagram || youtube) {
      } else {
        setErrorSocialMediaHandle({
          message: "Atleast One Social Media Handle is Required.",
          status: true,
        });
      }

      if (terms) {
      } else {
        setErrorTerms({ message: "Please Accept", status: true });
      }

      if (!verifiedWAfield && whatsappnumber) {
        localService.toastify("WhatsApp No. Not Verified.", "error", 1000);
      }
      if (!verifiedEmailfield && email) {
        localService.toastify("Email Id. Not Verified.", "error", 1000);
      }

      const verifyCaptcha = async () => {
        try {
          const res = await captchaService.verifyCaptcha({ token: captchaToken });
          return res?.data?.rows?.message === "Captcha verified successfully!";
        } catch (error) {
          throw new Error(`verifyCaptcha Error :: ${JSON.stringify(error)}`);
        }
      };


      if (!verifyIgId && !verifyYtId) {
        localService.toastify('please Verify handlename ', 'info', 1000);
      }

      if (
        isValidName &&
        whatsapp_number &&
        isValidEmail &&
        isValidGender &&
        isValidGenre &&
        isValidLocation &&
        isValidLanguage &&
        (verifyIgId || verifyYtId) &&
        terms &&
        verifiedWAfield &&
        verifiedEmailfield &&
        isValidPin &&
        isValidPassword &&
        isValidConfirmPassword
      ) {
        storeData["dob"] = `${storeData.yyyy}-${storeData.mm}-${storeData.dd}`
        storeData["pin"] = pin
        storeData["mobile_verified"] = "Yes";
        storeData["email_verified"] = "No";
        storeData["logo"] = DefaultAccountLogo.influencer;
        storeData["photo"] =
          (gender + "").toLocaleLowerCase() === "male"
            ? DefaultUserProfile.influencer_male_default
            : (gender + "").toLocaleLowerCase() === "female"
              ? DefaultUserProfile.influencer_female_default
              : DefaultUserProfile.influencer_unknown_default;
        delete storeData.yyyy;
        delete storeData.mm;
        delete storeData.dd;

        //call verifycaptcha
        props.loading(true);
        if (await verifyCaptcha()) {
          try {
            const res = await signupService.createInfluencerAccount(storeData);
            if (res.data.status.status) {
              // let date = new Date();
              localService.setStorageItem("login", `${email_id}`)
              localService.setStorageItem("password", `${password}`)
              navigate(`/welcome/influencer?id=${res.data.status?.user_id}`);
            }
            props.loading(false);

          }
          catch (error) {
            props.loading(false);
            console.warn("Captcha Verification (Influencer) Error in ", error)
          }
        }
        else {
          props.loading(false);

        }
      }
    } catch (error) {
      props.loading(false);
      throw new Error(
        `Influencer Create Account function error :: ${JSON.stringify(error)}`
      );
    }
  };
  const [verifiedfield, setVerifiedfield] = useState(false)
  const [verifiedEmail, setVerifiedEmail] = useState(false)
  const handleWhatsAppOtp = (otp: any) => {
    try{
    if (otp.length === 4) {
      setPhoneOtpVerifyLoading(true);
      PublicService.verifyOtp({ mobile: +whatsappnumber, otp: otp })
        .then((res) => {
          if (res?.data?.rows?.status) {
            setOtpBox(false);
            setVerifiedWAfield(true);
            setDisableWhatsAppField(true);
            setShowResendEdit(false);
            localService.toastify(res?.data?.rows?.message, "success", 1000);
            setVerifiedfield(true)
            setPhoneOtpError(false);
            setIsActiveField({
              ...isActiveField,
              mobile: true,
            })
            if(influencer_id && email.length){

              setDisableEmailfieldInput(true);
              setDisableEmailField(false);
          
            }
          } else {
            setPhoneOtpError(true);
            // localService.toastify(res?.data?.rows?.message, "error", 1000);
          }
        })
        .catch((error) => {
          setPhoneOtpVerifyLoading(false);
          throw new Error("VerifyOtp Error ::: " + error);
        });
    }}catch(error){
      console.log('handleWhatsAppOtp error',error);
    }finally{
      setPhoneOtpVerifyLoading(false);
    }
  };

  const handleSendOtpButton = () => {
    let isValid: any = formValidation.GetMobileControlIsValid(whatsappnumber);
    setDisableWhatsAppFieldInput(true)

    if (isValid.status) {
      setErrorWhatsApp(isValid);
      // setVerifiedWAfield(true)
      PublicService.sendOtp({ mobile: +whatsappnumber })
        .then((res: any) => {
          if (res?.data?.rows?.status) {
            setShowResendEdit(true);
            setIsResendButtonDisabled(true);
            setTimer(30);
            setIsTimerDisabled(true);
            setIsEditButtonDisabled(true);
            setOtpBox(res?.data?.rows?.status);
            setDisableWhatsAppField(res?.data?.rows?.status);
            // setVerifiedWAfield(res?.data?.rows?.status);
            localService.toastify(res?.data?.rows?.message, "success", 1000);
          } else {
            localService.toastify(res?.data?.rows?.message, "error", 1000);
            setVerifiedWAfield(false);
            setDisableWhatsAppFieldInput(false)

          }
          setPhoneLoading(false);
        })
        .catch((error: any) => {
          setPhoneLoading(false);
          setVerifiedWAfield(false);
          setDisableWhatsAppFieldInput(false)
          console.warn(`Otp Response Error :: ${error}`);
        });

    } else {
      setErrorWhatsApp(isValid);
    }
  };

  const handleWhatsApp = (e: any) => {
    try {
      if (e.target.value.length < 10) {
        setWhatsAppNumber((e.target.value));
        setErrorWhatsApp(formValidation.GetMobileControlIsValid(e.target.value));
        setDisableWhatsAppField(true)
      }
      else if (e.target.value.length == 10 && formValidation.GetMobileControlIsValid(e.target.value).status == true) {
        setWhatsAppNumber((e.target.value));
        setErrorWhatsApp(formValidation.GetMobileControlIsValid(e.target.value));
        setDisableWhatsAppField(false)
      }
      else if(e.target.value.length > 10) {
        setWhatsAppNumber(e.target.value.slice(0, 10));
        setErrorWhatsApp(formValidation.GetMobileControlIsValid(e.target.value.slice(0, 10)));

      }
    } catch (error) {
      throw new Error(`handleWhatsApp error :: ${error}`);
    }
  };

  const handleFullName = (e: any) => {
    setErrorFullName(formValidation.GetNameValidate(e.target.value));
    if(!isActiveField.name){
      setIsActiveField({
        ...isActiveField,
        name:formValidation.GetNameValidate(e.target.value).status
      }) 
    }
  };

  const handlePassword = (e: any) => {
    const {
      target: { value },
    } = e;
    setPasswordChecking(value);
    setErrorPassword(formValidation.GetPasswordValidate(value));
    if(!isActiveField.password){
      setIsActiveField({
        ...isActiveField,
        password: formValidation.GetPasswordValidate(value).status
      })
    }
  };

  const handleConfirmPassword = (e: any) => {
    const {
      target: { value },
    } = e;
    if(value !== passwordChecking) {
      setErrorConfirmPassword({status: false, message: 'Password does not match'});
    }else{
      setErrorConfirmPassword({status: true, message: ''});
      setIsActiveField({
        ...isActiveField,
        confirmPassword: true
      })
    }
  };


  //Element Heights

  const handleGenre = (event: any) => {
    const {
      target: { value },
    } = event;
    if (genre.length !== 3) {
      setGenre(value);
    } else {
      if (value.length === 2) {
        setGenre(value);
      }
    }
  };

  const handleGenreOnBlur = (event: any) => {
    if(genre.length > 0){
      setIsActiveField({
        ...isActiveField,
        genre: true
      }) 
    }
  };

  const handleLanguage = (event: any) => {
    const {
      target: { value },
    } = event;
    if (language.length !== 4) {
      setLanguage(value);
    } else {
      if (value.length === 3) {
        setLanguage(value);
      }
    }

    setIsActiveField({
      ...isActiveField,
      language: true
    })
  };

  const handleLanguageOnBlur = (event: any) => {
    if(language.length > 0){
      setIsActiveField({
        ...isActiveField,
        language: true
      })
    }  
  };


  // Extra component
  function getStyles(name: string, personName: string[], theme: Theme) {
    try {
      return {
        fontWeight:
          personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
      };
    } catch (error) { }
  }
  useBodyClass("common-signup-page");

  const handleInstagramProfile = async (e: any) => {
    try {
      if (instagramProfile.length !== 0) {
        setIgVerifyLoading(true);
        const checkInDb = await publicService.checkInfluencerHandleInDB({
          platform: "Instagram",
          handle_name: instagramProfile,
        });

        if (checkInDb.data.rows.length === 0) {
          setErrorIgRegistered({
            status: false,
            message: ''
          })
          const verify = await UserService.getInstaProfileDetails(instagramProfile, "sm_api")
          setverifyIgId(true)
          if (verify.status === 1) {
            setShowInstaProfileCard(true);
            setInstaCardData(verify.data);
            setIsActiveField({
              ...isActiveField,
              social_media: true
            })
          } else {
            setShowInstaProfileCard(false);
            localService.toastify(`Unable to verify handle name`, "info", 1000);
          }
        }
        else {
          setErrorIgRegistered({
            status: true,
            message: 'This username already exists. Kindly login or contact us.'
          })
        }
        setIgVerifyLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleYoutubeProfile = async (e: any) => {
    try {
      if (youtubeProfile.length !== 0) {
        setYtVerifyLoading(true);
        const checkInDb = await publicService.checkInfluencerHandleInDB({
          platform: "Youtube",
          handle_name: youtubeProfile,
        });

        if (checkInDb.data.rows.length === 0) {
          setErrorYtRegistered({
            status: false,
            message: ''
          })
          setverifyYtId(true)
          const verify = await UserService.getYtProfileDetails(youtubeProfile, "sm_api")
          if (verify.status === 1) {
            setYtCardData(verify.data);
            setShowYtProfileCard(true);
            setIsActiveField({
              ...isActiveField,
              social_media: true
            })
          }
          else {
            setShowYtProfileCard(false);
            setYtCardData({});
            localService.toastify("Unable to verify channel name");
          }
        }
        else {
          setErrorYtRegistered({
            status: true,
            message: 'This username already exists. Kindly login or contact us.'
          })
        }
        setYtVerifyLoading(false);
      }
    }
    catch (err) {
      console.log(err);
    }
  };

  const handleChangePhone = () => {
    setVerifiedWAfield(false);
    setDisableWhatsAppFieldInput(false);
    setOtpBox(false);
    setShowResendEdit(false);
    if (phoneRef.current) {
      phoneRef.current.focus();
    }
  }

  const handleChangeEmail = () => {
    setVerifiedEmailfield(false);
    setEmailOtpBox(false);
    setShowEmailResendEdit(false);
    setDisableEmailfieldInput(false)
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }

      
    const handlePINChange = (newValue: string) => {
      const filteredValue = newValue.replace(/\D/g, '');
      setOtpPINValue(filteredValue);
      setPin(filteredValue);
    }
    const handleWhatsAppChange = (newValue: string) => {
      const filteredValue = newValue.replace(/\D/g, ''); // Replace any non-digit character
      setOtpWhatsAppValue(filteredValue);
    }
    const handleEmailChange = (newValue: string) => {
      const filteredValue = newValue.replace(/\D/g, '');
      setOtpEmailValue(filteredValue)
    }
    // Function to check if a value is numeric
    const matchIsNumeric = (text: any) => {
        return !isNaN(Number(text));
    };

    // Validation function for MuiOtpInput
    const validateChar = (value: string) => {
        // return matchIsNumeric(value);
        return /^\d*$/.test(value);
    };

  return (
    <>
      {
        String(BASE_URL_UI).includes("clanconnect.ai") &&
        <Helmet>
          <link rel="canonical" href={`${BASE_URL_UI}/signup/influencer`} />
        </Helmet>
      }
      <div className="_influencer-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <p className="_influencer-login-btn">
                Already have an account?{" "}
                <span>
                  <NavLink
                    to="/login"
                    className="_influencer-signup-navlink"
                  >
                    Login
                  </NavLink>
                </span>
              </p>
              <div className="_influencer-signup">
                <div className="d-flex flex-wrap">
                  <div className="d-flex">
                    <NavLink to="/" className="__navLink __backArrow_icon">
                      <ArrowBackIcon className="_icon" />
                    </NavLink>
                    <HeadingStyle title="Influencer Signup" />
                  </div>

                  <span className="mandatory-msg ms-auto">
                    All fields are mandatory
                  </span>
                </div>
                <form onSubmit={handleSubmit} className="__signup_form">
                  <div className="row">
                    <div className="col-12 mt-1 mb-3">
                      <TextField
                        className="w-100 input-style input-common"
                        size="small"
                        autoFocus
                        id="full_name_id"
                        onBlur={handleFullName}
                        name="full_name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Your Full Name"
                        label="Full Name"
                        variant="outlined"
                      />
                      <span className="error">{errorFullName?.message}</span>
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12  mb-3">
                      <div className="__wa_column mt-1">
                        <TextField
                          className="w-100 input-style input-common"
                          disabled={!isActiveField.name}
                          type="tel"
                          size="small"
                          color="primary"
                          InputProps={{
                            readOnly: disableWhatsappfieldInput,
                            inputMode: 'numeric'
                          }}
                          inputRef={phoneRef}
                          onChange={handleWhatsApp}
                          name="whatsapp_number"
                          value={whatsappnumber}
                          id="whatsapp_number_id"
                          label="WhatsApp No."
                          variant="outlined"
                        
                        />
                        {!verifiedfield &&
                        <LoadingButton
                              className={`__button __button-influender ${disableWhatsappfield ? "btn-disabled" : ""}`}
                          loading={phoneloading}
                          loadingPosition="start"
                          variant="contained"
                          startIcon={<WhatsAppIcon />}
                          color="success"
                          disabled={disableWhatsappfield}
                          onClick={handleSendOtpButton}
                        >
                          Send OTP
                        </LoadingButton>
                        }
                        {verifiedfield && 
                          <Tooltip className="icon-verified" title="verified" arrow>
                            <IconButton size="small" className="p-0">
                              <MaterialSymbol iconName="verified" fontweight="200" />
                            </IconButton>
                          </Tooltip>
                        }
                        <span className="error">{errorWhatsApp?.message}</span>
                        {showResendEdit &&
                          <div className="resend-otp d-flex align-items-center w-100 mb-2">
                            {isEditButtonDisabled && <p className="change-phone fs-12 btn btn-text-link me-auto my-0" onClick={handleChangePhone}>Change Phone</p>}
                            <div className="resend-otp-container d-flex">
                              <button
                                className="otp-btn fs-12 btn btn-text-link"
                                onClick={handleSendOtpButton}
                                type="button"
                                disabled={isResendButtonDisabled}
                              >
                                Resend OTP
                              </button>
                              {isTimerDisabled && <p className="otp-time fs-12 my-0">&nbsp; 00:{timer < 10 ? `0${timer}` : timer}</p>}
                            </div>

                          </div>
                        }
                        {/* {showotpBox && <OtpField handleOtp={handleWhatsAppOtp} />} */}
                          {showotpBox && 
                          <div className="mobile-verification">
                          <MuiOtpInput
                            className="otp-fields"
                            value={otpWhatsAppValue}
                            onChange={handleWhatsAppChange}
                            length={4}
                            autoFocus
                            TextFieldsProps={{ size: 'small', placeholder: '0', type:"tel"  }}
                            validateChar={validateChar} 
                            />
                            
                            <LoadingButton
                            className="verify-button"
                            loading={phoneOtpVerifyloading}
                            variant="contained"
                            color="success"
                            disabled={otpWhatsAppValue.length == 4 ? false : true}
                            onClick={()=>handleWhatsAppOtp(otpWhatsAppValue)}
                          >
                            Verify
                          </LoadingButton>
                          </div>
                            }
                          {phoneOtpError && <span className="error">Wrong One Time Password (OTP)</span>}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                      <div className="__wa_column mt-1">
                        <TextField
                          className={`w-100 input-style input-common ${verifiedEmail ? "verified-email": ""}`}
                          disabled={!isActiveField.mobile}
                          size="small"
                          id="email_id"
                          onChange={handleEmail}
                          name="email_id"
                          label="Your Email Id"
                          variant="outlined"
                          inputRef={emailRef}
                          value={email}
                          InputProps={{ readOnly: disableEmailfieldInput }}
                        />
                        {!verifiedEmail &&
                        <LoadingButton
                              className={`__button ${disableEmailField ? "btn-disabled" : ""}`}
                          color="success"
                          loading={emailloading}
                          loadingPosition="start"
                          variant="contained"
                          startIcon={<SendIcon />}
                          onClick={handleEmailSendOTP}
                          disabled={disableEmailField}
                        >
                          {emailOtpSent ? "OTP Sent" : "Send OTP"}
                        </LoadingButton>
                      }
                        {verifiedEmail &&
                          <Tooltip className="icon-verified" title="verified" arrow>
                            <IconButton size="small" className="p-0">
                              <MaterialSymbol iconName="verified" fontweight="200" />
                            </IconButton>
                          </Tooltip>
                        }
                        <span className="error">{errorEmail?.message}</span>
                        {showEmailResendEdit &&
                          <div className="resend-otp d-flex align-items-center w-100 mb-2">
                            {isEditButtonEmailDisabled && <p className="change-phone fs-12 btn btn-text-link me-auto my-0" onClick={handleChangeEmail}>Change Email</p>}
                            <div className="resend-otp-container d-flex">
                              <button
                                className="otp-btn fs-12 btn btn-text-link"
                                onClick={handleEmailSendOTP}
                                type="button"
                                disabled={isResendButtonEmailDisabled}
                              >
                                Resend OTP
                              </button>
                              {isEmailTimerDisabled && <p className="otp-time fs-12 my-0">&nbsp; 00:{Emailtimer < 10 ? `0${Emailtimer}` : Emailtimer}</p>}
                            </div>

                          </div>
                        }
                        {/* {showEmailotpBox && <OtpField handleOtp={handleEmailOtp} />} */}
                        {/* {showEmailotpBox && <MuiOtpInput
                          value={otpEmailValue}
                          onChange={handleEmailChange}
                          onComplete={handleEmailOtp}
                          length={4}
                          autoFocus
                          validateChar={validateChar}
                          TextFieldsProps={{ size: 'small', placeholder: '0', type:"tel"  }} 
                          />} */}
                          {showEmailotpBox && 
                          <div className="mobile-verification">
                          <MuiOtpInput
                            className="otp-fields"
                            value={otpEmailValue}
                            onChange={handleEmailChange}
                            length={4}
                            autoFocus
                            TextFieldsProps={{ size: 'small', placeholder: '0', type:"tel"  }}
                            validateChar={validateChar} 
                            />
                            
                            <LoadingButton
                            className="verify-button"
                            loading={emailOtpVerifyloading}
                            variant="contained"
                            color="success"
                            disabled={otpEmailValue.length == 4 ? false : true}
                            onClick={()=>handleEmailOtp(otpEmailValue)}
                          >
                            Verify
                          </LoadingButton>
                          </div>
                            }
                          {emailOtpError && <span className="error">Wrong One Time Password (OTP)</span>}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-1 mb-3">
                      <FormControl fullWidth size="small" className="input-style input-common">
                        <InputLabel id="demo-simple-select-label">
                          Gender
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="gender_id"
                          disabled={!isActiveField.email}
                          value={gender}
                          inputRef={genderRef}
                          label="Gender"
                          name="gender"
                          onChange={(e) => {
                            setGender(e.target.value)
                            setIsActiveField({
                              ...isActiveField,
                              gender: true,
                            })
                          }}
                          MenuProps={MenuPropsSelect}
                        >
                          <MenuItem value="male">Male</MenuItem>
                          <MenuItem value="female">Female</MenuItem>
                          <MenuItem value="other">Other</MenuItem>
                        </Select>
                      </FormControl>
                      <span className="error">{errorGender?.message}</span>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-1 mb-3 d-flex _signup_dob_section d-flex flex-column">
                      <div className="d-flex">
                      <TextField
                        className="w-100 _dobinput input-style input-common"
                        name="dd"
                        disabled={!isActiveField.gender}
                        error={!errorDate.dd.status}
                        // helperText={!errorDate.dd.message}
                        inputRef={ddRef}
                        value={dd}
                        type="number"
                        onChange={(e) => {
                          const {
                            target: { value },
                          } = e;

                          if (/^\d{0,2}$/.test(value)) {
                            setDD(value);

                            setErrorDate((previous) => ({
                              ...previous,
                              dd: formValidation.IsValidDay(value),
                            }));
                          }
                        }}
                        onBlur={(e) => {
                          let formattedValue = e.target.value;
                          if (formattedValue.length === 1 && formattedValue !== '0') {
                            formattedValue = `0${formattedValue}`;
                            setDD(formattedValue);

                            setErrorDate((previous) => ({
                              ...previous,
                              dd: formValidation.IsValidDay(formattedValue),
                            }));
                          }

                          if(!isActiveField.dd){
                            setIsActiveField({
                              ...isActiveField,
                              dd: formValidation.IsValidDay(formattedValue).status
                            })
                          }
                        }}
                        inputMode="numeric"
                        size="small"
                        id="dd_id"
                        label="DD"
                        variant="outlined"
                      />
                      <span className="_influencer_dob_slash ">/</span>
                      <TextField
                        className="w-100 _dobinput input-style input-common"
                        name="mm"
                        disabled={!isActiveField.dd}
                        error={!errorDate.mm.status}
                        inputRef={mmRef}
                        // helperText={errorDate.mm.message}
                        value={mm}
                        onChange={(e) => {
                          const {
                            target: { value },
                          } = e;
                          if (/^\d{0,2}$/.test(value)) {
                            setMM(value);

                            setErrorDate((previous) => ({
                              ...previous,
                              mm: formValidation.IsValidMonth(value),
                            }));
                          }
                        }}
                        onBlur={(e) => {
                          let formattedValue = e.target.value;
                          if (formattedValue.length === 1 && formattedValue !== '0') {
                            formattedValue = `0${formattedValue}`;
                            setMM(formattedValue);

                            setErrorDate((previous) => ({
                              ...previous,
                              mm: formValidation.IsValidMonth(formattedValue),
                            }));
                          }

                          if(!isActiveField.mm){
                            setIsActiveField({
                              ...isActiveField,
                              mm: formValidation.IsValidMonth(formattedValue).status
                            })
                          }
                        }}
                        type="number"
                        size="small"
                        id="mm_id"
                        label="MM"
                        variant="outlined"
                      />
                      <span className="_influencer_dob_slash">/</span>
                      <TextField
                        className="w-100 _dobinput input-style input-common"
                        name="yyyy"
                        disabled={!isActiveField.mm}
                        error={!errorDate.yyyy.status}
                        inputRef={yyyyRef}
                        onBlur={() => {
                          setErrorDate((e) => ({
                            ...e,
                            yyyy: formValidation.IsvalidYear(yyyy),
                          }));

                          if(!isActiveField.yyyy){
                            setIsActiveField({
                              ...isActiveField,
                              yyyy: formValidation.IsvalidYear(yyyy).status
                            })
                          }
                        }}
                        value={yyyy}
                        onChange={(e) => {
                          const {
                            target: { value },
                          } = e;
                          if (value.length <= 4) {
                            setYYYY(value);
                            setIsActiveField((prevIsActiveField) => ({
                              ...prevIsActiveField,
                              dob: formValidation.IsvalidYear(value).status,
                            }));
                          } else {
                            setYYYY(yyyy);
                          }
                        }}
                        type="number"
                        size="small"
                        id="yyyy_id"
                        label="YYYY"
                        variant="outlined"
                      />
                      </div>
                      <div className="d-flex flex-column">
                        {errorDate.dd.message && <span className="error fs-12">{errorDate.dd.message}</span>}
                      {errorDate.mm.message && <span className="error fs-12">{errorDate.mm.message}</span>}
                        {errorDate.yyyy.message && <span className="error fs-12">{errorDate.yyyy.message}</span>}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mt-1 mb-3">
                      <FormControl className="w-100 input-style input-common" size="small">
                        <InputLabel id="demo-multiple-name-label">
                          Genre (Max. 3)
                        </InputLabel>
                        <Select
                          className="_genre_option"
                          labelId="demo-multiple-name-label"
                          id="Genre_id"
                          disabled={!isActiveField.yyyy}
                          multiple
                          value={genre}
                          inputRef={genreRef}
                          onBlur={handleGenreOnBlur}
                          onChange={handleGenre}
                          input={<OutlinedInput label="Genre (Max. 3)" />}
                          MenuProps={MenuPropsSelect}
                          name="genre"
                        >
                          {genricList ? (
                            genricList?.map((res: any) => (
                              <MenuItem
                                key={res.category_id}
                                value={res.category_id}
                                style={getStyles(res.category, genre, theme)}
                              >
                                {res.category}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem
                              key={1}
                              value={1}
                              style={getStyles("No Data", genre, theme)}
                            >
                              {"No Data"}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                      <span className="error">{errorGenre?.message}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mt-1 mb-3">
                      <FormControl className="w-100 input-style input-common" size="small">
                        <InputLabel id="demo-multiple-name-label">
                          Language (Max. 4)
                        </InputLabel>
                        <Select
                          className="_genre_option"
                          labelId="demo-multiple-name-label"
                          id="Language_id"
                          disabled={!isActiveField.genre}
                          multiple
                          value={language}
                          inputRef={languageRef}
                          onBlur={handleLanguageOnBlur}
                          onChange={handleLanguage}
                          input={<OutlinedInput label="Language (Max. 3)" />}
                          MenuProps={MenuPropsSelect}
                          name="language"
                        >
                          {languageList ? (
                            languageList?.map((res: any) => (
                              <MenuItem
                                key={res.id}
                                value={res.id}
                              >
                                {`${res.language_name} (${res.language_original_name})`}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem
                              key={1}
                              value={1}
                            >
                              {"No Data"}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                      <span className="error">{errorLanguage?.message}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mt-1 mb-3">
                      <Autocomplete
                        className="inputFieldSp input-common"
                        size="small"
                        disablePortal
                        disabled={!isActiveField.language}
                        value={selectedLocation}
                        id="combo-box-demo"
                        onChange={handleLocationChange}
                        options={
                          location
                            ? location?.map(
                              (res: {
                                id: number;
                                city: string;
                                state: string;
                                country: string;
                              }) => {
                                return `${res.city}, ${res.state}, ${res.country}`;
                              }
                            )
                            : ["No Data"]
                        }
                        ListboxProps={MenuPropsAutoCompleteNoCheckBox.PaperProps}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              inputRef={locationRef}
                              name="location_id"   
                              onBlur={() => {
                                if(!isActiveField.location) {
                                setIsActiveField((prevIsActiveField) => ({
                                  ...prevIsActiveField,
                                  location: selectedLocation ? true : false,
                                }));
                              }}}
                              label="Location"
                            />
                          );
                        }}
                      />

                      <span className="error">{errorLocation?.message}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mt-1 mb-1">
                      <p
                        style={{
                          margin: `0px`,
                          marginBottom: "10px",
                          fontSize: "14px",
                        }}
                      >
                        Social Media Handle (atleast one)
                        <span style={{ color: `red` }}>*</span>
                        <span
                          className="__influencer-warn d-block"
                          style={{
                            fontSize: "12px",
                            color: "#888",
                          }}
                        >
                          Please ensure the profiles mentioned belong to you and
                          is genuine.
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="row __manually mb-3">
                    <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                      <span style={{ fontSize: "14px", marginBottom: '10px', display: 'block' }}>
                        <InstagramIcon className="icon-insta" /> Instagram
                      </span>
                    </div>
                    <div className="col">
                      <div className="__wa_column">
                        <TextField
                          className="w-100 input-style input-common"
                          name="instagram"
                          disabled={showInstaProfileCard || (influencerId != null && instagramProfile) ? true : !isActiveField.location}
                          value={instagramProfile}
                          // onBlur={handleInstagramProfile}
                          inputRef={socialRef}
                          onChange={(e) => setInstagramProfile(e.target.value)}
                          label="Instagram ID"
                          size="small"
                          id="instagram_id"
                          placeholder="eg. virat.kohli"
                          variant="outlined"
                        />
                        <LoadingButton
                            className={`__button __button-influender ${instagramProfile.length === 0 ? "btn-disabled" : ""}`}
                          loading={igVerifyLoading}
                          loadingPosition="start"
                          variant="contained"
                          color="success"
                          disabled={showInstaProfileCard}
                          onClick={handleInstagramProfile}
                        >
                          { influencerId == null && (showInstaProfileCard && instagramProfile ? "Verified" : "Verify")}
                          { influencerId != null && (instagramProfile ? "Verified" : "Verify")}

                        </LoadingButton>
                        {
                          errorIgRegistered?.status && (
                            <span className="error">This username already exists. If this username belongs to you, please  <NavLink
                              to={`${BASE_URL_WEB}/contact_us`}
                              style={{
                                textDecoration: `none`,
                                color: `#0d6efd`,
                              }}
                            >
                              {" "}
                              contact us.
                            </NavLink>{" "}</span>
                          )
                        }
                      </div>
                      {influencerId == null && showInstaProfileCard && (
                        <InfluencerProfileCard
                          platform="instagram"
                          name={instaCardData.name}
                          username={instaCardData.insta_username}
                          profile_image={instaCardData.profile_picture_url}
                          description={instaCardData.biography}
                          subscriber={instaCardData.followers_count}
                          follows={instaCardData.follows}
                          total_media={instaCardData.total_media}
                        />
                      )}
                      <span className="error">
                        {errorSocialMediaHandle?.message}
                      </span>
                    </div>
                  </div>
                  <div className="row __manually mb-3">
                    <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                      <span style={{ fontSize: "14px", marginBottom: '10px', display:'block' }}>
                        {" "}
                        <YouTubeIcon style={{ color: "#f00" }} /> Youtube
                      </span>
                    </div>
                    <div className="col">
                      <div className="__wa_column">
                        <TextField
                          className="w-100 input-style input-common"
                          name="youtube"
                          size="small"
                          id="youtube_id"
                          disabled={showYtProfileCard || (influencerId != null && youtubeProfile) ? true : !isActiveField.location}
                          value={youtubeProfile}
                          placeholder="eg. UCjOooOPRBat1IqsP-BBPTzQ"
                          variant="outlined"
                          onChange={(e) => setYoutubeProfile(e.target.value)}
                          label="YouTube ID"
                        />

                        <LoadingButton
                            className={`__button __button-influender ${youtubeProfile.length === 0 ? "btn-disabled" : ""}`}
                          color="success"
                          loading={ytVerifyLoading}
                          loadingPosition="start"
                          variant="contained"
                          disabled={showYtProfileCard}
                          onClick={handleYoutubeProfile}
                        >
                          {influencerId == null && (showYtProfileCard && youtubeProfile ? "Verified" : "Verify")}
                          {influencerId != null && (youtubeProfile  ? "Verified" : "Verify")}
                          </LoadingButton>
                        {
                          errorYtRegistered?.status && (
                            <span className="error">This channel id already exists. If this channel id belongs to you, please  <NavLink
                              to={`${BASE_URL_WEB}/contact_us`}
                              style={{
                                textDecoration: `none`,
                                color: `#0d6efd`,
                              }}
                            >
                              {" "}
                              contact us.
                            </NavLink>{" "}</span>
                          )
                        }
                      </div>
                      {influencerId == null && showYtProfileCard && (
                        <InfluencerProfileCard
                          platform="youtube"
                          name={ytCardData.channel_name}
                          username={ytCardData.custom_url}
                          profile_image={ytCardData.thumbnail}
                          description={ytCardData.description}
                          subscriber={ytCardData.subscriber_count}
                          follows={0}
                          total_media={ytCardData.video_count}
                        />
                      )}
                      <span className="error">
                        {errorSocialMediaHandle?.message}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col">
                      <FormControl
                        variant="outlined"
                        className="w-100 input-common"
                        size="small"
                      >
                        <InputLabel htmlFor="outlined-adornment-password">
                          Create Password
                        </InputLabel>
                        <OutlinedInput
                          id="password_id"
                          type={showPassword ? "text" : "password"}
                          disabled={influencerId  == null ? !isActiveField.social_media : !isActiveField.location}
                          inputRef={passwordRef}
                          name="password"
                          onBlur={handlePassword}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Create Password"
                        />
                      </FormControl>
                      <span className="fs-12" style={{lineHeight: '14px',display: 'block', paddingTop: '5px'}}>Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.</span>
                      <span className="error">{errorPassword?.message}</span>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col">
                      <FormControl
                        variant="outlined"
                        className="w-100 input-common"
                        size="small"
                      >
                        <InputLabel htmlFor="outlined-adornment-password">
                          Confirm Password
                        </InputLabel>
                        <OutlinedInput
                          id="confirm_password_id"
                          type={showConfirmPassword ? "text" : "password"}
                          name="confirm_password"
                          disabled={!isActiveField.password}
                          onBlur={handleConfirmPassword}
                          inputRef={confirmPasswordRef}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownConfirmPassword}
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Confirm Password"
                        />
                      </FormControl>
                      {errorConfirmPassword && !errorConfirmPassword.status && <span className="error">{errorConfirmPassword?.message}</span>}
                    </div>
                  </div>
                  <div className="row __manually mb-3">
                    <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                      <span style={{ fontSize: "14px" }}> Create Login Pin  </span>
                    </div>
                    <div className="col">
                      <div className="__wa_column">
                      <MuiOtpInput
                          value={otpPINValue}
                          onChange={handlePINChange}
                          onComplete={handlePin}
                          ref={pinRef}
                          style={{ pointerEvents: isActiveField.confirmPassword ? 'auto' : 'none', opacity: isActiveField.confirmPassword ? 1 : 0.5 }}
                          length={4}
                          TextFieldsProps={{ size: 'small', placeholder: '0', type:"tel"  }}
                          validateChar={validateChar} 
                          />
                      </div>
                      <span className="error">
                        {errorPin?.message}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <ReCAPTCHA
                        sitekey={CAPTCHA_SITE_KEY}
                        onChange={(token: any) => setCaptchaToken(token)}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col" style={{ fontSize: "12px" }}>
                      <Checkbox
                        style={{
                          padding: "0",
                          marginRight: "5px",
                          verticalAlign: "middle",
                        }}
                        inputProps={{
                          "aria-label": "Checkbox",
                        }}
                        name="terms"
                        defaultChecked
                        size="small"
                      />
                      <span>
                        I agree to the{" "}
                        <div className="d-inline-flex align-items-end">
                          <NavLink
                            to="/terms_condition"
                            className="btn btn-text-link"
                          >
                            Terms and Conditions
                          </NavLink>
                          <span className="px-1">and</span> <NavLink
                            to="/privacy_policy"
                            className="btn btn-text-link"
                          >Privacy Policy</NavLink>
                        </div>
                      </span>
                      <br />
                      <span className="error">{errorTerms?.message}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-end">
                      <Button
                        type="submit"
                        variant="contained"
                        className={`btn btn-primary ${!isActiveField.pin ? "btn-disabled":""}`}
                        disabled={!isActiveField.pin}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-12 col-lg-6 mt-4 mt-lg-0 _influencer-content-sec">
              <InfluencerContent />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};


const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(null, mapDispatchToProps)(Influencer);

export { connectToRedux as Influencer };
