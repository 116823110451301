import { HttpService } from './index';
import { BASE_URL } from '../../config/config';

const http = new HttpService();
export class UploadService {

    static async UploadMedia(data: any): Promise<any> {

        const path = BASE_URL + '/upload';
        try {
            const response = await http.postRequest(path, data, 'file');
            return response;

        } catch (err) {
            throw new Error('UploadMedia service function error :: ' + err)
        }
    }
}