import React, {useEffect, useState } from 'react';
import { Container} from 'react-bootstrap';
import dayjs from 'dayjs';
import MaterialSymbol from '../../components/icons/material-symbol';
import * as Util from '../../core/utility';
import { DefaultUserProfile } from '../../config/config';

const method: Util.Methods = new Util.Methods();


interface Props {
    chats: any;
    userData: { user_id: number | string, account_id: number | string, influencer_id: number | string, account_name: string, brand_name: string; brand_organization: string; photo: string; logo: string; account_type: string };
    campaignDetail?: any;
    influencer?: any;
    campaign?: any;
    onClick: () => void;
    onSubmit: (event: any) => void;
}

export const InfluencerChatComponent: React.FC<Props> = ({ influencer, ...arg }: Props) => {
    const [random] = useState(method.getRandNumber());
    const [chats, setChats] = useState();

    useEffect(() => {
        const element = document.getElementById(`chat-fire_${random}`);
        if (element && JSON.stringify(arg.chats) !== JSON.stringify(chats)) {
            setChats(arg.chats);
            element.scrollTop = element.scrollHeight;
        }
    }, [arg.chats])
    return (
        <Container className={`pro-inf-chat-container`} fluid>
            <section className='influencer-chat-header'>
                <section className='pro-detail'>
                    <MaterialSymbol onClick={arg.onClick} className='back-btn' iconName='chevron_left' fontweight='300' />
                    {/* Influencer Section */}
                    {influencer && <img className='profile-image-size' src={
                    (influencer.gcp_profile_image_url || influencer.profile_image_url)
                        ? (influencer.gcp_profile_image_url ? influencer.gcp_profile_image_url : influencer.profile_image_url)
                        : DefaultUserProfile.influencer_unknown_default
                        } />
                    }
                    {influencer &&
                        <section>
                            <span className='influencer-name'>{influencer.account_name}</span>
                            <br />
                            <span className='influencer-username'>{influencer.username}</span>
                        </section>}
                    {influencer && <span className='proposal-status'>{influencer.status}</span>}
                    {/* Campaign Section */}
                    {arg.campaign && <img className='profile-image-size' src={arg.campaign.campaign_cover_url} />}
                    {arg.campaign &&
                        <section>
                            <span className='influencer-name'>{arg.campaign.campaign_title}</span>
                        </section>
                    }
                    {arg.campaign && <span className='proposal-status'>{arg.campaign.status}</span>}
                </section>

            </section>
            <section id={`chat-fire_${random}`} className='chat-view'>
                <ul className='parent-ul'>
                    {Object.keys(arg.chats)?.map((data, index) =>
                        <li key={`parent_${index}`} className='parent-li'>
                            <span className='proposal-status'>{dayjs(data).format('DD MMM, YYYY')}</span>
                            <ul className='child-ul'>
                                {arg.chats[data]?.map((data: any, child_index: number) =>
                                    <li key={`child_${child_index}`} className={`mt-3 child-li ${data.influencer_id === arg.userData.influencer_id ? 'to' : 'from'}`}>
                                        <span>
                                            {data.comments}
                                        </span>
                                        {/* Influencer */}
                                        {influencer && <img className='profile-image-size' src={data.account_id === arg.userData.account_id ? arg.campaignDetail.campaign_cover_url : ((influencer.gcp_profile_image_url || influencer.profile_image_url) ? (influencer.gcp_profile_image_url ? influencer.gcp_profile_image_url : influencer.profile_image_url) : DefaultUserProfile.influencer_unknown_default)} />}
                                        {/* Campaign */}
                                        {arg.campaign && <img className='profile-image-size' src={data.account_id === arg.userData.account_id ? arg.userData.photo : arg.campaign.campaign_cover_url} />}
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}
                </ul>
            </section>

            <section className='conversion-section'>
                <form onSubmit={arg.onSubmit}>
                    <input type='text' name='message' placeholder='Write message...' />
                    <button type='submit' className='send-btn'>
                        <MaterialSymbol className={`send-icon`} iconName='send' fontweight='200' />
                    </button>
                </form>
            </section>


        </Container>
    )
}