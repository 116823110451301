import React, { useEffect, useState } from "react";
import { SortingColumnsType, TableComponent } from "../../../components/table-component/table";
import { TableCell, TableRow, Tooltip } from "@mui/material";
import { connect } from "react-redux";
import { Loading, UserLogin, SwitchLayer1, SwitchLayer2 } from "../../../redux/action";
import { LocalService, UserService } from "../../../core/services";
import { useNavigate } from "react-router-dom";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

interface Props {
    UserReducer: any;
    // loading: (data: boolean) => void;
    switchLayer2: any;
    switchLayer1: (data: any) => void;
    SwitchUserData: (data: any) => void;
}
const userService: UserService = new UserService();
const localService: LocalService = new LocalService();
const ABTIUserSubscription : React.FC<Props> = (props: Props) => {
    const columnDisplayName = {
        serial_no: "#",
        brand_name: props.switchLayer2.account_type === "Agency" ? "Brand" : "Influencer",
        status: "Status",
        amount: "Total Amount",
        plan_end_date: "Plan End Date",
        action: "Action"
    };

    const sortingColumnType: SortingColumnsType[] = [
        {
            name: "#",
            columnSize: "30px",
        },
        {
            name: "brand_name",
            columnSize: "160px",
        },
        {
            name: "status",
            columnSize: "60px",
        },
        {
            name: "amount",
            columnSize: "60px",
        },
        {
            name: "plan_end_date",
            columnSize: "30px",
        },
        {
            name: "action",
            columnSize: "30px",
        }
    ];

    const [renderData, setRenderData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [agencyBrands, setAgencyBrands] = useState<any>([]);
    const [activeUser, setActiveUser] = useState<any>(null);
    const navigate = useNavigate();
    // For Ex: Agency, Brand, Talent Partner, Influencer 
    const activeAccountType = props.switchLayer2.account_type

    async function getABTIUserSubscription() {
        try {
            setLoading(true);
            const res = await userService.getBrandsOfAgencyAndInflOfTP(props.switchLayer2.account_id,activeAccountType);

            if (res.data.status) {
                setAgencyBrands(res.data.rows);
            }
        } catch (error) {
            console.error('Error fetching getABTIUserSubscription:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getABTIUserSubscription()
    }, [])

    async function updateUserReducer() {
        const latestUserReducerData = await userService.getUserReducerAccountData(props.switchLayer2)
        if (![undefined, null, ''].includes(latestUserReducerData) && latestUserReducerData?.data?.rows[0]?.status === 'Blocked') {
            localService.logout();
        } else if (![undefined, null, ''].includes(latestUserReducerData)) {
            props.switchLayer1(latestUserReducerData.data.rows);
            props.SwitchUserData(latestUserReducerData.data.rows[0]);
        }
    }

    async function activeUserMethod(data: any) {
        if (data.status != 'Under Review' && data.status != 'Deactivate') {
            setActiveUser(data);
        }
    }

    useEffect(() => {
        updateUserReducer();
        if (activeUser) {
            const accountType = (activeAccountType + "")
                .toString()
                .toLocaleLowerCase();
            if (accountType === "agency" || accountType === "talent partner") {
                const currentUser = {
                    ...activeUser,
                    ...props.switchLayer2,
                    user_id: props.switchLayer2.user_id  // Ensure old user_id is used
                };

                props.UserReducer(currentUser);
                if (accountType === "agency") {
                    navigate("/agency/settings/get-subscription");
                } else {
                    navigate("/talent-partner/settings/get-subscription");
                }
    
                localService.setStorageItem("userreducer", JSON.stringify(currentUser));
            }
        }
    }, [activeUser]);
    
    const handleIconClick = (name: string) => {
        const path = activeAccountType === "Agency"
            ? "/agency/settings/subscription-history"
            : "/talent-partner/settings/subscription-history";
        navigate(path, { state: { brandInfluencerName: name } });
    };


    return (
        <div className="agency-brands px-4">
            <div className="list-section-content rounded mb-3">
                <div className='row align-items-center'>
                    <strong className='col'>{activeAccountType === "Agency" ? "Brand Subscriptions" : "Influencer Subscriptions"}</strong>
                </div>
            </div>

            <TableComponent
                columnDisplayName={columnDisplayName}
                orderByColumnName=""
                sortingColumnType={sortingColumnType}
                inputDataSource={agencyBrands}
                renderData={renderData}
                setRenderData={setRenderData}
                tableMinWidth={300}
            >
                {renderData.length === 0 ? (
                    <TableRow>
                        <TableCell colSpan={6} align="center">
                            No Data Available
                        </TableCell>
                    </TableRow>
                ) : (


                    renderData.map((data: any, index: number) => {
                        const planEnd = data?.plan_end ? `${new Date(data.plan_end).getDate()} ${new Date(data.plan_end).toLocaleString('en-GB', { month: 'short', year: 'numeric' })}` : '-';
                        return (
                            <TableRow key={index} hover role="checkbox">
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{activeAccountType === "Agency" ? data.brand_name : data.name}</TableCell>
                                <TableCell style={{ color: data.days_counts > 0 ? 'green' : data.days_counts === 0 ? 'red' : 'black' }}>
                                    {data.days_counts > 0
                                        ? "Active"
                                        : data.days_counts === 0
                                            ? "Expired"
                                            : "Non-Subscribed"}
                                </TableCell>
                                <TableCell>{data.amount ? `₹${data.amount}` : '-'}</TableCell>
                                <TableCell>{planEnd}</TableCell>
                                <TableCell>
                                    <div>
                                        <Tooltip title="View Subscriptions" arrow onClick={() => handleIconClick(data.name || data.brand_name)}                                        >
                                            <VisibilityOutlinedIcon style={{cursor: "pointer"}}/>
                                        </Tooltip>
                                        <a
                                            className="btn btn-outline-primary btn-sm btn-mui ms-2"
                                            onClick={(e) => activeUserMethod(data)}
                                        >
                                            Upgrade Now
                                        </a>
                                    </div>
                                </TableCell>
                            </TableRow>
                        );
                    }))}
            </TableComponent>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        switchLayer2: state.SwitchLayer2Reducer.switchUser
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        UserReducer: (data: any) => dispatch(UserLogin(data)),
        switchLayer1: (data: any) => dispatch(SwitchLayer1(data)),
        SwitchUserData: (data: any) => dispatch(SwitchLayer2(data)),
    };
};

const connectToRedux = connect(
    mapStateToProps,
    mapDispatchToProps
)(ABTIUserSubscription );

export { connectToRedux as ABTIUserSubscription  };