import React, { useEffect, useState, useRef } from "react";
import './request-a-demo.scss';
import ReCAPTCHA from "react-google-recaptcha";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CAPTCHA_SITE_KEY } from "../../../config/config";
import {
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Box,
  Tooltip
} from "@mui/material";

import { FormValidation } from "../../../core/utility";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  PublicService,
  UserService,
  LocalService,
} from "../../../core/services";
import { connect } from "react-redux";
// import { Loading } from "../../../redux/action";
import Loading from '../../../components/loader/loading';

import OtpField from "../../../components/otp/otp";
import { CryptoJS } from "../../../core/utility";
import useBodyClass from "../../../hooks/useBodyClass";
import HeadingStyle from "../../../components/heading-style/heading-style";
import { CaptchaService } from "../../../core/services/captcha.service";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from 'react-toastify';
import { MenuPropsSelect } from "../../../core/utility/style";
import Footer from "../../../components/footer/footer";
import { Instagram, YouTube } from "@mui/icons-material";
import MaterialSymbol from "../../../components/icons/material-symbol";
import ContentLoading from "../../../components/loader/loading";



const userService: UserService = new UserService();
const localService: LocalService = new LocalService();
interface ErrorTypes {
  status: boolean;
  message: string;
}

interface Props {
  loading: (data: any) => void;
}

const cryptoJS = new CryptoJS();
const publicService: PublicService = new PublicService();

const RequestDemo: React.FC<Props> = (props: Props) => {
  const formValidation = new FormValidation();
  const [loading, setLoading] = useState(false);
  const [purpose, setPurpose] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState<ErrorTypes>();
  const [errorMessage, setErrorMessage] = useState<ErrorTypes>();
  const [email, setEmail] = useState("");
  const [instagramUsername, setInstagramUsername] = useState("");
  const [youtubeChannel, setYoutubeChannel] = useState("");
  const [_industry, setIndustry] = useState<any>("");
  const [showotpBox, setOtpBox] = useState(false);
  const [whatsappnumber, setWhatsAppNumber] = useState("");
  const [captchaToken, setCaptchaToken] = useState<any>();
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const captchaService = new CaptchaService();
  const [isEditButtonDisabled, setIsEditButtonDisabled] = useState(false);
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(true);
  const [timer, setTimer] = useState(60);
  const [isTimerDisabled, setIsTimerDisabled] = useState(true);
  const [showResendEdit, setShowResendEdit] = useState(false);
  const phoneRef = useRef<any>(null);



  // For Disabling ...........................

  const [disableWhatsappfield, setDisableWhatsAppField] = useState(true);


  // For Verification----------------------------------------------
  const [verifiedWAfield, setVerifiedWAfield] = useState(false);

  //validations
  const [errorwhatsapp, setErrorWhatsApp] = useState<ErrorTypes>();
  const [errorFullName, setErrorFullName] = useState<ErrorTypes>();

  const [errorUserType, setErrorUserType] = useState<ErrorTypes>();

  const navigate = useNavigate();


  //handles

  const handleFullName = (e: any) => {
    setErrorFullName(formValidation.GetNameValidate(e.target.value));
  };

  const handleEmail = (e: any) => {
    const value = e?.target?.value ?? e;
    setEmail(e.target.value)
    let isValid: { status: boolean; message: string } =
      formValidation.GetEmailControlIsValid(value);
    setErrorEmail(isValid);


  }

  useEffect(() => {
    let countdown: any;
    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setTimer(0);
      setIsResendButtonDisabled(false);
    }

    return () => clearInterval(countdown);
  }, [timer]);
  const handleSendOtpButton = (e: any) => {
    let isValid: any = formValidation.GetMobileControlIsValid(whatsappnumber);
    // if (`${whatsappnumber}`.length === 10){
    if (isValid.status) {
      setErrorWhatsApp(isValid);

      PublicService.sendOtp({ mobile: +whatsappnumber, request_demo: "true" })
        .then((res: any) => {
          if (res?.data?.rows?.status) {
            setShowResendEdit(true);
            setIsResendButtonDisabled(true);
            setTimer(30);
            setIsTimerDisabled(true);
            setIsEditButtonDisabled(true);
            setOtpBox(res?.data?.rows?.status);
            setDisableWhatsAppField(res?.data?.rows?.status);
            localService.toastify(res?.data?.rows?.message, "success", 1000);
          } else {
            localService.toastify(res?.data?.rows?.message, "error", 1000);
          }
          // setLoading(false);
        })
        .catch((error: any) => {
          // setLoading(false);
          console.warn(`Otp Response Error :: ${error}`);
        });
    } else {
      setErrorWhatsApp(isValid);
    }


  }
  // const handleWhatsApp = (e: any) => {
  //   setErrorWhatsApp(formValidation.GetMobileControlIsValid(e.target.value));
  // }
  const handleWhatsAppOtp = (otp: any) => {
    if (otp.length === 4) {
      PublicService.verifyOtp({ mobile: +whatsappnumber, otp: otp })
        .then((res) => {
          if (res?.data?.rows?.status) {
            setOtpBox(false);
            setDisableWhatsAppField(true);
            setShowResendEdit(false);
            setVerifiedWAfield(true)
            localService.toastify(res?.data?.rows?.message, "success", 1000);
          } else {
            localService.toastify(res?.data?.rows?.message, "error", 1000);
          }
        })
        .catch((error) => {
          throw new Error("VerifyOtp Error ::: " + error);
        });
    }
  }

  // const submitRequestDemo = async (storeData: any) => {
  //   const response = await publicService.requestADemo(storeData)
  //   localService.toastify(response.data.rows.message);
  //   if (response.data.rows.status) {
  //     navigate('/');
  //   }else{
  //     navigate('/')
  //   }
  // }/

  const submitRequestDemo = async (storeData: any) => {
    setLoading(true);
    try {
      const response = await publicService.requestADemo(storeData);
      localService.toastify(response.data.rows.message);
      
      if (response.data.rows.status) {
        navigate('/');
      } else {
        navigate('/');
      }
    } catch (error) {
      console.error("An error occurred:", error);
      localService.toastify("There was an issue submitting your request.");
    } finally {
      setLoading(false);
    }
  };
  

  const handleOnSubmit = async (e: any) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);
      const data = Object.fromEntries(form);
      const storeData: any = { message, ...data };
      setErrorEmail(formValidation.GetEmailControlIsValid(`${email}`));
      setErrorFullName(formValidation.GetNameValidate(`${name}`));
      setErrorMessage(formValidation.GetLongTextValidate(`${message}`))
      setErrorUserType(formValidation.GetNameValidate(`${purpose}`))
      setErrorWhatsApp(
        formValidation.GetMobileControlIsValid(`${whatsappnumber}`)
      );
      // For Validation
      const isValidEmail = formValidation.GetEmailControlIsValid(`${email}`).status
      const isValidName = formValidation.GetNameValidate(`${name}`).status;
      const isValidMessage = formValidation.GetLongTextValidate(`${message}`).status;
      const isValidUser = formValidation.GetNameValidate(`${purpose}`).status

      // Check if all fields are empty
      const areAllFieldsEmpty = !email && !name && !message  && !whatsappnumber;
      
      // if all fields are empty
      if (areAllFieldsEmpty) {
        localService.toastify("All fields are required.", "error", 1000);
        return;
      }


      // Check if CAPTCHA is verified
      if (!captchaToken) {
        localService.toastify("Please complete CAPTCHA verification.", "error", 1000);
        return;
      }

      if (!verifiedWAfield) {
        localService.toastify("Please verify your WhatsApp number by entering the OTP.", "error", 1000);
        return;
      }
      
      if (!disableWhatsappfield && whatsappnumber) {
        localService.toastify("WhatsApp No. Not Verified.", "error", 1000);
      }
      else {
        props.loading(false);
      }
      const verifyCaptcha = async () => {
        try {
          const res = await captchaService.verifyCaptcha({ token: captchaToken });
          return res?.data?.rows?.message === "Captcha verified successfully!";
        } catch (error) {
          throw new Error(`verifyCaptcha Error :: ${JSON.stringify(error)}`);
        }
      };
      if (await verifyCaptcha) {

        if (
          isValidName &&
          isValidEmail &&
          isValidMessage &&
          isValidUser &&
          disableWhatsappfield) {
          submitRequestDemo(storeData)

        }
      }


    }
    catch (error) {
    }
  };

  const handleChangePhone = () => {
    setVerifiedWAfield(false);
    setOtpBox(false);
    setShowResendEdit(false);
    if (phoneRef.current) {
      phoneRef.current.focus();
    }


  }
  let influencerTp : boolean 
  if (purpose === 'influencer_contact_us') {
    influencerTp = true
  } else {
    influencerTp = false
  }

  useBodyClass("common-signup-page");
  useBodyClass("request-a-demo-page");
  return (
    <>
    {loading ? (<div style={{height:'100vh', display:'flex',justifyContent:'center', alignItems:'center'}}><ContentLoading width='150' height='150' /></div>):
    (

    <div className="_influencer-container">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">

            <div className="_influencer-signup">
              <div className="row ">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-1 mb-2 __backArrow">
                  <HeadingStyle title="Request a Demo" />
                </div>
              </div>

              <form onSubmit={handleOnSubmit} className="__signup_form">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-1 mb-3">
                    <FormControl fullWidth size="small" className="input-style">
                      <InputLabel id="demo-simple-select-label">
                        I am
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="purposeId"
                        value={purpose}
                        label="Please Select One"
                        name="purpose"
                        onChange={(e) => setPurpose(e.target.value)}
                        MenuProps={MenuPropsSelect}
                      >
                        <MenuItem value="brand_request_demo">Brand/Advertiser</MenuItem>
                        <MenuItem value="influencer_contact_us">Influencer/Talent Partner</MenuItem>
                      </Select>
                    </FormControl>
                    <span className="error">{errorUserType?.message}</span>
                    {influencerTp && <p className="m-0"><small>Please note that the demo is for Brands and Advertisers only. However, if you still wish to write to us please fill in the details below with your concern.</small></p>}
                  </div>
                        <div className="mb-3 row pe-0" >
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3 mb-sm-3 mb-md-0 mb-lg-0 pe-0 pe-sm-0 pe-md-0">
                              <TextField
                                className="w-100"
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                id="full_name_id"
                                onBlur={handleFullName}
                                name="full_name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Full Name"
                                label="Name"
                                variant="outlined"
                              />
                              <span className="error">{errorFullName?.message}</span>
                            </div>
                              <div className="col-lg-6 col-md-6 pe-0 __wa_column">
                                <TextField
                                  className="w-100"
                                  size="small"
                                  InputLabelProps={{ shrink: true }}
                                  id="email_id"
                                  // onBlur={handleEmail}
                                  onChange={handleEmail}
                                  name="email_id"
                                  label="Your Email Id"
                                  placeholder="Email Id"
                                  variant="outlined"
                                />
                              <span className="error">{errorEmail?.message}</span>
                            </div>
                        </div>
                  
                  <div className={`row pe-0 ${influencerTp ? 'mb-3' : ''}`}>
                    {influencerTp &&
                        <div className="col-lg-6 col-md-6  mb-3 mb-sm-3 mb-md-0 mb-lg-0 pe-0 pe-sm-0 pe-md-0 __wa_column">
                          <TextField
                            className="w-100 input-style"
                            name="instagram_handle"
                            value={instagramUsername}
                            onChange={(e) => setInstagramUsername(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Instagram />
                                </InputAdornment>
                              ),
                            }}
                            size="small"
                            id="instagram_id"
                            placeholder="eg. virat.kohli"
                            label="Instagram Username/URL (Optional)"
                            variant="outlined"
                          />
                      </div>
                    }
                    {influencerTp &&
                        <div className="col-lg-6 col-md-6 pe-0 __wa_column">
                          <TextField
                            className="w-100 input-style"
                            name="youtube_channel"
                            size="small"
                            id="youtube_id"
                            label="YouTube Channel/URL (Optional)"
                            value={youtubeChannel}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <YouTube style={{ color: "red" }} />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="eg. UCjOooOPRBat1IqsP-BBPTzQ"
                            variant="outlined"
                            onChange={(e) => setYoutubeChannel(e.target.value)}
                          />
                        </div>
                    }
                  </div>
                  <div className=" mb-3">
                    <div className="col __wa_column">
                      <TextField
                        className="w-100"
                        type="number"
                        size="small"
                        color="primary"
                        inputRef={phoneRef}
                        // onBlur={handleWhatsApp}
                        name="mobile"
                        id="whatsappnumber_id"
                        label="WhatsApp No."
                        value={whatsappnumber}
                        InputProps={{
                          readOnly: verifiedWAfield,
                        }}
                        // onChange={(e) => {
                        //   setWhatsAppNumber(e.target.value);
                        //   if (e.target.value.length >= 10) {
                        //     setDisableWhatsAppField(false);
                        //   } else {
                        //     setDisableWhatsAppField(true);
                        //   }
                        // }}
                        onChange={(e) => {
                          // Restrict input to 10 digits
                          if (e.target.value.length <= 10) {
                            setWhatsAppNumber(e.target.value);
                            setDisableWhatsAppField(e.target.value.length !== 10);
                          }
                        }}
                        variant="outlined"
                      />
                      {/* <LoadingButton
                        className="__button send-otp"
                        color="success"
                        // loading={loading}
                        loadingPosition="start"
                        disabled={disableWhatsappfield}
                        onClick={handleSendOtpButton}
                        variant="contained"
                        startIcon={<WhatsAppIcon />}
                      >
                        Send OTP
                      </LoadingButton> */}
                        {!verifiedWAfield ? (
                          <LoadingButton
                            className="__button send-otp"
                            color="success"
                            loadingPosition="start"
                            disabled={disableWhatsappfield}
                            onClick={handleSendOtpButton}
                            variant="contained"
                            startIcon={<WhatsAppIcon />}
                          >
                            Send OTP
                          </LoadingButton>
                        ) : (
                          <Tooltip className="icon-verified" title="Verified" arrow>
                            <IconButton size="small" className="p-0">
                              <MaterialSymbol iconName="verified" fontweight="200" />
                            </IconButton>
                          </Tooltip>
                        )}
                      <span className="error">{errorwhatsapp?.message}</span>
                      {showResendEdit &&
                        <div className="resend-otp">
                          {isEditButtonDisabled && <p className="change-phone fs-12 btn btn-text-link" onClick={handleChangePhone}>Change Phone</p>}
                          <div className="resend-otp-container">
                            <button
                              className="otp-btn fs-12 btn btn-text-link"
                              onClick={handleSendOtpButton}
                              type="button"
                              disabled={isResendButtonDisabled}
                            >
                              Resend OTP
                            </button>
                            {isTimerDisabled && <p className="otp-time fs-12">&nbsp; 00:{timer < 10 ? `0${timer}` : timer}</p>}
                          </div>

                        </div>}


                      {showotpBox && <OtpField handleOtp={handleWhatsAppOtp} />}
                    </div>
                  </div>
                </div>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '100%' },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div className="mb-3">
                    <TextField
                      id="outlined-multiline-static "
                      className="textfield-message"
                      label="Message"
                      multiline
                      rows={4}
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value)
                      }}
                    />
                    <span className="error">{errorMessage?.message}</span>
                  </div>
                </Box>
                <div className="row mb-3">
                  <div className="col">
                    <ReCAPTCHA
                      sitekey={CAPTCHA_SITE_KEY}
                      onChange={(token: any) => setCaptchaToken(token)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col text-end">
                    <LoadingButton
                      className="btn btn-primary ms-2"
                      type="submit"
                      loading={loading}
                      disabled={loading}
                      loadingPosition="start"
                      variant="contained"
                    >
                      Submit
                    </LoadingButton>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    )}
    <Footer />
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(null, mapDispatchToProps)(RequestDemo);
export { connectToRedux as RequestDemo };
